import { rj, useRj, useRunRj } from 'react-rocketjump'
import api from '../api'

const CheckActivateUserTokenState = rj().effect({
  name: 'CheckActivateUserToken',
  effect: (token) =>
    api.mapResponse(() => true).get(`/api/check-activate-user-token/${token}/`),
})

export function useCheckActivateUserToken(token) {
  return useRunRj(CheckActivateUserTokenState, [token])
}

const ActivateUserState = rj().effect({
  name: 'ActivateUser',
  effect: (token, data) =>
    api.mapResponse(() => true).post(`/api/activate-user/${token}/`, data),
})

export function useActivateUser() {
  return useRj(ActivateUserState)
}