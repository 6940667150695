import { Breadcrumb } from '@patternfly/react-core'
import { useNavigate } from 'react-router'
import AccountOperatoreForm from '../../../components/AccountOperatoreForm'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import { useAddAccountOperatore } from '../../../hooks/accountOperatore'

export default function AccountAdd() {
  const { run: addAccount } = useAddAccountOperatore()[1]
  const navigate = useNavigate()
  return (
    <FullPage>
      <SectionHeader
        title="Nuovo Account Operatore"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/account-operatore">
              Account Operatore
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to="/account-operatore/add">
              Nuovo
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <ScrollableContent>
        <AccountOperatoreForm
          save={(data) =>
            addAccount
              .onSuccess((created) => navigate(`/account-operatore/${created.id}`))
              .asPromise(data)
          }
        />
      </ScrollableContent>
    </FullPage>
  )
}
