import { useEffect } from 'react'
import { useAuthActions } from 'use-eazy-auth'

export function useForceAuthRefresh() {
  const { callAuthApiPromise } = useAuthActions()
  useEffect(() => {
    let refreshTriggered = false
    callAuthApiPromise(() => () => {
      if (!refreshTriggered) {
        // NOTE: Tell to my awesome friend use eazy auth to perfome
        // a token refresh task!
        refreshTriggered = true
        return Promise.reject({ status: 401 })
      } else {
        // Token refreshed!
        return Promise.resolve(true)
      }
    })
  }, [callAuthApiPromise])

  return null
}
