import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import SegnalazioneDetail from './SegnalazioneDetail'
import SegnalazioniList from './SegnalazioniList'

export default function Rilevatori() {
  return (
    <Routes>
      <Route index element={<SegnalazioniList />} />
      <Route path=":id" element={<SegnalazioneDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
