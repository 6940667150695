import { deps, rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const ImportazioniAnagraficaListState = rj()
  .mutations({
    removeImportazieAanagrafica: {
      updater: (state, id) => ({
        ...state,
        data: state.data.filter((im) => im.id !== id),
      }),
      effect: (t) => (id) =>
        api
          .auth(t)
          .mapResponse(() => id)
          .delete(`/api/importazioni-anagrafica/${id}`),
    },
  })
  .effect({
    name: 'ImportazioniAnagraficaList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/importazioni-anagrafica', params),
  })

const NO_PARAMS = {}
export function useImportazioniAnagraficaList(params = NO_PARAMS) {
  return useRunRj(ImportazioniAnagraficaListState, [deps.maybe(params)], false)
}
