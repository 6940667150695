import { Breadcrumb } from '@patternfly/react-core'
import { useNavigate } from 'react-router'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ReportForm from '../../../components/ReportCompletoForm'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import { useAddReportCompleto } from '../../../hooks/reportCompleti'

export default function ReportAdd() {
  const { run: addReport } = useAddReportCompleto()[1]
  const navigate = useNavigate()
  return (
    <FullPage>
      <SectionHeader
        title="Nuovo report completo"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/reports-completi">
              Reports Completi
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to="/reports-completi/add">
              Nuovo report
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <ScrollableContent>
        <ReportForm
          save={(data) =>
            addReport
              .onSuccess((created) => navigate(`/reports-completi/${created.id}`))
              .asPromise(data)
          }
        />
      </ScrollableContent>
    </FullPage>
  )
}
