import { rj, useRj } from 'react-rocketjump'
import api from '../api'

const DashboardCountRilieviState = rj().effect({
  name: 'DashboardCountRilievi',
  effectCaller: 'configured',
  effect: (t) => (params) =>
    api.auth(t).get('/api/dashboard-rilievi-count', params),
})

export function useDashboardCountRilievi() {
  return useRj(DashboardCountRilieviState)
}



const DashboardTimeSerieTipoMezzoState = rj().effect({
    name: 'DashboardCountRilievi',
    effectCaller: 'configured',
    effect: t => (params) =>
      api.auth(t).get('/api/dashboard-rilievi-timeserie-tipomezzo', params),
  })


  export function useDashboardTimeSerieTipoMezzo() {
    return useRj(DashboardTimeSerieTipoMezzoState)
  }


  