import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import RilieviList from './RilieviList'
import RilievoDetail from './RilievoDetail'

export default function Rilevatori() {
  return (
    <Routes>
      <Route index element={<RilieviList />} />
      <Route path=":id" element={<RilievoDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
