import { Banner } from '@patternfly/react-core'

export default function RunTimeErrorFallback() {
  return (
    <div>
      <Banner variant="danger">
        Ops, qualcosa è andato storto.
        <br />
        Stiamo lavorando per risolvere il problema.
      </Banner>
    </div>
  )
}
