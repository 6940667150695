import { BreadcrumbItem } from '@patternfly/react-core'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

export default function BreadcrumbItemLink({ children, to, ...props }) {
  const resolved = useResolvedPath(to)
  const isActive = useMatch({ path: resolved.pathname, end: true }) !== null
  return (
    <BreadcrumbItem
      to={to}
      isActive={isActive}
      {...props}
      render={({ ariaCurrent, ...passProps }) => (
        <Link {...passProps} to={to} aria-current={ariaCurrent}>
          {children}
        </Link>
      )}
    />
  )
}
