import { Alert } from '@patternfly/react-core'
import { Link } from 'react-router-dom'

export default function AlertLastReportRevision({ report }) {
  if (!report.ultima_revisione) {
    return null
  }
  return (
    <Alert
      className="pf-u-mb-md"
      variant="warning"
      title={
        <span>
          <b>ATTENZIONE</b>
          <p>
            Quella che sta visulazzando <b>NON</b> è l'ultima revisione del
            report.{' '}
            <Link to={`/reports-completi/${report.ultima_revisione}`}>
              Vai all'ultima revisione.
            </Link>
          </p>
        </span>
      }
      aria-live="polite"
      isInline
    />
  )
}
