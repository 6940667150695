import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { Field, Formik, useFormikContext } from 'formik'
import { useSearchParams } from 'react-router-dom'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import dayjs from 'dayjs'
import {
  Badge,
  Breadcrumb,
  Button,
  Form,
  FormGroup,
  FormSelectOption,
  Pagination,
} from '@patternfly/react-core'
import {
  TableComposable,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from '@patternfly/react-table'
import classNames from 'classnames'
import { useAuthUser } from 'use-eazy-auth'
import SectionHeader from '../../components/SectionHeader'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import { PAGE_SIZE, STATI_SEGNALAZIONE, TIPI_MEZZO } from '../../consts'
import SearchBar from '../../components/SearchBar'
import { useTableSorter } from '../../hooks/sorter'
import ScrollableContent from '../../components/ScrollableContent'
import FullPage from '../../components/FullPage'
import ListFiltersOverlay from '../../components/ListFiltersOverlay'
import {
  DatePickerField,
  MultiViaAutocomplete,
  SelectField,
} from '../../components/Form/fields'
import { countFilters } from '../../utils'
import { useOperatoriTipiMezzoFlat } from '../../hooks/operatori'
import { useSegnalazioniList } from '../../hooks/segnalazioni'

function FiltersFormFields({ operatoriTipiMezzo }) {
  const { user } = useAuthUser()
  const { values } = useFormikContext()

  const tipoMezzoOptions = useMemo(() => {
    let deOptions = [{ value: '', label: 'Tutti' }]
    if (operatoriTipiMezzo) {
      deOptions.push(
        ...uniq(
          operatoriTipiMezzo
            .filter((ot) => {
              if (!values.operatore) {
                return true
              }
              return +ot.operatore.id === +values.operatore
            })
            .map((s) => s.tipo_mezzo)
        ).map((value) => ({
          value,
          label: TIPI_MEZZO[value],
        }))
      )
    }
    return deOptions
  }, [operatoriTipiMezzo, values.operatore])

  const operatoriOptions = useMemo(() => {
    let deOptions = [{ value: '', label: 'Tutti' }]
    if (operatoriTipiMezzo) {
      deOptions.push(
        ...uniqBy(
          operatoriTipiMezzo.filter((ot) => {
            if (!values.tipo_mezzo) {
              return true
            }
            return ot.tipo_mezzo === values.tipo_mezzo
          }),
          (ot) => ot.operatore.id
        ).map((ot) => ({
          value: ot.operatore.id,
          label: ot.operatore.nome,
        }))
      )
    }
    return deOptions
  }, [operatoriTipiMezzo, values.tipo_mezzo])

  return (
    <>
      <div className="pf-u-display-flex pf-u-justify-content-space-between form-group-max-width">
        <Field
          invalidFormatText=""
          label="Dal"
          name="inizio"
          component={DatePickerField}
        />
        <Field
          invalidFormatText=""
          label="Al"
          name="fine"
          component={DatePickerField}
        />
      </div>
      <Field label="Tipo Mezzo" name="tipo_mezzo" component={SelectField}>
        {tipoMezzoOptions.map((o) => (
          <FormSelectOption label={o.label} value={o.value} key={o.value} />
        ))}
      </Field>
      {user.amministratore && (
        <Field label="Operatore" name="operatore" component={SelectField}>
          {operatoriOptions.map((o) => (
            <FormSelectOption label={o.label} value={o.value} key={o.value} />
          ))}
        </Field>
      )}
      <Field label="Stato" name="stato" component={SelectField}>
        <FormSelectOption label={'Tutti'} value={''} />
        {Object.keys(STATI_SEGNALAZIONE)
          .sort()
          .filter((stato) => {
            if (user.account_operatore) {
              return !['in_arrivo', 'invalida'].includes(stato)
            } else {
              return true
            }
          })
          .map((s) => (
            <FormSelectOption label={STATI_SEGNALAZIONE[s]} value={s} key={s} />
          ))}
      </Field>
      <Field label="Via" name="vie" component={MultiViaAutocomplete} />
    </>
  )
}

function FiltersForm({ initialValues, onSubmit, onClose, operatoriTipiMezzo }) {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Form isHorizontal onSubmit={handleSubmit}>
          <FiltersFormFields operatoriTipiMezzo={operatoriTipiMezzo} />
          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between pf-u-mt-md">
              <Button variant="secondary" type="button" onClick={onClose}>
                Annulla
              </Button>
              <Button type="submit">Aggiorna</Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}

export default function RilieviList() {
  const { user } = useAuthUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const filters = useMemoCompare({
    page: searchParams.get('page') ?? 1,
    search: searchParams.get('search') ?? '',
    ordering: searchParams.get('ordering') ?? '',
    tipo_mezzo: searchParams.get('tipo_mezzo') ?? '',
    inizio: searchParams.get('inizio') ?? '',
    fine: searchParams.get('fine') ?? '',
    vie: searchParams.getAll('vie') ?? [],
    operatore: searchParams.get('operatore') ?? '',
    stato: searchParams.get('stato') ?? '',
  })
  const sorter = useTableSorter(filters.ordering, (ordering) =>
    setSearchParams({ ...filters, page: 1, ordering })
  )
  const [{ data: operatoriTipiMezzo }] = useOperatoriTipiMezzoFlat()
  const [{ list, pagination, pending, error }, { getToken }] =
    useSegnalazioniList(filters)
  const [openFilters, setOpenFilters] = useState(false)
  const filtersCount =
    countFilters(filters, ['tipo_mezzo', 'inizio', 'fine', 'operatore', 'stato']) +
    (filters.vie.length > 0 ? 1 : 0)

  return (
    <FullPage>
      <SectionHeader
        bottomPadded
        title="Segnalazioni"
        subTitle={
          <span>
            {pagination.count ?? ''} totali
            {filtersCount > 0 && (
              <span
                onClick={() => setSearchParams({})}
                className="pf-u-ml-md pf-u-active-color-100 mimic-link"
              >
                Cancella filtri
              </span>
            )}
          </span>
        }
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/segnalazioni">
              Segnalazioni
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <>
            {user.amministratore && (
              <Button
                onClick={() => {
                  getToken
                    .onSuccess((link) => {
                      window.open(link, '_blank')
                    })
                    .run(filters)
                }}
              >
                Esporta
              </Button>
            )}
          </>
        }
        bottomLeft={
          <SearchBar
            initialValue={filters.search}
            onChange={(search) =>
              setSearchParams({ ...filters, page: 1, search })
            }
          />
        }
        bottomRight={
          <Button
            isDisabled={openFilters}
            onClick={() => setOpenFilters(true)}
            variant="secondary"
          >
            {filtersCount === 0 && 'Filtra'}
            {filtersCount > 0 && (
              <>
                Filtri applicati
                <Badge className="pf-u-ml-sm">{filtersCount}</Badge>
              </>
            )}
          </Button>
        }
      />
      <ScrollableContent>
        {openFilters && (
          <ListFiltersOverlay className="pf-u-mt-xl">
            <FiltersForm
              operatoriTipiMezzo={operatoriTipiMezzo}
              initialValues={{
                tipo_mezzo: filters.tipo_mezzo,
                inizio: filters.inizio,
                fine: filters.fine,
                vie: filters.vie,
                operatore: filters.operatore,
                stato: filters.stato,
              }}
              onClose={() => setOpenFilters(false)}
              onSubmit={(values) => {
                setSearchParams({
                  ...filters,
                  page: 1,
                  tipo_mezzo: values.tipo_mezzo,
                  inizio: values.inizio,
                  fine: values.fine,
                  vie: values.vie,
                  operatore: values.operatore,
                  stato: values.stato,
                })
                setOpenFilters(false)
              }}
            />
          </ListFiltersOverlay>
        )}
        {!openFilters && (
          <>
            <TableComposable isStickyHeader>
              <Thead>
                <Tr>
                  <Th sort={sorter('pk')}>ID</Th>
                  <Th sort={sorter('rilievo__tipo_mezzo')}>Mezzo</Th>
                  <Th sort={sorter('rilievo__operatore')}>Operatore</Th>
                  <Th sort={sorter('rilievo__identificativo_mezzo')}>
                    Identificativo
                  </Th>
                  <Th sort={sorter('rilievo__via')}>Via</Th>
                  <Th sort={sorter('numero_segnalazioni')}>N Anomalie</Th>
                  <Th sort={sorter('dataora_creazione')}>Data ora</Th>
                  <Th sort={sorter('stato')}>Stato</Th>
                </Tr>
              </Thead>
              <Tbody>
                {list &&
                  list.map((segnalazione) => (
                    <Tr
                      className={classNames({
                        'pf-u-background-color-info': segnalazione.has_actions,
                      })}
                      isHoverable
                      key={segnalazione.id}
                      onRowClick={() =>
                        navigate(`/segnalazioni/${segnalazione.id}`)
                      }
                    >
                      <Td>{segnalazione.id}</Td>
                      <Td>{TIPI_MEZZO[segnalazione.rilievo.tipo_mezzo]}</Td>
                      <Td>{segnalazione.rilievo.operatore_data.nome}</Td>
                      <Td>{segnalazione.rilievo.identificativo_mezzo}</Td>
                      <Td>{segnalazione.rilievo.via_data.nome}</Td>
                      <Td>{segnalazione.numero_segnalazioni}</Td>
                      <Td>
                        {dayjs(segnalazione.dataora_creazione).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </Td>
                      <Td>{STATI_SEGNALAZIONE[segnalazione.stato]}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </TableComposable>
            {pagination && (
              <Pagination
                variant="bottom"
                isSticky
                onSetPage={(_, page) => {
                  setSearchParams({ ...filters, page })
                }}
                page={+(searchParams.get('page') ?? 1)}
                perPageOptions={[{ title: PAGE_SIZE, value: PAGE_SIZE }]}
                itemCount={pagination.count}
                perPage={PAGE_SIZE}
              />
            )}
          </>
        )}
      </ScrollableContent>
    </FullPage>
  )
}
