import {
  Nav,
  NavList,
  NavItem,
  Page,
  PageSidebar,
  PageSection,
} from '@patternfly/react-core'
import { Outlet, useMatch, useResolvedPath } from 'react-router'
import { Link } from 'react-router-dom'
import { UserIcon } from '@patternfly/react-icons'
import { useAuthUser } from 'use-eazy-auth'

function NavItemLink({
  children,
  to,
  end = false,
  linkClassName = '',
  ...props
}) {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end })

  return (
    <NavItem isActive={match} {...props}>
      <Link className={linkClassName} to={to}>
        {children}
      </Link>
    </NavItem>
  )
}

export default function Layout() {
  const { user } = useAuthUser()
  return (
    <Page
      className="amat-page"
      sidebar={
        <PageSidebar
          nav={
            <Nav className="pf-u-display-flex pf-u-flex-direction-column pf-u-h-100">
              <NavList className="pf-u-flex-grow-1">
                {user.amministratore && (
                  <>
                    <NavItemLink end to="/">
                      Dashboard
                    </NavItemLink>
                    <NavItemLink to="/rilievi">Rilievi</NavItemLink>
                    <NavItemLink to="/reports-completi">
                      Reports Completi
                    </NavItemLink>
                    <NavItemLink to="/reports-rapidi">
                      Reports Rapidi
                    </NavItemLink>
                  </>
                )}
                {(user.amministratore || user.account_operatore) && (
                  <NavItemLink to="/segnalazioni">Segnalazioni</NavItemLink>
                )}
                {user.amministratore && (
                  <>
                    <NavItemLink to="/rilevatori">
                      Gestione Rilevatori
                    </NavItemLink>
                    <NavItemLink to="/amministratori">
                      Gestione Amministratori
                    </NavItemLink>
                    <NavItemLink to="/account-operatore">
                      Gestione Account Operatore
                    </NavItemLink>
                    <NavItemLink to="/map">Gestione Mappa</NavItemLink>
                    <NavItemLink to="/anagrafica-mezzi">
                      Anagrafica Mezzi
                    </NavItemLink>
                    <NavItemLink to="/operatori">Operatori</NavItemLink>
                  </>
                )}
                {user.rilevatore && (
                  <NavItemLink to="/videos">Carica Video</NavItemLink>
                )}
              </NavList>
              <NavList>
                <NavItemLink
                  linkClassName="pf-u-display-flex pf-u-justify-content-space-between"
                  className="nav-link-no-border"
                  to="/profile"
                >
                  <UserIcon />
                  {user.name}
                </NavItemLink>
              </NavList>
            </Nav>
          }
        />
      }
    >
      <PageSection variant="light" className="pf-u-h-100">
        <Outlet />
      </PageSection>
    </Page>
  )
}
