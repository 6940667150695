import { Button, TextInput } from '@patternfly/react-core'
import { useState } from 'react'

export default function SearchBar({ initialValue, onChange }) {
  const [value, setValue] = useState(initialValue ?? '')

  return (
    <form
      className="pf-u-display-flex"
      onSubmit={(e) => {
        e.preventDefault()
        onChange(value)
      }}
    >
      <TextInput
        id='search-bar'
        iconVariant="search"
        style={{ width: 250 }}
        value={value}
        onChange={setValue}
      />
      <Button className="pf-u-ml-sm" type="submit">
        Cerca
      </Button>
    </form>
  )
}
