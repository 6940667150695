import React from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { transformErrorForForm, InputField } from './Form'
import {
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
} from '@patternfly/react-core'

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string().required().label('Password'),
  repeatPassword: Yup.string()
    .required()
    .label('Ripeti password')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono.'),
})

const INITIAL_VALUES = {
  password: '',
  repeatPassword: '',
}

export default function ChangePasswordForm({ save, className }) {
  return (
    <Formik
      validationSchema={ChangePasswordSchema}
      initialValues={INITIAL_VALUES}
      onSubmit={(values, actions) =>
        save(values)
          .then(() => {
            actions.setStatus({ success: true })
          })
          .catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
      }
    >
      {({ handleSubmit, isSubmitting, resetForm, status }) => (
        <Form onSubmit={handleSubmit} className={className} isHorizontal>
          {status?.success && (
            <FormAlert>
              <Alert
                variant="success"
                title={'Password modficata con successo'}
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          <div className="pf-u-pt-m" />
          <Field
            isRequired
            type="password"
            label="Password"
            name="password"
            component={InputField}
          />
          <Field
            isRequired
            type="password"
            label="Ripeti password"
            name="repeatPassword"
            component={InputField}
          />
          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between">
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => {
                  resetForm({ values: INITIAL_VALUES })
                }}
              >
                Annulla
              </Button>
              <Button isDisabled={isSubmitting} type="submit">
                Modifica
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
