import { Field, Formik } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { InputField, transformErrorForForm } from '../../components/Form'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
} from '@patternfly/react-core'
import { useRecoverPassword } from '../../hooks/recover'

const RecoverSchema = Yup.object().shape({
  email: Yup.string().email().required().label('Email'),
})

export default function Recover() {
  const [{ data: recovered }, { run }] = useRecoverPassword()

  if (recovered) {
    return (
      <div className="pf-u-display-flex pf-u-flex-direction-column pf-u-h-100 pf-u-align-items-center pf-u-justify-content-center">
        <Card style={{ maxWidth: 600, width: '100%' }} className="pf-u-p-xl">
          <CardTitle>Monitoraggio Sharing</CardTitle>
          <CardBody>
            <Alert
              variant="success"
              isInline
              title="Se il tuo account è presente nei nostri sistemi riceverai una mail dalla quale potrai secegliere una nuova password."
            />
          </CardBody>
          <p>
            Vai al <Link to="/login">login</Link>.
          </p>
        </Card>
      </div>
    )
  }

  return (
    <div className="pf-u-display-flex pf-u-flex-direction-column pf-u-h-100 pf-u-align-items-center pf-u-justify-content-center">
      <Card style={{ maxWidth: 600, width: '100%' }} className="pf-u-p-xl">
        <CardTitle>Monitoraggio Sharing</CardTitle>
        <CardBody>
          <b>Recupera Password</b>
          <Formik
            validationSchema={RecoverSchema}
            initialValues={{
              email: '',
            }}
            onSubmit={(values, actions) =>
              run
                .asPromise({
                  email: values.email,
                })
                .catch((err) => {
                  actions.setErrors(transformErrorForForm(err))
                })
            }
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="pf-u-pt-m" />
                <Field
                  isRequired
                  type="email"
                  label="Email"
                  name="email"
                  component={InputField}
                />
                <p>
                  Ti sarà inviata una mail dalla quale potrai scegliere una
                  nuova password. <br />
                  Vai al <Link to="/login">login</Link>.
                </p>
                <FormGroup>
                  <Button isDisabled={isSubmitting} type="submit">
                    Recupera
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  )
}
