import { Chip } from '@patternfly/react-core'

export default function BigChip(props) {
  const { children, className = '', ...other } = props
  const newClassName = className + ' no-ellipsis-chip pf-u-p-sm'
  return (
    <Chip className={newClassName} {...other}>
      {children}
    </Chip>
  )
}
