import { Breadcrumb, Button, Modal, ModalVariant } from '@patternfly/react-core'
import { useNavigate, useParams } from 'react-router'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import AmministratoreForm from '../../../components/AmministratoreForm'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useAmministratoreDetail } from '../../../hooks/amministratore'

export default function AmministratoreDetail() {
  const { id } = useParams()
  const [
    { data: amministratore },
    { updateAmministratore, removeAmministratore },
  ] = useAmministratoreDetail(id)
  const [modalRm, modalRmActions] = useModalTrigger()
  const navigate = useNavigate()

  return (
    <FullPage>
      <SectionHeader
        title={amministratore?.user?.name ?? ''}
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/amministratori">
              Gestione amministratori
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/amministratori/${id}`}>
              Dettaglio amministratore
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <Button onClick={modalRmActions.open} variant="danger">
            Elimina
          </Button>
        }
      />
      <ScrollableContent>
        {amministratore && (
          <AmministratoreForm
            amministratore={amministratore}
            save={(data) =>
              updateAmministratore.asPromise(amministratore.id, data)
            }
          />
        )}
      </ScrollableContent>
      {amministratore && (
        <Modal
          title={'Rimuovi amministratore'}
          className="modal-actions-end"
          isOpen={modalRm.isOpen}
          variant={ModalVariant.medium}
          actions={[
            <Button onClick={modalRmActions.close} variant="secondary">
              Annulla
            </Button>,
            <Button
              onClick={() => {
                removeAmministratore
                  .onSuccess(() => {
                    navigate('/amministratori')
                  })
                  .run(amministratore.id)
              }}
            >
              Conferma
            </Button>,
          ]}
          onClose={modalRmActions.close}
        >
          Rimuovere amministratore {amministratore.user.name} ?
        </Modal>
      )}
    </FullPage>
  )
}
