import { useCallback, useMemo, useState } from 'react'
import {
  Badge,
  Breadcrumb,
  Button,
  Modal,
  ModalVariant,
  ModalBoxBody,
} from '@patternfly/react-core'
import {
  EditAltIcon,
  PlusCircleIcon,
  TimesCircleIcon,
} from '@patternfly/react-icons'
import { Link, useParams } from 'react-router-dom'
import keyBy from 'lodash/keyBy'
import dayjs from 'dayjs'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import BigChip from '../../../components/BigChip'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import ReportDocForm from '../../../components/ReportDocForm'
import { TIPI_MEZZO } from '../../../consts'
import {
  useReportRapidoDetail,
  useReportRapidoRilievi,
} from '../../../hooks/reportsRapidi'
import NoteOperatoreFormBoxModal from './NoteOperatoreFormBoxModal'

export default function ReporDocument() {
  const { id } = useParams()
  const [
    { data: report, closing },
    { updateReport, chiudiReport, addNota, updateNota, removeNota },
  ] = useReportRapidoDetail(id)
  const [{ data: rilievi }] = useReportRapidoRilievi(id)
  const [modalEdit, modalEditActions] = useModalTrigger()
  const [modalClose, modalCloseActions] = useModalTrigger()
  const [modalNote, modalNoteActions] = useModalTrigger()
  const [modalRmNota, modalRmNotaActions] = useModalTrigger()

  const [embedState, setEmbedState] = useState(0)
  const flipEmbedState = useCallback(
    () => setEmbedState((e) => (e === 0 ? 1 : 0)),
    []
  )
  const embedContentType = report
    ? new URL(report.pdf_path).pathname.endsWith('/html/')
      ? 'text/html'
      : 'application/pdf'
    : null

  const operatoryById = useMemo(() => {
    if (!report) {
      return {}
    }
    return keyBy(report.operatori_data, 'id')
  }, [report])
  const operatoriOptionsForNotes = useMemo(() => {
    if (!report) {
      return []
    }
    const flatOperatoriInNotes = report.note_operatori.map((n) => n.operatore)
    const deOptions = report.operatori_data.filter(
      (r) => !flatOperatoriInNotes.includes(r.id)
    )
    if (modalNote.value && operatoryById[modalNote.value.operatore]) {
      deOptions.push(operatoryById[modalNote.value.operatore])
    }
    return deOptions
  }, [report, modalNote.value, operatoryById])

  return (
    <FullPage>
      <SectionHeader
        title={`Documento Report Rapido ${report?.id ?? ''}`}
        centerRight={
          <>
            {report && (
              <>
                <Button component={Link} to={`/reports-rapidi/${id}`}>
                  Visualizza rilievi
                </Button>
                {report.pdf_preview_download && !report.pdf_file && (
                  <Button
                    component="a"
                    className="pf-u-ml-xs"
                    target='_blank'
                    href={report.pdf_preview_download}
                  >
                    Download PDF
                  </Button>
                )}
                {!report.dataora_chiusura && (
                  <Button
                    variant="danger"
                    className="pf-u-ml-xs"
                    isDisabled={report ? !report.chiudibile : true}
                    onClick={() => {
                      modalCloseActions.open()
                    }}
                  >
                    Chiudi Report
                  </Button>
                )}
                {report.xlsx_file && (
                  <Button
                    component="a"
                    className="pf-u-ml-xs"
                    href={report.xlsx_file}
                    download
                  >
                    Download xlsx
                  </Button>
                )}
                {report.pdf_file && (
                  <Button
                    component="a"
                    className="pf-u-ml-xs"
                    href={report.pdf_file}
                    download
                  >
                    Download PDF
                  </Button>
                )}
              </>
            )}
          </>
        }
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/reports-rapidi">
              Reports Rapidi
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/reports-rapidi/${id}`}>
              Report Rapido {id}
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/reports-rapidi/${id}/doc`}>
              Documento Report
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        bottomLeft={
          report && (
            <div className="pf-u-mb-sm">
              <BigChip isReadOnly className="pf-u-mr-xs">
                N. rilievi: <Badge>{rilievi?.length}</Badge>
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Periodo:</b> dal{' '}
                {dayjs(report.periodo_da).format('DD/MM/YYYY')} al{' '}
                {dayjs(report.periodo_a).format('DD/MM/YYYY')}
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Tipo</b>: {TIPI_MEZZO[report.tipo_mezzo]}
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Operatori:</b>{' '}
                {report.operatori_data.map((operatore, i) => {
                  const isLast = i === report.operatori_data.length - 1
                  return (
                    <span
                      className={isLast ? '' : 'pf-u-mr-xs'}
                      key={operatore.id}
                    >
                      {operatore.nome}
                      {!isLast && ','}
                    </span>
                  )
                })}
              </BigChip>
              {report.vie_data.length > 0 &&
                report.vie_data.map((via) => (
                  <BigChip key={via.id} isReadOnly className="pf-u-mr-xs">
                    <b>Via:</b>
                    {via.nome}
                  </BigChip>
                ))}
              {report.vie_data.length === 0 && (
                <BigChip isReadOnly className="pf-u-mr-xs">
                  <b>Vie:</b> Nessuna via selezionata
                </BigChip>
              )}
            </div>
          )
        }
      />
      <ScrollableContent>
        {report && (
          <div className="pf-u-h-100 pf-u-display-flex pf-u-flex-direction-column">
            <embed
              key={embedState}
              className="pf-u-flex-grow-1 preview-pdf"
              src={`${report.pdf_path}#toolbar=0`}
              // src={`${report.pdf_path}`}
              style={{ width: '100%' }}
              type={embedContentType}
            />
            <div className="pf-u-mt-sm pf-u-display-flex">
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Codifica:</b> {report.codifica ?? 'N.D.'}
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Revisione:</b> {report.revisione ?? 'N.D.'}
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Data:</b>{' '}
                {report.data_doc
                  ? dayjs(report.data_doc).format('DD/MM/YYYY')
                  : 'N.D.'}
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <a
                  style={
                    report.dataora_chiusura
                      ? { pointerEvents: 'none', opacity: 0.5 }
                      : undefined
                  }
                  onClick={modalEditActions.open}
                >
                  Modifica
                </a>
              </BigChip>
              {!report.dataora_chiusura && (
                <div
                  className="pf-u-display-flex pf-u-flex-direction-row pf-u-justify-content-flex-end"
                  style={{ flex: 1 }}
                >
                  {report.note_operatori.map((noteOp) => (
                    <BigChip isReadOnly className="pf-u-mr-xs" key={noteOp.id}>
                      <small>
                        <EditAltIcon
                          onClick={() => {
                            modalNoteActions.open(noteOp)
                          }}
                          className="pf-u-active-color-100 cursor-pointer pf-u-mr-xs"
                        />
                        <TimesCircleIcon
                          onClick={() => {
                            modalRmNotaActions.open(noteOp)
                          }}
                          className="pf-u-danger-color-100 cursor-pointer pf-u-mr-xs"
                        />
                      </small>
                      <small>
                        Note: {operatoryById[noteOp.operatore]?.nome}
                      </small>
                    </BigChip>
                  ))}
                  {operatoriOptionsForNotes.length > 0 && (
                    <Button
                      variant="secondary"
                      isSmall
                      onClick={() => modalNoteActions.open()}
                    >
                      <PlusCircleIcon className="pf-u-mr-xs" />
                      Nota
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </ScrollableContent>
      <Modal
        variant={ModalVariant.medium}
        title="Modifica Proprietà documento"
        isOpen={modalEdit.isOpen}
        onClose={modalEditActions.close}
        hasNoBodyWrapper
        aria-label="modifca proprietà documento"
      >
        <ModalBoxBody style={{ overflow: 'initial' }}>
          <ReportDocForm
            tipo="rapido"
            report={report}
            cancel={modalEditActions.close}
            save={(data) =>
              updateReport.asPromise(report.id, data).then(() => {
                modalEditActions.close()
                flipEmbedState()
              })
            }
          />
        </ModalBoxBody>
      </Modal>
      <Modal
        title={'Chiudi report'}
        className="modal-actions-end"
        isOpen={modalClose.isOpen}
        variant={ModalVariant.medium}
        actions={[
          <Button
            key="undo"
            isDisabled={closing}
            onClick={modalCloseActions.close}
            variant="secondary"
          >
            Annulla
          </Button>,
          <Button
            key="ok"
            isDisabled={closing}
            onClick={() => {
              chiudiReport
                .onSuccess(() => {
                  flipEmbedState()
                  modalCloseActions.close()
                })
                .run(id)
            }}
          >
            Conferma
          </Button>,
        ]}
        onClose={modalCloseActions.close}
      >
        Chiudere il report corrente?
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title={
          modalNote.value ? 'Modifica nota operatore' : 'Nuova nota operatore'
        }
        isOpen={modalNote.isOpen}
        onClose={modalNoteActions.close}
        hasNoBodyWrapper
        aria-label="modal note"
      >
        <NoteOperatoreFormBoxModal
          noteOperatore={modalNote.value}
          onClose={modalNoteActions.close}
          operatoriOptions={operatoriOptionsForNotes}
          save={(data) => {
            if (modalNote.value) {
              return updateNota
                .onSuccess(() => {
                  flipEmbedState()
                  modalNoteActions.close()
                })
                .asPromise(modalNote.value.id, {
                  report: report.id,
                  ...data,
                })
            } else {
              return addNota
                .onSuccess(() => {
                  flipEmbedState()
                  modalNoteActions.close()
                })
                .asPromise({
                  report: report.id,
                  ...data,
                })
            }
          }}
        />
      </Modal>
      <Modal
        title={'Rimuovere nota operatore'}
        className="modal-actions-end"
        isOpen={modalRmNota.isOpen}
        variant={ModalVariant.medium}
        actions={[
          <Button
            key="undo"
            onClick={modalRmNotaActions.close}
            variant="secondary"
          >
            Annulla
          </Button>,
          <Button
            key="ok"
            onClick={() => {
              removeNota
                .onSuccess(() => {
                  flipEmbedState()
                  modalRmNotaActions.close()
                })
                .run(modalRmNota.value.id)
            }}
          >
            Conferma
          </Button>,
        ]}
        onClose={modalRmNotaActions.close}
      >
        Rimuovere nota operatore{' '}
        {operatoryById[modalRmNota.value?.operatore]?.nome} ?
      </Modal>
    </FullPage>
  )
}
