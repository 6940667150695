import { rj, useRj, useRunRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

const AccountOperatoreListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .effect({
    name: 'AccountOperatoreList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/account-operatore', params),
  })

const AddAccountOperatoreState = rj().effect({
  name: 'AddAccountOperatore',
  effectCaller: 'configured',
  effect: (t) => (data) => api.auth(t).post('/api/account-operatore', data),
})

const AccountOperatoreDetailState = rj()
  .mutations({
    updateAccount: {
      updater: 'updateData',
      effect: (t) => (id, data) =>
        api.auth(t).put(`/api/account-operatore/${id}/`, data),
    },
    removeAccount: {
      updater: (s) => s,
      effect: (t) => (id) => api.auth(t).delete(`/api/account-operatore/${id}/`),
    },
  })
  .effect({
    name: 'AccountOperatoreDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/api/account-operatore/${id}`),
  })

export function useAddAccountOperatore() {
  return useRj(AddAccountOperatoreState)
}

export function useAccountOperatoreDetail(id) {
  return useRunRj(AccountOperatoreDetailState, [id])
}

export function useAccountOperatoreList(params) {
  return useRunRj(AccountOperatoreListState, [params], false)
}
