import { rj, useRj, useRunRj } from 'react-rocketjump'
import api from '../api'

const EditAmministratoreProfileState = rj({
  name: 'EditAmministratoreProfile',
  effectCaller: 'configured',
  effect: (t) => (data) => {
    const formData = new FormData()
    if (data.firma instanceof File) {
      formData.append('firma', data.firma)
    }
    formData.append('riceve_mail_segnalazione', data.riceve_mail_segnalazione)
    return api.auth(t).post('/api/amministratore/profile', formData)
  },
})

export function useEditAmministratoreProfile() {
  return useRj(EditAmministratoreProfileState)
}

const EditRilevatoreProfileState = rj({
  name: 'EditRilevatoreProfile',
  effectCaller: 'configured',
  effect: (t) => (data) => {
    const formData = new FormData()
    if (data.firma instanceof File) {
      formData.append('firma', data.firma)
    }
    return api.auth(t).post('/api/rilevatore/profile', formData)
  },
})

export function useEditRilevatoreProfile() {
  return useRj(EditRilevatoreProfileState)
}

const ChangePasswordState = rj({
  name: 'ChangePassword',
  effectCaller: 'configured',
  effect: (t) => (data) => {
    return api
      .auth(t)
      .mapResponse(() => true)
      .put('/api/change-password', data)
  },
})

export function useChangePassword() {
  return useRj(ChangePasswordState)
}
