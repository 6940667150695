import { Field, Formik } from 'formik'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import * as Yup from 'yup'
import { useParams } from 'react-router'
import { InputField, transformErrorForForm } from '../../components/Form'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
} from '@patternfly/react-core'
import { useCheckResetToken, useResetPassword } from '../../hooks/reset'

const ResetSchema = Yup.object().shape({
  password: Yup.string().required().label('Password'),
  repeatPassword: Yup.string()
    .required()
    .label('Ripeti password')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono.'),
})

export default function Reset() {
  const { token } = useParams()
  const [{ data: isTokenOk, error }] = useCheckResetToken(token)
  const { run: resetPassword } = useResetPassword()[1]
  const [activated, setActivated] = useState(false)

  if (error) {
    return <div>Token scaduto o invalido.</div>
  }

  if (!isTokenOk) {
    return null
  }

  if (activated) {
    return (
      <div className="pf-u-display-flex pf-u-flex-direction-column pf-u-h-100 pf-u-align-items-center pf-u-justify-content-center">
        <Card style={{ maxWidth: 650, width: '100%' }} className="pf-u-p-xl">
          <CardTitle>Monitoraggio Sharing</CardTitle>
          <CardBody>
            <b>Password cambiata.</b>
            <div>
              Vai al <Link to="/login">login</Link>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

  return (
    <div className="pf-u-display-flex pf-u-flex-direction-column pf-u-h-100 pf-u-align-items-center pf-u-justify-content-center">
      <Card style={{ maxWidth: 650, width: '100%' }} className="pf-u-p-xl">
        <CardTitle>Monitoraggio Sharing</CardTitle>
        <CardBody>
          <b>Scegli una nuova password.</b>
          <Formik
            validationSchema={ResetSchema}
            initialValues={{
              password: '',
              repeatPassword: '',
            }}
            onSubmit={(values, actions) =>
              resetPassword
                .asPromise(token, {
                  password: values.password,
                })
                .then(() => {
                  setActivated(true)
                })
                .catch((err) => {
                  actions.setErrors(transformErrorForForm(err))
                })
            }
          >
            {({ handleSubmit, isSubmitting, resetForm, status }) => (
              <Form onSubmit={handleSubmit} isHorizontal>
                <div className="pf-u-pt-m" />
                <Field
                  isRequired
                  type="password"
                  label="Password"
                  name="password"
                  component={InputField}
                />
                <Field
                  isRequired
                  type="password"
                  label="Ripeti password"
                  name="repeatPassword"
                  component={InputField}
                />
                <FormGroup>
                  <Button isDisabled={isSubmitting} type="submit">
                    Resetta Password
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  )
}
