import { rj, useRj, useRunRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

const RilevatoriListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .effect({
    name: 'RilevatoriList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/rilevatori', params),
  })

const AddRilevatoreState = rj().effect({
  name: 'AddRilevatore',
  effectCaller: 'configured',
  effect: (t) => (data) => {
    const formData = new FormData()
    formData.append('user.email', data.user.email)
    formData.append('user.name', data.user.name)
    if (data.firma instanceof File) {
      formData.append('firma', data.firma)
    }
    formData.append(
      'rilievi_completi_tipi_mezzo',
      JSON.stringify(data.rilievi_completi_tipi_mezzo)
    )
    formData.append(
      'rilievi_rapidi_tipi_mezzo',
      JSON.stringify(data.rilievi_rapidi_tipi_mezzo)
    )
    return api.auth(t).post('/api/rilevatori', formData)
  },
})

const RilevatoreDetailState = rj()
  .mutations({
    updateRilevatore: {
      updater: 'updateData',
      effect: (t) => (id, data) => {
        const formData = new FormData()
        formData.append('user.id', data.user.id)
        formData.append('user.email', data.user.email)
        formData.append('user.name', data.user.name)
        formData.append('user.is_active', data.user.is_active)
        if (data.firma instanceof File) {
          formData.append('firma', data.firma)
        } else if (data.firma === null) {
          // Remove file!
          formData.append('firma', '')
        }
        formData.append(
          'rilievi_completi_tipi_mezzo',
          JSON.stringify(data.rilievi_completi_tipi_mezzo)
        )
        formData.append(
          'rilievi_rapidi_tipi_mezzo',
          JSON.stringify(data.rilievi_rapidi_tipi_mezzo)
        )
        return api.auth(t).put(`/api/rilevatori/${id}/`, formData)
      },
    },
    removeRilevatore: {
      updater: (s) => s,
      effect: (t) => (id) => api.auth(t).delete(`/api/rilevatori/${id}/`),
    },
  })
  .effect({
    name: 'RilevatoreDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/api/rilevatori/${id}`),
  })

export function useAddRilevatore() {
  return useRj(AddRilevatoreState)
}

export function useRilevatoreDetail(id) {
  return useRunRj(RilevatoreDetailState, [id])
}

export function useRilevatoriList(params) {
  return useRunRj(RilevatoriListState, [params], false)
}
