import {
  Alert,
  Badge,
  Banner,
  Breadcrumb,
  Button,
  Form,
  FormSelectOption,
  Grid,
  GridItem,
  Modal,
  ModalBoxBody,
  ModalBoxFooter,
  ModalVariant,
  Text,
  Title,
} from '@patternfly/react-core'
import dayjs from 'dayjs'
import {
  TableComposable,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from '@patternfly/react-table'
import { PlayIcon, EditAltIcon } from '@patternfly/react-icons'
import { useNavigate, useParams } from 'react-router'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useRilievoDetail, useRilievoLogs } from '../../../hooks/rilievo'
import RilievoDetailIntro from './RilievoDetailIntro'
import RilievoScheda from './RilievoScheda'
import {
  InputField,
  SelectField,
  TextAreaField,
  transformErrorForForm,
} from '../../../components/Form'
import {
  N_FOTO_AGGIUNTIVE_RILIEVO,
  STATI_RILIEVO,
  TIPI_RILIEVO,
} from '../../../consts'
import classNames from 'classnames'
import range from 'lodash/range'
import { useOperatoriTipiMezzoFlat } from '../../../hooks/operatori'
import { useMemo } from 'react'

const InvalidaValidationSchema = Yup.object().shape({
  motivazione: Yup.mixed().required().label('Motivazione'),
})

function InvalidaFormBoxModal({ onClose, save }) {
  return (
    <Formik
      validationSchema={InvalidaValidationSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{ motivazione: '' }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody>
            <Field
              isRequired
              label={'Motivazione'}
              name="motivazione"
              component={TextAreaField}
            />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

function EditFieldBoxModalTableQuestion({ data, onClose, save }) {
  const { field, value, label, nome_scheda, domanda } = data
  return (
    <Formik
      onSubmit={(values, actions) =>
        save('tablequestion', {
          nome_scheda,
          domanda,
          nome_campo: field.name,
          valore: values.field,
        }).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{ field: value }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <ModalBoxBody>
            <h2>{field.label}</h2>
            <Field
              isRequired
              label={label}
              name="field"
              component={SelectField}
            >
              {field.choices.map((o) => (
                <FormSelectOption label={o[1]} value={o[0]} key={o[0]} />
              ))}
            </Field>
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

function EditFieldBoxModalText({ data, onClose, save }) {
  const { field, value, nome_scheda } = data
  return (
    <Formik
      onSubmit={(values, actions) =>
        save('field', {
          nome_scheda,
          nome_campo: field.name,
          valore: values.field,
        }).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{ field: value }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <ModalBoxBody>
            <Field
              isRequired
              label={field.label}
              name="field"
              component={TextAreaField}
            />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

function RowNotaAdmin({ rilievo, label, fullLabel, name, openModal }) {
  return (
    <div className="pf-u-mb-md">
      <div className="pf-u-display-flex pf-u-align-items-center">
        <Title headingLevel="h4" size="lg">
          {label}
        </Title>
        {rilievo.editable && (
          <EditAltIcon
            onClick={() => {
              openModal({
                label: fullLabel,
                name: name,
                value: rilievo[name],
              })
            }}
            className="cursor-pointer pf-u-active-color-100 pf-u-ml-md"
            size="sm"
          />
        )}
      </div>
      <p>{rilievo[name]}</p>
    </div>
  )
}

const MoveImageSchema = Yup.object().shape({
  domanda: Yup.mixed().required().label('Domanda'),
})

function MovaImageBoxModal({ data, onClose, save }) {
  const { field, label, nome_scheda, domanda } = data
  return (
    <Formik
      onSubmit={(values, actions) =>
        save({
          nome_scheda,
          da_domanda: domanda,
          a_domanda: +values.domanda,
          nome_campo: field.name,
        }).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      validationSchema={MoveImageSchema}
      initialValues={{ domanda: '' }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <ModalBoxBody>
            <h2>{field.label}</h2>
            <br />
            <h3>
              <b>Sposta da</b> {label}
            </h3>
            <Field
              isRequired
              label={'A'}
              name="domanda"
              component={SelectField}
            >
              <FormSelectOption
                label={'-- Scegli dove spostare --'}
                value={''}
              />
              {field.questions
                .filter((q) => q.pk !== domanda)
                .map((q) => (
                  <FormSelectOption label={q.label} value={q.pk} key={q.pk} />
                ))}
            </Field>
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Sposta
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

function LogRow({ log }) {
  const msg = (
    <span>
      {log.amministratore.user.name} ha modificato:{' '}
      {log.label ?? log.nome_campo}
      {log.domanda ? ` (${log.domanda.label})` : null}
      {' da '}
      <Badge isRead>{log.valore_iniziale_label ?? log.valore_iniziale}</Badge>
      {' a '}
      <Badge isRead>{log.valore_finale_label ?? log.valore_finale}</Badge>
    </span>
  )
  return (
    <Tr>
      <Td>{msg}</Td>
      <Td style={{ whiteSpace: 'nowrap' }}>
        {dayjs(log.dataora).format('DD/MM/YYYY HH:mm')}
      </Td>
    </Tr>
  )
}

function LogsBoxModal({ onClose, logs }) {
  return (
    <>
      <ModalBoxBody>
        <TableComposable>
          <Thead>
            <Tr>
              <Th>Messaggio</Th>
              <Th>Data ora</Th>
            </Tr>
          </Thead>
          <Tbody>
            {logs.map((log) => (
              <LogRow key={log.id} log={log} />
            ))}
          </Tbody>
        </TableComposable>
      </ModalBoxBody>
      <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
        <Button variant="link" onClick={onClose}>
          Chiudi
        </Button>
      </ModalBoxFooter>
    </>
  )
}

function EditFieldBoxModal({ data, ...props }) {
  if (data.field.type === 'tablequestions') {
    return <EditFieldBoxModalTableQuestion data={data} {...props} />
  } else if (data.field.type === 'text') {
    return <EditFieldBoxModalText data={data} {...props} />
  }
  return null
}

const IdentificativoMezzoSchema = Yup.object().shape({
  identificativo_mezzo: Yup.string().required().label('Identificativo mezzo'),
})

function IdentificativoMezzoFormBoxModal({ onClose, save, data }) {
  return (
    <Formik
      validationSchema={IdentificativoMezzoSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{ identificativo_mezzo: data.identificativo_mezzo }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody>
            <Field
              isRequired
              label={'Identificativo mezzo'}
              name="identificativo_mezzo"
              component={InputField}
            />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

function OperatoreFormBoxModal({ onClose, save, data }) {
  const [{ data: operatoriTipoMezzo }] = useOperatoriTipiMezzoFlat(
    data.tipo_mezzo
  )
  const options = useMemo(() => {
    if (!operatoriTipoMezzo) {
      return []
    }
    return operatoriTipoMezzo.map((ot) => ot.operatore)
  }, [operatoriTipoMezzo])
  return (
    <Formik
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{ operatore: data.operatore }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody>
            <Field
              isDisabled={options.length === 0}
              isRequired
              label={'Operatore'}
              name="operatore"
              component={SelectField}
            >
              {options.map((o) => (
                <FormSelectOption label={o.nome} value={o.id} key={o.id} />
              ))}
            </Field>
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

function NotaAmministraotreFormBoxModal({ onClose, save, label, value }) {
  return (
    <Formik
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{ nota: value }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody>
            <Field label={label} name="nota" component={InputField} />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

export default function RilievoDetail() {
  const { id } = useParams()
  const [
    { data: rilievo },
    {
      invalidaRilievo,
      modificaAmministratoreTesta,
      forceEditableAmministratore,
      spostaImmagineAmministratoreTableQuestion,
      rimuoviImmagineAmministratoreTableQuestion,
      modificaAmministratoreTableQuestion,
      modificaAmministratoreField,
    },
  ] = useRilievoDetail(id)
  const [{ data: logs }, { run: fetchLogs }] = useRilievoLogs(id)
  const [modalRm, modalRmActions] = useModalTrigger()
  const [modalImg, modalImgActions] = useModalTrigger()
  const [modalVideo, modalVideoActions] = useModalTrigger()
  const [modalEdit, modalEditActions] = useModalTrigger()
  const [modalMoveImage, modalMoveImageActions] = useModalTrigger()
  const [modalRemoveImage, modalRemoveImageActions] = useModalTrigger()
  const [modalLogs, modalLogsActions] = useModalTrigger()
  const [modalIdentificativo, modalIdentificativoActions] = useModalTrigger()
  const [modalOperatore, modalOperatoreActions] = useModalTrigger()
  const [modalNoteAdmin, modalNoteAdminActions] = useModalTrigger()
  const [modalForceEditable, modalForceEditableActions] = useModalTrigger()
  const navigate = useNavigate()

  return (
    <FullPage>
      <SectionHeader
        title={
          rilievo ? (
            <span className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center">
              <span className="pf-u-mr-md">
                Rilievo {TIPI_RILIEVO[rilievo.tipo_rilievo]} {rilievo.id}
              </span>{' '}
              <Badge className="pf-u-p-xs pf-u-px-sm">
                {STATI_RILIEVO[rilievo.stato]}
              </Badge>
              {!rilievo.video_ok && (
                <Badge isRead className="pf-u-p-xs pf-u-ml-md">
                  Upload video incompleto
                </Badge>
              )}
            </span>
          ) : (
            `Rilievo ${id}`
          )
        }
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/rilievi">Rilievi</BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/rilievi/${id}`}>
              Dettaglio rilievo {id}
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <>
            {rilievo && (
              <>
                {!rilievo.editable && !rilievo.in_report_chiuso && (
                  <Button
                    variant="warning"
                    onClick={modalForceEditableActions.open}
                    className="pf-u-mr-md"
                  >
                    Forza Modifiche
                  </Button>
                )}
                {rilievo.force_editable && (
                  <Button
                    variant="primary"
                    onClick={modalForceEditableActions.open}
                    className="pf-u-mr-md"
                  >
                    Annulla Forza Modifiche
                  </Button>
                )}
              </>
            )}
            <Button
              variant="secondary"
              onClick={modalLogsActions.open}
              className="pf-u-mr-md"
            >
              {logs?.length ?? ''} modifiche
            </Button>
            <Button
              isDisabled={
                rilievo
                  ? !!rilievo.motivazione_invalido || !rilievo.editable
                  : true
              }
              onClick={modalRmActions.open}
              variant="danger"
            >
              Invalida
            </Button>
          </>
        }
      />
      <ScrollableContent>
        {rilievo && rilievo.force_editable && (
          <Alert
            className="pf-u-mb-md"
            variant="warning"
            title={
              <span>
                <b>ATTENZIONE</b>
                <p>
                  Questo rilievo non dovrebbe essere modificabile, prosegui con
                  cautela.
                </p>
              </span>
            }
            aria-live="polite"
            isInline
          />
        )}
        {rilievo && !!rilievo.motivazione_invalido && (
          <Alert
            className="pf-u-mb-md"
            variant="danger"
            title={
              <span>
                Rilievo invalido:
                <br />
                {rilievo.motivazione_invalido}
              </span>
            }
            aria-live="polite"
            isInline
          />
        )}
        {rilievo && logs && (
          <>
            <RilievoDetailIntro
              editable={rilievo.editable}
              openModalIdentificativo={modalIdentificativoActions.open}
              openModalOperatore={modalOperatoreActions.open}
              rilievo={rilievo}
              openImage={modalImgActions.open}
            />
            {rilievo.tipo_rilievo === 'completo' &&
              rilievo.schema.map((scheda) => (
                <RilievoScheda
                  logs={logs}
                  editable={rilievo.editable}
                  openEdit={modalEditActions.open}
                  openImage={modalImgActions.open}
                  openMoveImage={modalMoveImageActions.open}
                  openRemoveImage={modalRemoveImageActions.open}
                  key={scheda.name}
                  scheda={scheda}
                  rilievo={rilievo}
                />
              ))}
            <Title headingLevel="h4" size="lg" className="pf-u-mb-md">
              Note rilievo
            </Title>
            <p>{rilievo.note}</p>
            {rilievo.tipo_rilievo === 'completo' && (
              <>
                <Title headingLevel="h4" size="lg">
                  Foto Aggiuntive
                </Title>
                <div className="pf-u-mt-md">
                  {range(1, N_FOTO_AGGIUNTIVE_RILIEVO + 1).map((i) => {
                    const foto = rilievo[`foto_extra_${i}`]
                    if (!foto) {
                      return null
                    }
                    return (
                      <img
                        key={i}
                        onClick={() =>
                          modalImgActions.open({
                            caption: `Foto aggiuntiva ${i}`,
                            src: foto,
                          })
                        }
                        className="thumbnail-rilievo cursor-pointer pf-u-mr-xl"
                        src={foto}
                        alt={`foto aggiuntiva ${i}`}
                      ></img>
                    )
                  })}
                </div>
              </>
            )}
            {rilievo.tipo_rilievo === 'completo' && (
              <Title headingLevel="h4" size="lg">
                Video
              </Title>
            )}
            {!rilievo.video_ok && (
              <Alert
                className="pf-u-mt-md"
                isInline
                variant="warning"
                title="Upload video incompleto"
              />
            )}
            {rilievo.videos.length > 0 && (
              <div className="pf-u-mt-md">
                {rilievo.videos.map((video, index) => (
                  <Grid
                    className={classNames('pf-u-p-sm', {
                      'pf-u-background-color-200': index % 2 === 0,
                    })}
                  >
                    <GridItem span={3}>
                      <Text>Video {index + 1}</Text>
                    </GridItem>
                    <GridItem span={9}>
                      <PlayIcon
                        className="cursor-pointer"
                        onClick={() => {
                          modalVideoActions.open({
                            caption: `Video ${index + 1}`,
                            src: video.media,
                          })
                        }}
                      />
                    </GridItem>
                  </Grid>
                ))}
              </div>
            )}
            {rilievo.tipo_rilievo === 'completo' && (
              <div>
                <Banner className="pf-u-mt-md" variant="info">
                  <Title headingLevel="h3" size="xl">
                    Note Amministratore
                  </Title>
                </Banner>
                <div
                  className="pf-u-p-sm"
                  style={{
                    border: '1px solid var(--pf-global--palette--blue-200)',
                  }}
                >
                  <RowNotaAdmin
                    rilievo={rilievo}
                    label="Segnalazioni varie"
                    fullLabel="Nota segnalazioni verie"
                    name="amministratore_note_anomalia_lieve"
                    openModal={modalNoteAdminActions.open}
                  />
                  <RowNotaAdmin
                    rilievo={rilievo}
                    label="Anomalia tecnica"
                    fullLabel="Nota anomalia tecnica"
                    name="amministratore_note_anomalia_tecnica"
                    openModal={modalNoteAdminActions.open}
                  />
                  <RowNotaAdmin
                    rilievo={rilievo}
                    label="Anomalia sosta"
                    fullLabel="Nota anomalia sosta"
                    name="amministratore_note_anomalia_sosta"
                    openModal={modalNoteAdminActions.open}
                  />
                  <RowNotaAdmin
                    rilievo={rilievo}
                    label="Non conformità"
                    fullLabel="Nota non conformità"
                    name="amministratore_note_non_conformita"
                    openModal={modalNoteAdminActions.open}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </ScrollableContent>
      {rilievo && (
        <Modal
          variant={ModalVariant.medium}
          title="Invalida rilievo"
          aria-label="invalida rilievo"
          hasNoBodyWrapper
          isOpen={modalRm.isOpen}
          onClose={modalRmActions.close}
        >
          <InvalidaFormBoxModal
            save={(data) => {
              return invalidaRilievo
                .onSuccess(() => {
                  navigate('/rilievi')
                })
                .asPromise(rilievo.id, data)
            }}
            onClose={modalRmActions.close}
          />
        </Modal>
      )}
      <Modal
        variant={ModalVariant.medium}
        title="Modifica Rilievo"
        aria-label="Modifica Rilievo"
        hasNoBodyWrapper
        isOpen={modalEdit.isOpen}
        onClose={modalEditActions.close}
      >
        <EditFieldBoxModal
          data={modalEdit.value}
          save={(type, data) => {
            if (type === 'tablequestion') {
              return modificaAmministratoreTableQuestion
                .onSuccess(() => {
                  modalEditActions.close()
                  fetchLogs(rilievo.id)
                })
                .asPromise(rilievo.id, data)
            } else {
              return modificaAmministratoreField
                .onSuccess(() => {
                  modalEditActions.close()
                  fetchLogs(rilievo.id)
                })
                .asPromise(rilievo.id, data)
            }
          }}
          onClose={modalEditActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Sposta Immagine"
        aria-label="Sposta Immagine"
        hasNoBodyWrapper
        isOpen={modalMoveImage.isOpen}
        onClose={modalMoveImageActions.close}
      >
        <MovaImageBoxModal
          save={(data) => {
            return spostaImmagineAmministratoreTableQuestion
              .onSuccess(() => {
                modalMoveImageActions.close()
                fetchLogs(rilievo.id)
              })
              .asPromise(rilievo.id, data)
          }}
          data={modalMoveImage.value}
          onClose={modalMoveImageActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Rimuovi Immagine"
        aria-label="Rimuovi Immagine"
        className="modal-actions-between"
        isOpen={modalRemoveImage.isOpen}
        onClose={modalRemoveImageActions.close}
        actions={[
          <Button
            key="close"
            onClick={modalRemoveImageActions.close}
            variant="link"
          >
            Chiudi
          </Button>,
          <Button
            key="remove"
            onClick={() => {
              const { field, nome_scheda, domanda } = modalRemoveImage.value
              return rimuoviImmagineAmministratoreTableQuestion
                .onSuccess(() => {
                  modalRemoveImageActions.close()
                  fetchLogs(rilievo.id)
                })
                .asPromise(rilievo.id, {
                  nome_scheda,
                  domanda: domanda,
                  nome_campo: field.name,
                })
            }}
            variant="danger"
          >
            Rimuovi
          </Button>,
        ]}
      >
        {modalRemoveImage.value && (
          <div>
            <h2>{modalRemoveImage.value.field.label}</h2>
            <br />
            <div>
              Rimuovere immagine <b>{modalRemoveImage.value.label}</b>
              {' ?'}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title={modalImg.value?.caption ?? ''}
        aria-label="immagine rilievo"
        className="modal-actions-end"
        isOpen={modalImg.isOpen}
        variant={ModalVariant.medium}
        actions={[
          <Button
            key="close"
            onClick={modalImgActions.close}
            variant="secondary"
          >
            Chiudi
          </Button>,
        ]}
        onClose={modalImgActions.close}
      >
        {modalImg.value && <img src={modalImg.value.src} alt="Immagine" />}
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Lista modifiche rilievo"
        aria-label="Lista modifiche rilievo"
        hasNoBodyWrapper
        isOpen={modalLogs.isOpen}
        onClose={modalLogsActions.close}
      >
        <LogsBoxModal logs={logs} onClose={modalLogsActions.close} />
      </Modal>
      <Modal
        title={modalVideo.value?.caption ?? ''}
        aria-label="video rilievo"
        className="modal-actions-end"
        isOpen={modalVideo.isOpen}
        variant={ModalVariant.medium}
        actions={[
          <Button onClick={modalVideoActions.close} variant="secondary">
            Chiudi
          </Button>,
        ]}
        onClose={modalVideoActions.close}
      >
        {modalVideo.value && (
          <video
            controls
            style={{ width: '100%' }}
            src={modalVideo.value.src}
          />
        )}
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Modifica Identificativo Mezzo"
        aria-label="Modifica Identificativo Mezzo"
        hasNoBodyWrapper
        isOpen={modalIdentificativo.isOpen}
        onClose={modalIdentificativoActions.close}
      >
        <IdentificativoMezzoFormBoxModal
          data={modalIdentificativo.value}
          save={(data) => {
            return modificaAmministratoreTesta
              .onSuccess(() => {
                modalIdentificativoActions.close()
                fetchLogs(rilievo.id)
              })
              .asPromise(rilievo.id, data)
          }}
          onClose={modalIdentificativoActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Modifica Operatore"
        aria-label="Modifica Operatore"
        hasNoBodyWrapper
        isOpen={modalOperatore.isOpen}
        onClose={modalOperatoreActions.close}
      >
        <OperatoreFormBoxModal
          data={modalOperatore.value}
          save={(data) => {
            return modificaAmministratoreTesta
              .onSuccess(() => {
                modalOperatoreActions.close()
                fetchLogs(rilievo.id)
              })
              .asPromise(rilievo.id, data)
          }}
          onClose={modalOperatoreActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Modifica Nota Amministratore"
        aria-label="Modifica Nota Amministratore"
        hasNoBodyWrapper
        isOpen={modalNoteAdmin.isOpen}
        onClose={modalNoteAdminActions.close}
      >
        <NotaAmministraotreFormBoxModal
          value={modalNoteAdmin.value?.value ?? ''}
          label={modalNoteAdmin.value?.label ?? ''}
          save={(values) => {
            return modificaAmministratoreTesta
              .onSuccess(() => {
                modalNoteAdminActions.close()
                fetchLogs(rilievo.id)
              })
              .asPromise(rilievo.id, {
                [modalNoteAdmin.value.name]: values.nota,
              })
          }}
          onClose={modalNoteAdminActions.close}
        />
      </Modal>
      <Modal
        title={
          rilievo?.force_editable
            ? 'Annulla Forza Modifiche'
            : 'Forza Modifiche'
        }
        aria-label="forza modifiche"
        className="modal-actions-end"
        isOpen={modalForceEditable.isOpen}
        variant={ModalVariant.medium}
        onClose={modalForceEditableActions.close}
        actions={[
          <Button
            key="close"
            onClick={modalForceEditableActions.close}
            variant="secondary"
          >
            Chiudi
          </Button>,
          <Button
            key="goon"
            onClick={() => {
              if (rilievo.force_editable) {
                modificaAmministratoreTesta
                  .onSuccess(() => {
                    modalForceEditableActions.close()
                  })
                  .run(rilievo.id, {
                    force_editable: false,
                  })
              } else {
                forceEditableAmministratore
                  .onSuccess(() => {
                    modalForceEditableActions.close()
                  })
                  .run(rilievo.id)
              }
            }}
            variant="primary"
          >
            Prosegui
          </Button>,
        ]}
      >
        {rilievo?.force_editable && (
          <p>
            Annulla la forzatura delle modifiche, il rilevio tornerà non
            modificabile.
          </p>
        )}
        {!rilievo?.force_editable && (
          <p>
            Forza le modifiche del rilievo.
            <br />
            <b>ATTENZIONE</b>
            <br />
            Rendendo il rilievo modificabile non sarà più garantita la
            congruenza tra il rilievo le segnlazioni ed i report.
            <br />
            Continua a tuo rischio e pericolo.
          </p>
        )}
      </Modal>
    </FullPage>
  )
}
