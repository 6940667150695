import React from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { InputField, transformErrorForForm, FileUploadField, SwitchField, TipiMezzoMultiSwitch } from '../Form'
import {
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
} from '@patternfly/react-core'

const INITIAL_VALUES = {
  user: {
    name: '',
    email: '',
  },
  firma: null,
  rilievi_rapidi_tipi_mezzo: [],
  rilievi_completi_tipi_mezzo: [],
}

const RilevatoreSchema = Yup.object().shape({
  firma: Yup.mixed().label('Firma'),
  user: Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
    name: Yup.string().required().label('Nome'),
  }),
})

export default function RilevatoreForm({ rilevatore, save, className }) {
  return (
    <Formik
      validationSchema={RilevatoreSchema}
      initialValues={rilevatore ?? INITIAL_VALUES}
      onSubmit={(values, actions) =>
        save(values)
          .then(() => {
            actions.setStatus({ success: true })
          })
          .catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
      }
    >
      {({ handleSubmit, isSubmitting, resetForm, status }) => (
        <Form onSubmit={handleSubmit} className={className} isHorizontal>
          {status?.success && (
            <FormAlert>
              <Alert
                variant="success"
                title={
                  rilevatore?.id
                    ? 'Rilevatore modificato con successo'
                    : 'Rilevatore creato con successo'
                }
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          <div className="pf-u-pt-m" />
          <Field
            isRequired
            label="Nome"
            name="user.name"
            component={InputField}
          />
          <Field
            isRequired
            label="Email"
            name="user.email"
            component={InputField}
          />
          <Field
            label="Firma"
            name="firma"
            component={FileUploadField}
            accept='image/png, image/jpeg'
          />
          {rilevatore?.id && (
            <Field
              label="Attivo?"
              name="user.is_active"
              component={SwitchField}
            />
          )}
          <Field
            label="Rilievi rapidi"
            name="rilievi_rapidi_tipi_mezzo"
            component={TipiMezzoMultiSwitch}
          />
          <Field
            label="Rilievi completi"
            name="rilievi_completi_tipi_mezzo"
            component={TipiMezzoMultiSwitch}
          />

          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between">
              <Button
                isDisabled={isSubmitting}
                variant="secondary"
                type="button"
                onClick={() => {
                  resetForm({ values: rilevatore ?? INITIAL_VALUES })
                }}
              >
                Annulla
              </Button>
              <Button isDisabled={isSubmitting} type="submit">
                {rilevatore?.id ? 'Modifica' : 'Aggiungi'}
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
