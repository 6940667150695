import React, { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import {
  FormGroup,
  TextInput,
  TextArea,
  FormSelect,
  Checkbox,
  FileUpload,
  DatePicker,
  Switch,
  Select,
  SelectVariant,
  SelectOption,
} from '@patternfly/react-core'
import { getIn } from 'formik'
import { useFetchVie, useVieList } from '../../hooks/vie'
import {
  useAmministratoriList,
  useFetchAmministratori,
} from '../../hooks/amministratore'
import { TIPI_MEZZO } from '../../consts'

export const InputField = ({
  field,
  label,
  form: { touched, errors },
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={field.name}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <TextInput
        validated={touch && error ? 'error' : undefined}
        id={field.name}
        {...props}
        {...field}
        onChange={(_, e) => field.onChange(e)}
        value={field.value ?? ''}
      />
    </FormGroup>
  )
}

export const TextAreaField = ({
  field,
  label,
  form: { touched, errors },
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={field.name}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <TextArea
        validated={touch && error ? 'error' : undefined}
        id={field.name}
        {...props}
        {...field}
        onChange={(_, e) => field.onChange(e)}
        value={field.value ?? ''}
      />
    </FormGroup>
  )
}

export const SelectField = ({
  field,
  label,
  form: { touched, errors },
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={field.name}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <FormSelect
        validated={touch && error ? 'error' : undefined}
        id={field.name}
        {...props}
        {...field}
        onChange={(_, e) => field.onChange(e)}
        value={field.value ?? ''}
      />
    </FormGroup>
  )
}

export const CheckBoxField = ({
  field,
  label,
  form: { touched, errors },
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value, ...passField } = field

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={field.name}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <Checkbox
        validated={touch && error ? 'error' : undefined}
        id={field.name}
        {...props}
        {...passField}
        isChecked={Boolean(value)}
        onChange={(_, e) => {
          field.onChange(e)
        }}
      />
    </FormGroup>
  )
}

export const SwitchField = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value, ...passField } = field

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={field.name}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <Switch
        id={field.name}
        {...props}
        {...passField}
        isChecked={Boolean(value)}
        onChange={(value) => {
          setFieldValue(field.name, value)
        }}
      />
    </FormGroup>
  )
}

function UploadImagePreview() {
  return null
}

export const FileUploadField = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  isRequired,
  accept,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value: rawValue, ...passField } = field
  const filename =
    rawValue instanceof File
      ? rawValue.name
      : // NOTE: At this point file point to uploaded url ES:. http://myhost/file.png
      typeof rawValue === 'string'
      ? rawValue
      : ''
  const value = rawValue instanceof File ? rawValue : ''

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={field.name}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <FileUpload
        browseButtonText="Carica"
        filenamePlaceholder="Trascina un file qui o carica"
        clearButtonText="Cancella"
        dropzoneProps={{
          accept,
        }}
        validated={touch && error ? 'error' : undefined}
        id={field.name}
        {...props}
        {...passField}
        value={value}
        filename={filename}
        onChange={(file) => {
          setFieldValue(field.name, file === '' ? null : file)
        }}
      >
        {value && <UploadImagePreview value={value} />}
      </FileUpload>
    </FormGroup>
  )
}

function dateFormatIT(date) {
  return dayjs(date).format('DD/MM/YYYY')
}

function dateParseIT(str) {
  const deDay = dayjs(str, 'DD/MM/YYYY')
  if (deDay.isValid()) {
    return deDay.toDate()
  }
  return false
}

export function transformDateFormatToUS(str) {
  return str.split('/').reverse().join('-')
}

export function transformDateFormatToIT(str) {
  return str.split('-').reverse().join('/')
}

export const DatePickerField = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  className = '',
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value, ...passField } = field

  return (
    <FormGroup
      className={`form-group-max-width ${className}`}
      label={label}
      fieldId={`date-picker-${field.name}`}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <DatePicker
        invalidFormatText="Inserire una data valida"
        dateFormat={dateFormatIT}
        dateParse={dateParseIT}
        placeholder="DD/MM/YYYY"
        validated={touch && error ? 'error' : undefined}
        id={`date-picker-${field.name}`}
        inputProps={{ name: field.name }}
        {...props}
        {...passField}
        value={transformDateFormatToIT(value ?? '')}
        onChange={(str) => {
          setFieldValue(field.name, transformDateFormatToUS(str))
        }}
      />
    </FormGroup>
  )
}

export function DatePickerIta(props) {
  const { value } = props
  return (
    <DatePicker
      invalidFormatText="Inserire una data valida"
      dateFormat={dateFormatIT}
      dateParse={dateParseIT}
      placeholder="DD/MM/YYYY"
      {...props}
      value={transformDateFormatToIT(value ?? '')}
    />
  )
}

function makeViaOption(via) {
  return {
    id: via.id,
    toString: function () {
      return `${via.nome}`
    },
    compareTo: function (value) {
      return this.toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase())
    },
  }
}

export const MultiViaAutocomplete = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value } = field

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const [{ list, loading }, { run }] = useVieList(null)
  useEffect(() => run({ search: '' }), [run])
  const options = useMemo(() => (list ? list.map(makeViaOption) : []), [list])

  const [selection, setSelection] = useState([])
  const fetchVie = useFetchVie()
  useEffect(() => {
    const deValue = Array.isArray(value) ? [...value] : []
    if (
      deValue.sort().join(',') !==
      selection
        .map((v) => v.id)
        .sort()
        .join(',')
    ) {
      if (value.length === 0) {
        setSelection([])
        return
      }
      let canceled = false
      fetchVie({
        id: value,
      })
        .toPromise()
        .then((data) => {
          if (!canceled) {
            setSelection(data.results.map(makeViaOption))
          }
        })
      return () => {
        canceled = true
      }
    }
  }, [fetchVie, selection, value])

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={`vie-autocomplete-${field.name}`}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <Select
        validated={touch && error ? 'error' : undefined}
        variant={SelectVariant.typeaheadMulti}
        typeAheadAriaLabel="Cerca via"
        id={`vie-autocomplete-${field.name}`}
        onToggle={toggle}
        isOpen={isOpen}
        loadingVariant={loading ? 'spinner' : undefined}
        noResultsFoundText="Nessun risultato trovato"
        onTypeaheadInputChanged={(input) => {
          run.withMeta({ debounced: true }).run({ search: input })
        }}
        onSelect={(_, value) => {
          let nextSelection
          if (find(selection, { id: value.id })) {
            nextSelection = selection.filter((v) => v.id !== value.id)
          } else {
            nextSelection = selection.concat(value)
          }
          setSelection(nextSelection)
          setFieldValue(
            field.name,
            nextSelection.map((v) => v.id)
          )
        }}
        selections={selection}
        aria-labelledby={'cerca-via'}
        placeholderText="Cerca via"
        isCreatable={false}
        {...props}
      >
        {options.map((v) => (
          <SelectOption key={v.id} value={v} />
        ))}
      </Select>
    </FormGroup>
  )
}

function makeAmministratoreOption(admin) {
  return {
    id: admin.id,
    toString: function () {
      return `${admin.user.name}`
    },
    compareTo: function (value) {
      return this.toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase())
    },
  }
}

export const MultiAmministratoriAutocomplete = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value } = field
  console.log('O.o', value)

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const [{ data, pending }, { run }] = useAmministratoriList(null)
  useEffect(() => run({ search: '' }), [run])
  const options = useMemo(
    () => (data ? data.list.map(makeAmministratoreOption) : []),
    [data]
  )

  const [selection, setSelection] = useState([])
  const fetchAdmins = useFetchAmministratori()
  useEffect(() => {
    const deValue = Array.isArray(value) ? [...value] : []
    if (
      deValue.sort().join(',') !==
      selection
        .map((v) => v.id)
        .sort()
        .join(',')
    ) {
      if (value.length === 0) {
        setSelection([])
        return
      }
      let canceled = false
      fetchAdmins({
        id: value,
      })
        .toPromise()
        .then((data) => {
          if (!canceled) {
            setSelection(data.results.map(makeAmministratoreOption))
          }
        })
      return () => {
        canceled = true
      }
    }
  }, [fetchAdmins, selection, value])

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={`amministratori-autocomplete-${field.name}`}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <Select
        validated={touch && error ? 'error' : undefined}
        variant={SelectVariant.typeaheadMulti}
        typeAheadAriaLabel="Cerca amministratore"
        id={`vie-autocomplete-${field.name}`}
        onToggle={toggle}
        isOpen={isOpen}
        loadingVariant={pending ? 'spinner' : undefined}
        noResultsFoundText="Nessun risultato trovato"
        onTypeaheadInputChanged={(input) => {
          run.withMeta({ debounced: true }).run({ search: input })
        }}
        onSelect={(_, value) => {
          let nextSelection
          if (find(selection, { id: value.id })) {
            nextSelection = selection.filter((v) => v.id !== value.id)
          } else {
            nextSelection = selection.concat(value)
          }
          setSelection(nextSelection)
          setFieldValue(
            field.name,
            nextSelection.map((v) => v.id)
          )
        }}
        selections={selection}
        aria-labelledby={'cerca-amministratore'}
        placeholderText="Cerca amministratore"
        isCreatable={false}
        {...props}
      >
        {options.map((v) => (
          <SelectOption key={v.id} value={v} />
        ))}
      </Select>
    </FormGroup>
  )
}

export const TipiMezzoMultiSwitch = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value } = field

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={field.name}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <div id={field.anme} className="pf-u-mt-md">
        {Object.keys(TIPI_MEZZO).map((tipoMezzo) => (
          <div className={'pf-u-p-xs'} key={tipoMezzo}>
            <Switch
              label={TIPI_MEZZO[tipoMezzo]}
              isChecked={value.includes(tipoMezzo)}
              onChange={() => {
                if (value.includes(tipoMezzo)) {
                  setFieldValue(
                    field.name,
                    value.filter((v) => v !== tipoMezzo)
                  )
                } else {
                  setFieldValue(field.name, value.concat(tipoMezzo))
                }
              }}
            />
          </div>
        ))}
      </div>
    </FormGroup>
  )
}

export const TipiMezzoOperatoreMultiSwitch = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  isRequired,
  operatoriTipiMezzo,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value } = field

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={field.name}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <div id={field.anme} className="pf-u-mt-md">
        {operatoriTipiMezzo.map((op) => (
          <div className={'pf-u-p-xs'} key={op.id}>
            <Switch
              label={TIPI_MEZZO[op.tipo_mezzo]}
              isChecked={value.includes(op.id)}
              onChange={() => {
                if (value.includes(op.id)) {
                  setFieldValue(
                    field.name,
                    value.filter((v) => v !== op.id)
                  )
                } else {
                  setFieldValue(field.name, value.concat(op.id))
                }
              }}
            />
          </div>
        ))}
      </div>
    </FormGroup>
  )
}

function makeSimpleOptions(option) {
  return {
    value: option.value,
    toString: function () {
      return `${option.label}`
    },
    compareTo: function (value) {
      return this.toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase())
    },
  }
}

export const SimpleMultiAutocompleteField = ({
  field,
  label,
  form: { touched, errors, setFieldValue },
  options,
  isRequired,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const deOptions = useMemo(() => options.map(makeSimpleOptions), [options])
  const { value } = field

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const selections = useMemo(() => {
    const optionsByValue = keyBy(deOptions, 'value')
    return value.map((v) => optionsByValue[v]).filter(Boolean)
  }, [deOptions, value])

  return (
    <FormGroup
      className="form-group-max-width"
      label={label}
      fieldId={`multi-select-${field.name}`}
      isRequired={isRequired}
      helperTextInvalid={touch && error ? error : undefined}
      validated={touch && error ? 'error' : undefined}
    >
      <Select
        validated={touch && error ? 'error' : undefined}
        variant={SelectVariant.typeaheadMulti}
        typeAheadAriaLabel="Cerca via"
        id={`multi-select-${field.name}`}
        onToggle={toggle}
        isOpen={isOpen}
        noResultsFoundText="Nessun risultato trovato"
        onSelect={(_, o) => {
          let nextSelection
          if (value.includes(o.value)) {
            nextSelection = value.filter((v) => v !== o.value)
          } else {
            nextSelection = value.concat(o.value)
          }
          setFieldValue(field.name, nextSelection)
        }}
        selections={selections}
        aria-labelledby={'cerca'}
        placeholderText="Cerca"
        isCreatable={false}
        {...props}
      >
        {deOptions.map((v) => (
          <SelectOption key={v.value} value={v} />
        ))}
      </Select>
    </FormGroup>
  )
}
