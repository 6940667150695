import {
  Button,
  Form,
  FormSelectOption,
  ModalBoxBody,
  ModalBoxFooter,
} from '@patternfly/react-core'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { SelectField, transformErrorForForm } from '../../components/Form'

const AttachTipoMezzoSchema = Yup.object().shape({
  tipo_mezzo: Yup.string().required().label('Tipo Mezzo'),
})

const INITIAL_DATA = {
  tipo_mezzo: '',
}

export default function AttachTipoMezzoBoxModal({
  onClose,
  save,
  tipoMezzoOptions,
}) {
  return (
    <Formik
      validationSchema={AttachTipoMezzoSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={INITIAL_DATA}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody className="pf-c-form">
            <Field label="Tipo Mezzo" name="tipo_mezzo" component={SelectField}>
              <FormSelectOption
                label={'-- Seleziona Tipo Mezzo --'}
                value={''}
              />
              {tipoMezzoOptions.map((o) => (
                <FormSelectOption
                  label={o.label}
                  value={o.value}
                  key={o.value}
                />
              ))}
            </Field>
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}
