import { useState } from 'react'
import * as Yup from 'yup'
import { circleMarker } from 'leaflet'
import { MapContainer, TileLayer, GeoJSON, Popup } from 'react-leaflet'
import FullPage from '../../components/FullPage'
import { useAreeSostaGeo } from '../../hooks/areaSosta'
import SectionHeader from '../../components/SectionHeader'
import {
  Breadcrumb,
  Button,
  Modal,
  Form,
  FormAlert,
  Alert,
  ModalBoxFooter,
  ModalBoxBody,
  ModalVariant,
  Title,
  Switch,
  Text,
} from '@patternfly/react-core'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Formik, Field } from 'formik'
import { FileUploadField, transformErrorForForm, getNonFieldsErrorMessageAsString } from '../../components/Form'
import dayjs from 'dayjs'
import { useAreePedonaliGeo } from '../../hooks/areaPedonale'
import { useStazioniBikeGeo } from '../../hooks/stazioniBike'

const ImportValidationSchema = Yup.object().shape({
  zip: Yup.mixed().required().label('File Zip'),
})

function ImportFormBoxModal({ onClose, save }) {
  return (
    <Formik
      validationSchema={ImportValidationSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{ zip: null }}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody>
            <Field
              isRequired
              label={'Zip'}
              name="zip"
              accept={'application/zip'}
              component={FileUploadField}
            />
          </ModalBoxBody>
          {errors.__noFieldsServerError && (
            <FormAlert>
              <Alert
                variant="danger"
                title={'Problemi di caricamento file'}
                aria-live="polite"
                isInline
              >
                <div>{errors.__noFieldsServerError && getNonFieldsErrorMessageAsString(errors.__noFieldsServerError)}</div>
              </Alert>
            </FormAlert>
          )}

          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button
              variant="secondary"
              onClick={onClose}
              isDisabled={isSubmitting}
            >
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Importa
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

export default function MapPage() {
  const [{ data: areeSosta }, { importAreeSosta }] = useAreeSostaGeo()
  const [{ data: areePedonali }, { importAreePedonali }] = useAreePedonaliGeo()
  const [{ data: stazioniBike }, { importStazioniBike }] = useStazioniBikeGeo()

  const [currentFeature, setCurrentFeature] = useState(null)
  const [showAreeSosta, setShowAreeSosta] = useState(true)
  const [showAreePedonali, setShowAreePedonali] = useState(true)
  const [showStazioniBike, setShowStazioniBike] = useState(true)

  const [modalAreePedonali, modalAreePedonaliActions] = useModalTrigger()
  const [modalAreeSosta, modalAreeSostaActions] = useModalTrigger()
  const [modalStazioniBike, modalStazioniBikeActions] = useModalTrigger()

  return (
    <FullPage>
      <SectionHeader
        title="Gestione mappa"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/map">Gestione mappa</BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <div className="pf-u-display-flex pf-u-mb-2xl">
        <div className="pf-u-w-50">
          <div className="pf-u-display-flex pf-u-align-items-center">
            <Title headingLevel="h5">Aree di sosta</Title>
            <Switch
              aria-label="show-aree-sosta"
              className="pf-u-ml-md"
              isChecked={showAreeSosta}
              onChange={setShowAreeSosta}
            />
          </div>
          <Text className="pf-u-my-md">
            {areeSosta?.import_timestamp ? (
              dayjs(areeSosta.import_timestamp).format('DD/MM/YYYY')
            ) : (
              <>&nbsp;</>
            )}
          </Text>
          <Button
            variant="secondary"
            onClick={() => modalAreeSostaActions.open()}
          >
            Aggiorna
          </Button>
        </div>
        <div className="pf-u-w-50">
          <div className="pf-u-display-flex pf-u-align-items-center">
            <Title headingLevel="h5">Aree pedonali</Title>
            <Switch
              aria-label="show-aree-pedonali"
              className="pf-u-ml-md"
              isChecked={showAreePedonali}
              onChange={setShowAreePedonali}
            />
          </div>
          <Text className="pf-u-my-md">
            {areePedonali?.import_timestamp ? (
              dayjs(areePedonali.import_timestamp).format('DD/MM/YYYY')
            ) : (
              <>&nbsp;</>
            )}
          </Text>
          <Button
            variant="secondary"
            onClick={() => modalAreePedonaliActions.open()}
          >
            Aggiorna
          </Button>
        </div>
        <div className="pf-u-w-50">
          <div className="pf-u-display-flex pf-u-align-items-center">
            <Title headingLevel="h5">Stazioni Bike</Title>
            <Switch
              aria-label="show-stazioni-bike"
              className="pf-u-ml-md"
              isChecked={showStazioniBike}
              onChange={setShowStazioniBike}
            />
          </div>
          <Text className="pf-u-my-md">
            {stazioniBike?.import_timestamp ? (
              dayjs(stazioniBike.import_timestamp).format('DD/MM/YYYY')
            ) : (
              <>&nbsp;</>
            )}
          </Text>
          <Button
            variant="secondary"
            onClick={() => modalStazioniBikeActions.open()}
          >
            Aggiorna
          </Button>
        </div>
      </div>

      <MapContainer
        style={{ height: '100%' }}
        center={[45.464664, 9.18854]}
        zoom={13}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {areeSosta && showAreeSosta && (
          <GeoJSON
            style={{
              color: 'red',
            }}
            key={areeSosta.import_timestamp ?? 'never'}
            data={areeSosta}
            eventHandlers={{
              click: (evt) => {
                setCurrentFeature(evt.sourceTarget.feature)
              },
            }}
          >
            <Popup>
              <div>{currentFeature?.properties.nome}</div>
              {currentFeature?.properties?.tipo_area && (
                <div>{currentFeature.properties.tipo_area}</div>
              )}
            </Popup>
          </GeoJSON>
        )}
        {areePedonali && showAreePedonali && (
          <GeoJSON
            key={areePedonali.import_timestamp ?? 'never'}
            data={areePedonali}
            eventHandlers={{
              click: (evt) => {
                setCurrentFeature(evt.sourceTarget.feature)
              },
            }}
          >
            <Popup>
              <p>
                {currentFeature?.properties.toponimo}{' '}
                {currentFeature?.properties.nome}
              </p>
            </Popup>
          </GeoJSON>
        )}
        {stazioniBike && showStazioniBike && (
          <GeoJSON
            key={stazioniBike.import_timestamp ?? 'never'}
            pointToLayer={(point, latlng) => {
              return circleMarker(latlng, {
                radius: 4,
                color: 'darkorange',
              })
            }}
            data={stazioniBike}
            eventHandlers={{
              click: (evt) => {
                setCurrentFeature(evt.sourceTarget.feature)
              },
            }}
          >
            <Popup>
              <p>{currentFeature?.properties.nome}</p>
            </Popup>
          </GeoJSON>
        )}
      </MapContainer>
      <Modal
        variant={ModalVariant.medium}
        title="Importa aree pedonali"
        aria-label="importa aree pedonali"
        hasNoBodyWrapper
        isOpen={modalAreePedonali.isOpen}
        onClose={modalAreePedonaliActions.close}
      >
        <ImportFormBoxModal
          save={(data) =>
            importAreePedonali
              .onSuccess(() => {
                modalAreePedonaliActions.close()
              })
              .asPromise(data)
          }
          onClose={modalAreePedonaliActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Importa aree sosta"
        aria-label="importa aree sosta"
        hasNoBodyWrapper
        isOpen={modalAreeSosta.isOpen}
        onClose={modalAreeSostaActions.close}
      >
        <ImportFormBoxModal
          save={(data) =>
            importAreeSosta
              .onSuccess(() => {
                modalAreeSostaActions.close()
              })
              .asPromise(data)
          }
          onClose={modalAreeSostaActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Importa stazioni bike"
        aria-label="importa stazioni bike"
        hasNoBodyWrapper
        isOpen={modalStazioniBike.isOpen}
        onClose={modalStazioniBikeActions.close}
      >
        <ImportFormBoxModal
          save={(data) =>
            importStazioniBike
              .onSuccess(() => {
                modalStazioniBikeActions.close()
              })
              .asPromise(data)
          }
          onClose={modalStazioniBikeActions.close}
        />
      </Modal>
    </FullPage>
  )
}
