import React from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { transformErrorForForm, FileUploadField, SwitchField } from './Form'
import {
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
} from '@patternfly/react-core'

const ProfileSchema = Yup.object().shape({
  firma: Yup.mixed().required().label('Firma'),
})

export default function ProfileForm({ profile, save, className, isAdmin }) {
  return (
    <Formik
      validationSchema={ProfileSchema}
      initialValues={profile}
      onSubmit={(values, actions) =>
        save(values)
          .then(() => {
            actions.setStatus({ success: true })
          })
          .catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
      }
    >
      {({ handleSubmit, isSubmitting, resetForm, status }) => (
        <Form onSubmit={handleSubmit} className={className} isHorizontal>
          {status?.success && (
            <FormAlert>
              <Alert
                variant="success"
                title={'Profilo modificato con successo'}
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          <div className="pf-u-pt-m" />
          <Field
            label="Firma"
            name="firma"
            component={FileUploadField}
            accept="image/png, image/jpeg"
          />
          {isAdmin && (
            <Field
              label="Riceve mail segnalazione?"
              name="riceve_mail_segnalazione"
              aria-label="Riceve mail segnalazione?"
              component={SwitchField}
            />
          )}
          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between">
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => {
                  resetForm({ values: profile })
                }}
              >
                Annulla
              </Button>
              <Button isDisabled={isSubmitting} type="submit">
                Modifica
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
