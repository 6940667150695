import React, { useEffect, useMemo, useRef } from 'react'
import * as Yup from 'yup'
import { Field, Formik, useFormikContext } from 'formik'
import {
  InputField,
  transformErrorForForm,
  SwitchField,
  SelectField,
  TipiMezzoOperatoreMultiSwitch,
} from '../Form'
import {
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
  FormSelectOption,
} from '@patternfly/react-core'
import uniqBy from 'lodash/uniqBy'
import { useOperatoriTipiMezzoFlat } from '../../hooks/operatori'

const INITIAL_VALUES = {
  user: {
    name: '',
    email: '',
  },
  operatore: '',
  operatori_tipi_mezzi: [],
}

const AccountOperatoreSchema = Yup.object().shape({
  operatore: Yup.string().required().label('Operatore'),
  operatori_tipi_mezzi: Yup.array()
    .of(Yup.string())
    .required()
    .label('Tipi Mezzo'),
  user: Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
    name: Yup.string().required().label('Nome'),
  }),
})

function AccountOperatoreFields({ account }) {
  const [{ data: operatoriTipiMezzo }] = useOperatoriTipiMezzoFlat()
  const { values, setFieldValue } = useFormikContext()

  const operatoriOptions = useMemo(() => {
    if (!operatoriTipiMezzo) {
      return []
    }
    return uniqBy(
      operatoriTipiMezzo.map((ot) => ({
        label: ot.operatore.nome,
        value: ot.operatore.id,
      })),
      'value'
    )
  }, [operatoriTipiMezzo])

  const operatoriTipiMezzoSelected = useMemo(() => {
    if (!operatoriTipiMezzo || !values.operatore) {
      return []
    }
    return operatoriTipiMezzo.filter(
      (o) => o.operatore.id === +values.operatore
    )
  }, [operatoriTipiMezzo, values.operatore])

  const prevOperatore = useRef(values.operatore)
  useEffect(() => {
    if (values.operatore !== prevOperatore.current) {
      prevOperatore.current = values.operatore
      setFieldValue(
        'operatori_tipi_mezzi',
        operatoriTipiMezzoSelected.map((o) => o.id)
      )
    }
  }, [operatoriTipiMezzoSelected, setFieldValue, values.operatore])

  return (
    <>
      <Field isRequired label="Nome" name="user.name" component={InputField} />
      <Field
        isRequired
        label="Email"
        name="user.email"
        component={InputField}
      />
      {account?.id && (
        <Field label="Attivo?" name="user.is_active" component={SwitchField} />
      )}
      <Field
        isRequired
        label="Operatore"
        name="operatore"
        component={SelectField}
      >
        <FormSelectOption label={'-- Seleziona operatore --'} value={''} />
        {operatoriOptions.map((o) => (
          <FormSelectOption label={o.label} value={o.value} key={o.value} />
        ))}
      </Field>
      <Field
        isRequired
        label="Tipi Mezzo"
        name="operatori_tipi_mezzi"
        operatoriTipiMezzo={operatoriTipiMezzoSelected}
        component={TipiMezzoOperatoreMultiSwitch}
      />
    </>
  )
}

export default function AccountOperatoreForm({ account, save, className }) {
  return (
    <Formik
      validationSchema={AccountOperatoreSchema}
      initialValues={account ?? INITIAL_VALUES}
      onSubmit={(values, actions) =>
        save(values)
          .then(() => {
            actions.setStatus({ success: true })
          })
          .catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
      }
    >
      {({ handleSubmit, isSubmitting, resetForm, status }) => (
        <Form onSubmit={handleSubmit} className={className} isHorizontal>
          {status?.success && (
            <FormAlert>
              <Alert
                variant="success"
                title={
                  account?.id
                    ? 'Account operatore modificato con successo'
                    : 'Account operatore creato con successo'
                }
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          <div className="pf-u-pt-m" />
          <AccountOperatoreFields account={account} />
          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between">
              <Button
                isDisabled={isSubmitting}
                variant="secondary"
                type="button"
                onClick={() => {
                  resetForm({ values: account ?? INITIAL_VALUES })
                }}
              >
                Annulla
              </Button>
              <Button isDisabled={isSubmitting} type="submit">
                {account?.id ? 'Modifica' : 'Aggiungi'}
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
