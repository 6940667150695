import {
  Pagination,
  Progress,
  ProgressMeasureLocation,
  Accordion,
  AccordionItem,
  AccordionToggle,
  AccordionContent,
  Button,
  Modal,
  ModalVariant,
  ModalBoxFooter,
  ModalBoxBody,
  FileUpload,
  Alert,
  Switch,
  Badge,
} from '@patternfly/react-core'
import {
  TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import dayjs from 'dayjs'
import sortBy from 'lodash/sortBy'
import { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  StopCircleIcon,
  CheckCircleIcon,
  PlusCircleIcon,
  Remove2Icon,
} from '@patternfly/react-icons'
import { useSearchParams } from 'react-router-dom'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import FullPage from '../../components/FullPage'
import ScrollableContent from '../../components/ScrollableContent'
import SectionHeader from '../../components/SectionHeader'
import { PAGE_SIZE, TIPI_MEZZO } from '../../consts'
import { useRilieviVideoList } from '../../hooks/rilievo'
import { useRilieviVideoUploaderContext } from '../../hooks/video'
import { useForceAuthRefresh } from '../../hooks/forceAuthRefresh'

function UploadModalBox({ onClose, upload }) {
  const [file, setFile] = useState(null)
  return (
    <>
      <ModalBoxBody>
        <FileUpload
          browseButtonText="Carica"
          filenamePlaceholder="Trascina un file qui o carica"
          clearButtonText="Cancella"
          value={file}
          onChange={setFile}
          filename={file?.name}
          dropzoneProps={{
            accept:
              'video/*,video/mp4,video/x-m4v,video/webm,video/x-matroska,.mkv,.avi',
          }}
        />
      </ModalBoxBody>
      <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
        <Button variant="link" onClick={onClose}>
          Annulla
        </Button>
        <Button
          type="submit"
          variant="primary"
          isDisabled={file === null}
          onClick={() => upload(file)}
        >
          Conferma
        </Button>
      </ModalBoxFooter>
    </>
  )
}

function UploadProgress({ upload, stop, rilievo }) {
  let progress = (100 * upload.loaded) / upload.total
  progress = isNaN(progress) ? 0 : parseInt(progress)
  return (
    <div>
      <Progress
        title={
          <div className="pf-u-display-flex pf-u-justify-content-space-between pf-u-w-100">
            {upload.fileName}{' '}
            <Button
              variant="danger"
              className="pf-u-ml-md"
              isSmall
              onClick={() => stop(rilievo.id, upload.id)}
            >
              <StopCircleIcon />
            </Button>
          </div>
        }
        value={progress}
        measureLocation={ProgressMeasureLocation.inside}
      />
      {upload.error && (
        <Alert
          className="pf-u-mt-xs"
          isInline
          title="Errore durante l'upload"
          variant="danger"
        />
      )}
    </div>
  )
}

function RilievoVideos({ rilievo, upload, onRemove }) {
  const [open, setOpen] = useState(false)
  return (
    <Accordion asDefinitionList>
      <AccordionItem>
        <AccordionToggle
          onClick={() => {
            setOpen(!open)
          }}
          isExpanded={open}
        >
          {rilievo.videos.length} Video
          <Button
            isSmall
            className="pf-u-ml-md"
            onClick={(e) => {
              e.stopPropagation()
              upload(rilievo.id)
            }}
          >
            <PlusCircleIcon />
          </Button>
        </AccordionToggle>
      </AccordionItem>
      <AccordionContent isHidden={!open}>
        {rilievo.videos.map((video) => (
          <RilievoVideo key={video.id} video={video} onRemove={onRemove} />
        ))}
      </AccordionContent>
    </Accordion>
  )
}

function RilievoVideo({ video, onRemove }) {
  return (
    <div className="pf-u-display-flex pf-u-align-items-center pf-u-mb-xs">
      <video controls style={{ height: 200 }} src={video.media} />
      <Button
        className="pf-u-ml-xl"
        isSmall
        variant="danger"
        onClick={() => onRemove(video.id)}
      >
        <Remove2Icon />
      </Button>
    </div>
  )
}

const RilieviRows = memo(
  ({
    rilievo,
    upload,
    rilieviUploadsMap,
    onConfirm,
    stop,
    onRemove,
    autoVideoOk,
    toggleAutoVideoOk,
  }) => {
    const uploads = sortBy(
      Object.keys(rilieviUploadsMap ?? {}).map((id) => ({
        id: +id,
        ...rilieviUploadsMap[id],
      })),
      'id'
    )
    const numUploads = Object.keys(rilieviUploadsMap ?? {}).filter(
      (k) => rilieviUploadsMap[k].error === null
    ).length
    return (
      <Fragment key={rilievo.id}>
        <Tr>
          <Td>{rilievo.id}</Td>
          <Td>{rilievo.via_data?.nome ?? ''}</Td>
          <Td>{TIPI_MEZZO[rilievo.tipo_mezzo]}</Td>
          <Td>{rilievo.operatore_data?.nome ?? ''}</Td>
          <Td>{rilievo.identificativo_mezzo}</Td>
          <Td>
            {rilievo.dataora_inizio
              ? dayjs(rilievo.dataora_inizio).format('DD/MM/YYYY HH:mm')
              : null}
          </Td>
          <Td style={{ width: 250 }}>
            {numUploads === 0 ? (
              <div style={{ textAlign: 'right' }}>
                <Button
                  isSmall
                  variant="tertiary"
                  onClick={() => onConfirm(rilievo)}
                >
                  <CheckCircleIcon />
                </Button>
              </div>
            ) : (
              <Switch
                id="reversed-switch"
                label={`Chiudi dopo upload di ${numUploads} video`}
                isChecked={autoVideoOk}
                onChange={() => toggleAutoVideoOk(rilievo.id)}
                isReversed
              />
            )}
          </Td>
        </Tr>
        <Tr>
          <Td colSpan={7} className="pf-u-p-0">
            <RilievoVideos
              upload={upload}
              rilievo={rilievo}
              onRemove={(id) => onRemove({ id: rilievo.id, videoId: id })}
            />
          </Td>
        </Tr>
        {uploads.map((upload) => (
          <Tr key={upload.id}>
            <Td colSpan={7}>
              <UploadProgress rilievo={rilievo} upload={upload} stop={stop} />
            </Td>
          </Tr>
        ))}
      </Fragment>
    )
  }
)

export default function Videos() {
  useForceAuthRefresh()
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = useMemoCompare({
    page: searchParams.get('page') ?? 1,
  })
  const [
    { list, pagination },
    { addVideo, videoOk, removeVideo, run: refresh },
  ] = useRilieviVideoList(filters)

  const [uploadState, { upload, stop }, uploadObservable] =
    useRilieviVideoUploaderContext()

  const [mapAutoVideoOk, setMapAutoVideoOk] = useState({})

  const toggleAutoVideoOk = useCallback((id) => {
    setMapAutoVideoOk((mmap) => ({
      ...mmap,
      [id]: mmap[id] ? false : true,
    }))
  }, [])

  function autoVideoOk(video) {
    if (
      uploadState[video.rilievo] === undefined &&
      mapAutoVideoOk[video.rilievo]
    ) {
      videoOk
        .onSuccess(() => {
          refresh(filters)
        })
        .run(video.rilievo)
    }
  }
  const autoVideoOkCbRef = useRef(autoVideoOk)
  useEffect(() => {
    autoVideoOkCbRef.current = autoVideoOk
  })

  useEffect(() => {
    const sub = uploadObservable.subscribe((video) => {
      addVideo(video)
      const lastAutoVideoOk = autoVideoOkCbRef.current
      if (lastAutoVideoOk) {
        lastAutoVideoOk(video)
      }
    })
    return () => {
      sub.unsubscribe()
    }
  }, [addVideo, uploadObservable])

  const [modalUpload, modalUploadActions] = useModalTrigger()
  const [modalConfrim, modalConfirmActions] = useModalTrigger()
  const [modalRemove, modalRemoveActions] = useModalTrigger()

  return (
    <FullPage>
      <SectionHeader
        bottomPadded
        title="Carica video"
        subTitle={pagination ? `${pagination.count} rilievi totali` : null}
      />
      <ScrollableContent>
        <TableComposable isStickyHeader>
          <Thead>
            <Tr>
              <Th>Rilievo</Th>
              <Th>Via</Th>
              <Th>Mezzo</Th>
              <Th>Operatore</Th>
              <Th>Identificativo</Th>
              <Th>Data e ora</Th>
              <Th>Azioni</Th>
            </Tr>
          </Thead>
          <Tbody>
            {list &&
              list.map((rilievo) => (
                <RilieviRows
                  toggleAutoVideoOk={toggleAutoVideoOk}
                  autoVideoOk={mapAutoVideoOk[rilievo.id] ?? false}
                  onRemove={modalRemoveActions.open}
                  rilievo={rilievo}
                  rilieviUploadsMap={uploadState[rilievo.id]}
                  key={rilievo.id}
                  upload={modalUploadActions.open}
                  stop={stop}
                  onConfirm={modalConfirmActions.open}
                />
              ))}
          </Tbody>
        </TableComposable>
        {pagination && (
          <Pagination
            variant="bottom"
            isSticky
            onSetPage={(_, page) => {
              setSearchParams({ ...filters, page })
            }}
            page={+(searchParams.get('page') ?? 1)}
            perPageOptions={[{ title: PAGE_SIZE, value: PAGE_SIZE }]}
            itemCount={pagination.count}
            perPage={PAGE_SIZE}
          />
        )}
      </ScrollableContent>
      <Modal
        variant={ModalVariant.medium}
        title="Carica video"
        aria-label="upload"
        hasNoBodyWrapper
        isOpen={modalUpload.isOpen}
        onClose={modalUploadActions.close}
      >
        <UploadModalBox
          upload={(file) => {
            upload(modalUpload.value, file)
            modalUploadActions.close()
          }}
          onClose={modalUploadActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title={
          modalConfrim.value ? (
            <span className="pf-u-display-flex pf-u-align-items-center">
              Concludi caricamento{' '}
              <Badge className="pf-u-p-xs pf-u-ml-xs">
                {modalConfrim.value.videos.length} video
              </Badge>
            </span>
          ) : (
            'Concludi caricamento video'
          )
        }
        aria-label="confirm"
        isOpen={modalConfrim.isOpen}
        onClose={modalConfirmActions.close}
        footer={
          <div className="pf-u-display-flex pf-u-justify-content-space-between pf-u-w-100">
            <Button variant="link" onClick={modalConfirmActions.close}>
              Annulla
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => {
                videoOk
                  .onSuccess(() => {
                    refresh(filters)
                    modalConfirmActions.close()
                  })
                  .run(modalConfrim.value.id)
              }}
            >
              Conferma
            </Button>
          </div>
        }
      >
        Conferma la conclusione del caricamento dei video?
        <br />
        Una volta confermata non sarà più possibile caricare altri video.
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Rimozione video"
        aria-label="remove"
        isOpen={modalRemove.isOpen}
        onClose={modalRemoveActions.close}
        footer={
          <div className="pf-u-display-flex pf-u-justify-content-space-between pf-u-w-100">
            <Button variant="link" onClick={modalRemoveActions.close}>
              Annulla
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => {
                const { id, videoId } = modalRemove.value
                removeVideo
                  .onSuccess(() => {
                    modalRemoveActions.close()
                  })
                  .run(id, videoId)
              }}
            >
              Conferma
            </Button>
          </div>
        }
      >
        Sei sicuro di voler rimuovere il video?
      </Modal>
    </FullPage>
  )
}
