import { Breadcrumb, Pagination } from '@patternfly/react-core'
import {
  TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import dayjs from 'dayjs'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import FullPage from '../../components/FullPage'
import ScrollableContent from '../../components/ScrollableContent'
import SearchBar from '../../components/SearchBar'
import SectionHeader from '../../components/SectionHeader'
import { PAGE_SIZE, TIPI_MEZZO } from '../../consts'
import { useOperatoriTipiMezziList } from '../../hooks/operatori'
import { useTableSorter } from '../../hooks/sorter'
import { CheckCircleIcon, TimesCircleIcon } from '@patternfly/react-icons'

export default function OperatoriTipiMezzoList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const filters = useMemoCompare({
    page: searchParams.get('page') ?? 1,
    search: searchParams.get('search') ?? '',
    ordering: searchParams.get('ordering') ?? '',
  })
  const sorter = useTableSorter(filters.ordering, (ordering) =>
    setSearchParams({ ...filters, ordering, page: 1 })
  )

  const [{ data, pending, error }] = useOperatoriTipiMezziList(filters)

  return (
    <FullPage>
      <SectionHeader
        bottomPadded
        title="Anagrafica mezzi"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/anagrafica-mezzi">
              Anagrafica Mezzi
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        bottomLeft={
          <SearchBar
            initialValue={filters.search}
            onChange={(search) =>
              setSearchParams({ ...filters, page: 1, search })
            }
          />
        }
      />
      <ScrollableContent>
        <TableComposable isStickyHeader>
          <Thead>
            <Tr>
              <Th sort={sorter('operatore__nome')}>Operatore</Th>
              <Th sort={sorter('tipo_mezzo')}>Tipo Mezzo</Th>
              <Th sort={sorter('numero_mezzi')}>N. Mezzi</Th>
              <Th>Id mobishare</Th>
              <Th>Data aggiornamento</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              data.list.map((operatoreTipoMezzo) => (
                <Tr
                  isHoverable
                  key={operatoreTipoMezzo.id}
                  onRowClick={() =>
                    navigate(`/anagrafica-mezzi/${operatoreTipoMezzo.id}`)
                  }
                >
                  <Td>{operatoreTipoMezzo.operatore.nome}</Td>
                  <Td>{TIPI_MEZZO[operatoreTipoMezzo.tipo_mezzo]}</Td>
                  <Td>{operatoreTipoMezzo.numero_mezzi}</Td>
                  <Td>
                    {operatoreTipoMezzo.id_operatore_mobishare ? (
                      <CheckCircleIcon
                        style={{ color: 'green', cursor: 'pointer' }}
                      />
                    ) : (
                      <TimesCircleIcon
                        style={{ color: 'red', cursor: 'pointer' }}
                      />
                    )}{' '}
                    {operatoreTipoMezzo.id_operatore_mobishare}
                  </Td>
                  <Td>
                    {operatoreTipoMezzo.data_importazione
                      ? dayjs(operatoreTipoMezzo.data_importazione).format(
                          'DD/MM/YYYY'
                        )
                      : null}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </TableComposable>
        {data?.pagination && (
          <Pagination
            variant="bottom"
            isSticky
            onSetPage={(_, page) => {
              setSearchParams({ ...filters, page })
            }}
            page={+(searchParams.get('page') ?? 1)}
            perPageOptions={[{ title: PAGE_SIZE, value: PAGE_SIZE }]}
            itemCount={data.pagination.count}
            perPage={PAGE_SIZE}
          />
        )}
      </ScrollableContent>
    </FullPage>
  )
}
