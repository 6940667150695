import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import AccountList from './AccountList'
import AccountAdd from './AccountAdd'
import AccountDetail from './AccountDetail'

export default function Rilevatori() {
  return (
    <Routes>
      <Route index element={<AccountList />} />
      <Route path="add" element={<AccountAdd />} />
      <Route path=":id" element={<AccountDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
