import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import OperatoreDetail from './OperatoreDetail'
import OperatoriList from './OperatoriList'

export default function Operatori() {
  return (
    <Routes>
      <Route index element={<OperatoriList />} />
      <Route path=":id" element={<OperatoreDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
