import { Alert, Title, Text, Grid, GridItem } from '@patternfly/react-core'
import {
  ImageIcon,
  EditAltIcon,
  MigrationIcon,
  TrashIcon,
} from '@patternfly/react-icons'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import classNames from 'classnames'
import { normalizeSchema, groupBySchema, computeDisabled } from './scheda'
import { useCallback, useMemo } from 'react'
import { STATI_RILIEVO } from '../../../consts'
import { makeBikeStationLabel } from '../../../utils'

function Problem({ problem }) {
  if (!problem) {
    return null
  }

  return (
    <div className="pf-u-display-flex pf-u-h-100 pf-u-align-items-center pf-u-justify-content-flex-end">
      {Object.keys(problem).map((k) => (
        <div className="pf-u-danger-color-100" key={k}>
          {STATI_RILIEVO[k]}
        </div>
      ))}
    </div>
  )
}

function CheckboxText({ isChecked, label }) {
  return (
    <div>
      {isChecked === true && 'Si'}
      {isChecked === false && 'No'}
      {label && <span className="pf-u-ml-md">{label}</span>}
    </div>
  )
}

function TableQuestions({
  config,
  data,
  index,
  problem,
  openImage,
  openEdit,
  openMoveImage,
  openRemoveImage,
  editable,
  logs,
}) {
  const dataByDomanda = useMemo(() => keyBy(data, 'domanda'), [data])
  const choicesByValue = useMemo(
    () => keyBy(config.choices ?? [], (x) => x[0]),
    [config.choices]
  )
  const problemsByQuestions = useMemo(() => {
    if (!problem) {
      return {}
    }
    return Object.keys(problem).reduce((dict, k) => {
      const deProblem = problem[k]
      deProblem.domande.forEach((d) => {
        if (!dict[d]) {
          dict[d] = {}
        }
        dict[d][k] = true
      })
      return dict
    }, {})
  }, [problem])

  const logsByQuestions = useMemo(() => {
    if (!logs) {
      return {}
    }
    return groupBy(logs, 'domanda.id')
  }, [logs])

  return (
    <div className="pf-u-mb-sm">
      <Title headingLevel="h4" className=" pf-u-my-md">
        {config.label}
      </Title>
      {config.questions.map((question, i) => {
        const datum = get(dataByDomanda, question.pk)

        if (!datum) {
          return null
        }
        const currentChoice = choicesByValue[datum.stato]
        const value = currentChoice && currentChoice[1]

        const zebraIndex = index + i

        const problemOfQuestion = problemsByQuestions[question.pk]
        const logOfQuestion = (logsByQuestions[question.pk] ?? [])[0]

        return (
          <Grid
            key={question.pk}
            className={classNames('pf-u-p-sm', {
              'pf-u-background-color-200': zebraIndex % 2 === 0,
            })}
          >
            <GridItem span={4}>
              <div>{question.label}</div>
            </GridItem>
            <GridItem span={3}>
              <Text>{value}</Text>
            </GridItem>
            <GridItem span={1}>
              {datum.immagine ? (
                <ImageIcon
                  title="Visualizza immagine"
                  onClick={() => {
                    openImage({
                      src: datum.immagine,
                      caption: `Immagine ${question.label}`,
                    })
                  }}
                  className="pf-u-active-color-100 cursor-pointer"
                />
              ) : null}
            </GridItem>
            <GridItem span={1}>
              {(() => {
                if (editable && datum.immagine) {
                  return (
                    <>
                      <MigrationIcon
                        title="Sposta immagine"
                        onClick={() => {
                          openMoveImage({
                            label: question.label,
                            field: config,
                            domanda: question.pk,
                          })
                        }}
                        className="pf-u-active-color-100 cursor-pointer"
                        size="sm"
                      />
                      <TrashIcon
                        title="Rimuovi immagine"
                        onClick={() => {
                          openRemoveImage({
                            label: question.label,
                            field: config,
                            domanda: question.pk,
                          })
                        }}
                        className="pf-u-active-color-100 cursor-pointer pf-u-ml-md"
                        size="sm"
                      />
                    </>
                  )
                }
                return null
              })()}
            </GridItem>
            <GridItem span={1}>
              {(() => {
                if (editable) {
                  // NOTE: This old if permits to edit only for problemi
                  // or past problemi
                  // if (problemOfQuestion || logOfQuestion) {
                  return (
                    <EditAltIcon
                      title="Modifica valore"
                      onClick={() => {
                        openEdit({
                          label: question.label,
                          value: datum.stato,
                          field: config,
                          domanda: question.pk,
                        })
                      }}
                      className="pf-u-active-color-100 cursor-pointer"
                      size="sm"
                    />
                  )
                  // }
                }
                return null
              })()}
            </GridItem>
            <GridItem span={2}>
              <Problem problem={problemOfQuestion} />
            </GridItem>
          </Grid>
        )
      })}
    </div>
  )
}

const CharQuestion = ({ config, data, index, problem }) => {
  const choices = config.choices || []
  const choicesByValue = keyBy(choices, (x) => x[0])
  const currentChoice = choicesByValue[data]

  return (
    <Grid
      className={classNames('pf-u-p-sm', {
        'pf-u-background-color-200': index % 2 === 0,
      })}
    >
      <GridItem span={4}>
        <Text>{config.label}</Text>
      </GridItem>
      <GridItem span={3}>{currentChoice && currentChoice[1]}</GridItem>
      <GridItem span={5}>
        <Problem problem={problem} />
      </GridItem>
    </Grid>
  )
}

const TextQuestion = ({ config, data, index, problem, openEdit, editable }) => {
  return (
    <Grid
      className={classNames('pf-u-p-sm', {
        'pf-u-background-color-200': index % 2 === 0,
      })}
    >
      <GridItem span={4}>
        <Text>{config.label}</Text>
      </GridItem>
      <GridItem span={3}>{data || '-'}</GridItem>
      <GridItem span={1}>
        {/* NOTE: This line permitt to edit only {problem && editable && ( */}
        {editable && (
          <EditAltIcon
            title="Modifica valore"
            onClick={() => {
              openEdit({
                value: data,
                field: config,
              })
            }}
            className="pf-u-active-color-100 cursor-pointer"
            size="sm"
          />
        )}
      </GridItem>
      <GridItem span={4}>
        <Problem problem={problem} />
      </GridItem>
    </Grid>
  )
}

const ImageQuestion = ({ config, data, index, problem, openImage }) => {
  return (
    <Grid
      className={classNames('pf-u-p-sm', {
        'pf-u-background-color-200': index % 2 === 0,
      })}
    >
      <GridItem span={4}>
        <Text>{config.label}</Text>
      </GridItem>
      <GridItem span={3}>
        {data && (
          <ImageIcon
            title="Visualizza immagine"
            onClick={() => {
              openImage({
                src: data,
                caption: `Immagine ${config.label}`,
              })
            }}
            className="pf-u-active-color-100 cursor-pointer"
          />
        )}
      </GridItem>
      <GridItem span={5}>
        <Problem problem={problem} />
      </GridItem>
    </Grid>
  )
}

const BooleanQuestion = ({ config, data, index, problem }) => {
  return (
    <Grid
      className={classNames('pf-u-p-sm', {
        'pf-u-background-color-200': index % 2 === 0,
      })}
    >
      <GridItem span={4}>
        <Text>{config.label}</Text>
      </GridItem>
      <GridItem span={3}>
        <CheckboxText
          id={config.name}
          isDisabled
          isChecked={data}
          aria-label={config.label}
        />
      </GridItem>
      <GridItem span={5}>
        <Problem problem={problem} />
      </GridItem>
    </Grid>
  )
}

const BooleanGroupedQuestions = ({
  problemsByField,
  groupConfig,
  schedaData,
  scheda,
  index,
}) => {
  return (
    <Grid
      className={classNames('pf-u-p-sm', {
        'pf-u-background-color-200': index % 2 === 0,
      })}
    >
      <GridItem span={4}>
        <Text>{groupConfig.label}</Text>
      </GridItem>
      <GridItem span={8}>
        {groupConfig.fields.map((fieldConfig) => (
          <div
            className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
            key={fieldConfig.name}
          >
            <CheckboxText
              label={fieldConfig.label}
              id={fieldConfig.name}
              isDisabled
              isChecked={get(schedaData, fieldConfig.name, false)}
              aria-label={fieldConfig.label}
            />
            <div style={{ flex: 1 }}>
              <Problem problem={problemsByField[fieldConfig.name]} />
            </div>
          </div>
        ))}
      </GridItem>
    </Grid>
  )
}

const PickerAreaPedonaleQuestion = ({
  config,
  data,
  index,
  problem,
  schedaData,
}) => {
  const area = get(schedaData, config.data_field, {})
  const areaName = area?.properties?.nome || '-'
  return (
    <Grid
      className={classNames('pf-u-p-sm', {
        'pf-u-background-color-200': index % 2 === 0,
      })}
    >
      <GridItem span={4}>
        <Text>{config.label}</Text>
      </GridItem>
      <GridItem span={3}>{areaName}</GridItem>
      <GridItem span={5}>
        <Problem problem={problem} />
      </GridItem>
    </Grid>
  )
}

const BikeStationQuestion = ({ config, data, index, problem, schedaData }) => {
  const stazione = get(schedaData, config.data_field, {})
  const stationName = makeBikeStationLabel(stazione)
  return (
    <Grid
      className={classNames('pf-u-p-sm', {
        'pf-u-background-color-200': index % 2 === 0,
      })}
    >
      <GridItem span={4}>
        <Text>{config.label}</Text>
      </GridItem>
      <GridItem span={3}>{stationName}</GridItem>
      <GridItem span={5}>
        <Problem problem={problem} />
      </GridItem>
    </Grid>
  )
}

const SelectListQuestion = ({ config, data, index, problem }) => {
  return (
    <Grid
      className={classNames('pf-u-p-sm', {
        'pf-u-background-color-200': index % 2 === 0,
      })}
    >
      <GridItem span={4}>
        <Text>{config.label}</Text>
      </GridItem>
      <GridItem span={3}>
        {data.map((o, i) => (
          <div key={i}>{o}</div>
        ))}
      </GridItem>
      <GridItem span={5}>
        <Problem problem={problem} />
      </GridItem>
    </Grid>
  )
}

const FIELDS_MAP = {
  tablequestions: TableQuestions,
  char: CharQuestion,
  text: TextQuestion,
  boolean: BooleanQuestion,
  picker_area_pedonale: PickerAreaPedonaleQuestion,
  image: ImageQuestion,
  jsonselectlist: SelectListQuestion,
  bikestation: BikeStationQuestion,
}

const NULL_MAP = {
  tablequestions: [],
  char: '',
  text: '',
  boolean: null,
  booleangrouped: [],
  picker_area_pedonale: null,
  image: null,
}

function SchedaField({
  config,
  schedaData,
  index,
  openImage,
  openEdit,
  openMoveImage,
  openRemoveImage,
  editable,
  problemsByField,
  logsByField,
}) {
  const FieldComponent = FIELDS_MAP[config.type]

  if (!FieldComponent) return <div>missing:{config.type}</div>

  const nullValue = get(NULL_MAP, config.type, null)

  const data = get(schedaData, `[${config.name}]`, nullValue)

  const problem = problemsByField[config.name]

  const logs = logsByField[config.name]

  return (
    <FieldComponent
      logs={logs}
      editable={editable}
      problem={problem}
      openImage={openImage}
      openEdit={openEdit}
      openMoveImage={openMoveImage}
      openRemoveImage={openRemoveImage}
      config={config}
      data={data}
      index={index}
      schedaData={schedaData}
    />
  )
}

export default function RilievoScheda({
  scheda,
  rilievo,
  openImage,
  openEdit,
  openMoveImage,
  openRemoveImage,
  editable,
  logs,
}) {
  const normalizedSchema = useMemo(() => normalizeSchema(scheda), [scheda])
  const schedaData = get(rilievo, scheda.name, {})

  const filteredSchema = useMemo(
    () =>
      normalizedSchema.filter((field) => {
        if (!schedaData) {
          return true
        }
        return !computeDisabled(schedaData, field)
      }),
    [normalizedSchema, schedaData]
  )

  const groupedSchema = useMemo(
    () =>
      groupBySchema({
        ...scheda,
        schema: filteredSchema,
      }),
    [filteredSchema, scheda]
  )

  const problemsByField = useMemo(() => {
    if (!schedaData) {
      return {}
    }
    const statiMap = {
      'anomalia-tecnica': 'computed_anomalie_tecniche',
      'anomalia-sosta': 'computed_anomalie_sosta',
      'non-conforme': 'computed_non_conformita',
      'anomalia-lieve': 'computed_anomalie_lievi',
    }
    return Object.keys(statiMap).reduce((dict, k) => {
      schedaData[statiMap[k]].forEach((problem) => {
        if (!dict[problem.name]) {
          dict[problem.name] = {}
        }
        dict[problem.name][k] = problem
      })
      return dict
    }, {})
  }, [schedaData])

  const openEditOnScheda = useCallback(
    (obj) =>
      openEdit({
        ...obj,
        nome_scheda: scheda.name,
      }),
    [openEdit, scheda.name]
  )

  const openMoveImageOnScheda = useCallback(
    (obj) =>
      openMoveImage({
        ...obj,
        nome_scheda: scheda.name,
      }),
    [openMoveImage, scheda.name]
  )

  const openRemoveImageOnScheda = useCallback(
    (obj) =>
      openRemoveImage({
        ...obj,
        nome_scheda: scheda.name,
      }),
    [openRemoveImage, scheda.name]
  )

  const logsByField = useMemo(() => {
    if (!logs) {
      return {}
    }
    return groupBy(
      logs.filter((l) => l.nome_scheda === scheda.name),
      'nome_campo'
    )
  }, [logs, scheda.name])

  return (
    <div className="pf-u-mb-md">
      <Title headingLevel="h4" size="lg" className="pf-u-mb-md">
        {scheda.label}
      </Title>
      {!schedaData && <Alert variant="warning" title="Scheda non compilata" />}
      {schedaData &&
        groupedSchema.map((groupConfig, index) => {
          return (
            <div key={groupConfig.name}>
              {groupConfig.label && (
                <BooleanGroupedQuestions
                  editable={editable}
                  problemsByField={problemsByField}
                  logsByField={logsByField}
                  groupConfig={groupConfig}
                  schedaData={schedaData}
                  scheda={scheda}
                />
              )}
              {!groupConfig.label &&
                groupConfig.fields.map((fieldConfig, innerIndex) => (
                  <SchedaField
                    editable={editable}
                    problemsByField={problemsByField}
                    logsByField={logsByField}
                    openImage={openImage}
                    openEdit={openEditOnScheda}
                    openMoveImage={openMoveImageOnScheda}
                    openRemoveImage={openRemoveImageOnScheda}
                    key={fieldConfig.name}
                    config={fieldConfig}
                    schedaData={schedaData}
                    index={index + innerIndex}
                  />
                ))}
            </div>
          )
        })}
    </div>
  )
}
