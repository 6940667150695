import { Breadcrumb } from '@patternfly/react-core'
import { useNavigate } from 'react-router'
import AmministratoreForm from '../../../components/AmministratoreForm'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import { useAddAmministratore } from '../../../hooks/amministratore'

export default function AmministratoreAdd() {
  const { run: addAmministratore } = useAddAmministratore()[1]
  const navigate = useNavigate()
  return (
    <FullPage>
      <SectionHeader
        title="Nuovo amministratore"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/amministratori">
              Gestione amministratori
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to="/amministratori/add">
              Nuovo
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <ScrollableContent>
        <AmministratoreForm
          save={(data) =>
            addAmministratore
              .onSuccess((created) => navigate(`/amministratori/${created.id}`))
              .asPromise(data)
          }
        />
      </ScrollableContent>
    </FullPage>
  )
}
