import { Breadcrumb, Button, Modal, ModalVariant } from '@patternfly/react-core'
import { useNavigate, useParams } from 'react-router'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import RilevatoreForm from '../../../components/RilevatoreForm'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useRilevatoreDetail } from '../../../hooks/rilevatore'

export default function RilevatoreDetail() {
  const { id } = useParams()
  const [{ data: rilevatore }, { updateRilevatore, removeRilevatore }] =
    useRilevatoreDetail(id)
  const [modalRm, modalRmActions] = useModalTrigger()
  const navigate = useNavigate()

  return (
    <FullPage>
      <SectionHeader
        title={rilevatore?.user?.name ?? ''}
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/rilevatori">
              Gestione rilevatori
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/rilevatori/${id}`}>
              Dettaglio rilevatore
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <>
            {rilevatore && (
              <Button
                isDisabled={rilevatore.rilievi_count > 0}
                onClick={modalRmActions.open}
                variant="danger"
              >
                Elimina
                {rilevatore.rilievi_count > 0 && (
                  <small>{` (${rilevatore.rilievi_count} rilievi associati)`}</small>
                )}
              </Button>
            )}
          </>
        }
      />
      <ScrollableContent>
        {rilevatore && (
          <RilevatoreForm
            rilevatore={rilevatore}
            save={(data) => updateRilevatore.asPromise(rilevatore.id, data)}
          />
        )}
      </ScrollableContent>
      {rilevatore && (
        <Modal
          title={'Rimuovi rilevatore'}
          className="modal-actions-end"
          isOpen={modalRm.isOpen}
          variant={ModalVariant.medium}
          actions={[
            <Button onClick={modalRmActions.close} variant="secondary">
              Annulla
            </Button>,
            <Button
              onClick={() => {
                removeRilevatore
                  .onSuccess(() => {
                    navigate('/rilevatori')
                  })
                  .run(rilevatore.id)
              }}
            >
              Conferma
            </Button>,
          ]}
          onClose={modalRmActions.close}
        >
          Rimuovere rilevatore {rilevatore.user.name} ?
        </Modal>
      )}
    </FullPage>
  )
}
