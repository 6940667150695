import { deps, rj, useRunRj } from 'react-rocketjump'
import { useAuthActions } from 'use-eazy-auth'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import api from '../api'
import { PAGE_SIZE } from '../consts'
import { useCallback } from 'react'

const VieListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    }),
    rjDebounce(200)
  )
  .computed({
    list: 'getList',
    pagination: 'getPagination',
    loading: 'isLoading',
    error: 'getError',
  })
  .effect({
    name: 'VieList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/vie', params),
  })

export function useVieList(params) {
  return useRunRj(VieListState, [deps.maybe(params)], false)
}

export function useFetchVie() {
  const { callAuthApiObservable } = useAuthActions()
  return useCallback((...args) => {
    return callAuthApiObservable(
      (t) =>
        (params = {}) =>
          api.auth(t).get("/api/vie", params),
      ...args
    )
  }, [callAuthApiObservable])
}