export const PAGE_SIZE = 100

export const TIPI_MEZZO = {
  auto: 'Auto',
  bike_station_based: 'Bike station based',
  bike_free_floating: 'Bike free floating',
  monopattino: 'Monopattino',
  scooter: 'Scooter',
}

export const STATI_RILIEVO = {
  conforme: 'Conforme',
  'anomalia-tecnica': 'Anomalia tecnica',
  'anomalia-sosta': 'Anomalia sosta',
  'non-conforme': 'Non conforme',
  'anomalia-lieve': 'Conforme con annotazione',
}

export const STATI_SEGNALAZIONE = {
  in_arrivo: 'In arrivo',
  inviata: 'Inviata',
  invalida: 'Invalida',
  risolta: 'Risolta',
  presa_in_carico_operatore: 'Presa in carico operatore',
  non_risolvibile: 'Non Risolvibile',
  riaperta: 'Riaperta',
  richiesta_chiusura: 'Richiesta chiusura',
}

export const TIPI_RILIEVO = {
  rapido: 'Rapido',
  completo: 'Completo',
}

export const N_FOTO_AGGIUNTIVE_RILIEVO = 3
