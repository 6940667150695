import { Breadcrumb } from '@patternfly/react-core'
import { useNavigate } from 'react-router'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ReportForm from '../../../components/ReportRapidoForm'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import { useAddReportRapido } from '../../../hooks/reportsRapidi'

export default function ReportAdd() {
  const { run: addReport } = useAddReportRapido()[1]
  const navigate = useNavigate()
  return (
    <FullPage>
      <SectionHeader
        title="Nuovo report rapido"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/reports-rapidi">
              Reports Rapidi
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to="/reports-rapidi/add">
              Nuovo report
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <ScrollableContent>
        <ReportForm
          save={(data) =>
            addReport
              .onSuccess((created) => navigate(`/reports-rapidi/${created.id}`))
              .asPromise(data)
          }
        />
      </ScrollableContent>
    </FullPage>
  )
}
