import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { Field, Formik, useFormikContext } from 'formik'
import { transformErrorForForm, MultiViaAutocomplete } from '../Form'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import {
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
  FormSelectOption,
} from '@patternfly/react-core'
import { DatePickerField, SelectField } from '../Form/fields'
import { TIPI_MEZZO } from '../../consts'
import { useOperatoriTipiMezzoFlat } from '../../hooks/operatori'

const INITIAL_VALUES = {
  periodo_da: '',
  periodo_a: '',
  tipo_mezzo: '',
  operatore: '',
  vie: [],
}

const ReportSchema = Yup.object().shape({
  periodo_da: Yup.date().typeError('').required().label('Periodo da'),
  periodo_a: Yup.date().typeError('').required().label('Periodo a'),
  tipo_mezzo: Yup.string().required().label('Tipo mezzo'),
  operatore: Yup.string().required().label('Operatore'),
  vie: Yup.mixed().label('Vie'),
})

function ReportFormFields() {
  const [{ data: operatoriTipiMezzo }] = useOperatoriTipiMezzoFlat()
  const { values } = useFormikContext()

  const tipoMezzoOptions = useMemo(() => {
    let deOptions = [{ value: '', label: '-- Seleziona tipo mezzo --' }]
    if (operatoriTipiMezzo) {
      deOptions.push(
        ...uniq(
          operatoriTipiMezzo
            .filter((ot) => {
              if (!values.operatore) {
                return true
              }
              return +ot.operatore.id === +values.operatore
            })
            .map((s) => s.tipo_mezzo)
        ).map((value) => ({
          value,
          label: TIPI_MEZZO[value],
        }))
      )
    }
    return deOptions
  }, [operatoriTipiMezzo, values.operatore])

  const operatoriOptions = useMemo(() => {
    let deOptions = [{ value: '', label: '-- Seleziona operatore --' }]
    if (operatoriTipiMezzo) {
      deOptions.push(
        ...uniqBy(
          operatoriTipiMezzo.filter((ot) => {
            if (!values.tipo_mezzo) {
              return true
            }
            return ot.tipo_mezzo === values.tipo_mezzo
          }),
          (ot) => ot.operatore.id
        ).map((ot) => ({
          value: ot.operatore.id,
          label: ot.operatore.nome,
        }))
      )
    }
    return deOptions
  }, [operatoriTipiMezzo, values.tipo_mezzo])

  return (
    <>
      <Field
        isRequired
        label="Periodo da"
        name="periodo_da"
        component={DatePickerField}
      />
      <Field
        isRequired
        label="Periodo a"
        name="periodo_a"
        component={DatePickerField}
      />
      <Field label="Tipo Mezzo" name="tipo_mezzo" component={SelectField}>
        {tipoMezzoOptions.map((o) => (
          <FormSelectOption label={o.label} value={o.value} key={o.value} />
        ))}
      </Field>
      <Field label="Operatore" name="operatore" component={SelectField}>
        {operatoriOptions.map((o) => (
          <FormSelectOption label={o.label} value={o.value} key={o.value} />
        ))}
      </Field>
      <Field
        maxHeight={200}
        label="Via"
        name="vie"
        component={MultiViaAutocomplete}
      />
    </>
  )
}

export default function ReportCompletoForm({
  report,
  save,
  className,
  cancel,
}) {
  return (
    <Formik
      validationSchema={ReportSchema}
      initialValues={report ?? INITIAL_VALUES}
      onSubmit={(values, actions) =>
        save(values)
          .then(() => {
            actions.setStatus({ success: true })
          })
          .catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
      }
    >
      {({ handleSubmit, isSubmitting, resetForm, status }) => (
        <Form onSubmit={handleSubmit} className={className} isHorizontal>
          {status?.success && (
            <FormAlert>
              <Alert
                variant="success"
                title={
                  report?.id
                    ? 'Report modificato con successo'
                    : 'Report creato con successo'
                }
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          <div className="pf-u-pt-m" />
          <ReportFormFields />
          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between">
              <Button
                isDisabled={isSubmitting}
                variant="secondary"
                type="button"
                onClick={() => {
                  cancel
                    ? cancel()
                    : resetForm({ values: report ?? INITIAL_VALUES })
                }}
              >
                Annulla
              </Button>
              <Button isDisabled={isSubmitting} type="submit">
                {report?.id ? 'Modifica' : 'Aggiungi'}
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
