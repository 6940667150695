import { useMemo } from 'react'

function fromSortObjToStr(obj) {
  return Object.keys(obj)
    .reduce((values, k) => {
      if (!obj[k]) {
        return values
      }
      values.push(obj[k] === 'desc' ? `-${k}` : k)
      return values
    }, [])
    .join(',')
}

function fromSortStrToObj(str) {
  return (
    str
      .split(',')
      .filter(Boolean)
      // NOTE: For now we handle one col at time
      .slice(0, 1)
      .reduce((all, col) => {
        let toCol = col
        let direction
        if (col.indexOf('-') === 0) {
          toCol = col.slice(1)
          direction = 'desc'
        } else {
          direction = 'asc'
        }
        all[toCol] = direction
        return all
      }, {})
  )
}

export function useTableSorter(value, onChange) {
  const sortObj = useMemo(() => fromSortStrToObj(value ?? ''), [value])

  function handleOnSort(field, direction) {
    onChange(fromSortObjToStr({ [field]: direction }))
  }

  return function (field) {
    return {
      onSort: (_e, _i, direction) => handleOnSort(field, direction),
      sortBy: sortObj[field]
        ? { direction: sortObj[field], defaultDirection: 'asc' }
        // TODO: This part is total messy this is a ??? workaround
        // to have stuff just working tra to figure aout hot this hells works
        : { index: 0, },
    }
  }
}
