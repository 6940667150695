import React from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  InputField,
  transformErrorForForm,
  FileUploadField,
  SwitchField,
} from '../Form'
import {
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
} from '@patternfly/react-core'

const INITIAL_VALUES = {
  user: {
    name: '',
    email: '',
  },
  riceve_mail_segnalazione: false,
  firma: null,
}

const AmministratoreSchema = Yup.object().shape({
  firma: Yup.mixed().label('Firma'),
  user: Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
    name: Yup.string().required().label('Nome'),
  }),
})

export default function AmministratoreForm({
  amministratore,
  save,
  className,
}) {
  return (
    <Formik
      validationSchema={AmministratoreSchema}
      initialValues={amministratore ?? INITIAL_VALUES}
      onSubmit={(values, actions) =>
        save(values)
          .then(() => {
            actions.setStatus({ success: true })
          })
          .catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
      }
    >
      {({ handleSubmit, isSubmitting, resetForm, status }) => (
        <Form onSubmit={handleSubmit} className={className} isHorizontal>
          {status?.success && (
            <FormAlert>
              <Alert
                variant="success"
                title={
                  amministratore?.id
                    ? 'Amministratore modificato con successo'
                    : 'Amministratore creato con successo'
                }
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          <div className="pf-u-pt-m" />
          <Field
            isRequired
            label="Nome"
            name="user.name"
            component={InputField}
          />
          <Field
            isRequired
            label="Email"
            name="user.email"
            component={InputField}
          />
          <Field
            label="Firma"
            name="firma"
            component={FileUploadField}
            accept="image/png, image/jpeg"
          />
          <Field
            label="Riceve mail segnalazione?"
            name="riceve_mail_segnalazione"
            aria-label="Riceve mail segnalazione?"
            component={SwitchField}
          />
          {amministratore?.id && (
            <Field
              label="Attivo?"
              name="user.is_active"
              aria-label="Attivo?"
              component={SwitchField}
            />
          )}

          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between">
              <Button
                isDisabled={isSubmitting}
                variant="secondary"
                type="button"
                onClick={() => {
                  resetForm({ values: amministratore ?? INITIAL_VALUES })
                }}
              >
                Annulla
              </Button>
              <Button isDisabled={isSubmitting} type="submit">
                {amministratore?.id ? 'Modifica' : 'Aggiungi'}
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
