import { useMemo, useEffect, useCallback, useState } from 'react'
import { TimesCircleIcon } from '@patternfly/react-icons'
import { useSearchParams } from 'react-router-dom'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import useMeasure from 'react-use-measure'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import {
  DatePickerIta,
  transformDateFormatToUS,
} from '../../components/Form/fields'
import dayjs from 'dayjs'
import {
  Breadcrumb,
  Button,
  Form,
  InputGroup,
  Grid,
  GridItem,
  Card,
  CardBody,
  Title,
  Text,
  CardHeader,
  FormSelect,
  FormSelectOption,
} from '@patternfly/react-core'
import {
  Chart,
  ChartAxis,
  ChartBar,
  ChartGroup,
  ChartArea,
} from '@patternfly/react-charts'
import classNames from 'classnames'
import SectionHeader from '../../components/SectionHeader'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import ScrollableContent from '../../components/ScrollableContent'
import FullPage from '../../components/FullPage'
import {
  useDashboardCountRilievi,
  useDashboardTimeSerieTipoMezzo,
} from '../../hooks/dashboard'
import useConstant from 'magik-react-hooks/useConstant'
import { TIPI_MEZZO } from '../../consts'

function PeriodoForm({ values, setValues }) {
  return (
    <Form isHorizontal>
      <div className="pf-u-display-flex pf-u-justify-content-space-between pf-u-align-items-center">
        <Text className="pf-u-mr-sm pf-u-font-weight-bold">Inizio</Text>
        <InputGroup className="pf-u-mr-md">
          <DatePickerIta
            value={values.inizio}
            onChange={(value) =>
              setValues({ ...values, inizio: transformDateFormatToUS(value) })
            }
            inputProps={{ name: 'inizio' }}
          />
          <Button
            variant="control"
            isDisabled={!values.inizio}
            onClick={() => setValues({ ...values, inizio: '' })}
          >
            <TimesCircleIcon></TimesCircleIcon>
          </Button>
        </InputGroup>

        <Text className="pf-u-mr-md pf-u-font-weight-bold">Fine</Text>
        <InputGroup className="pf-u-mr-md">
          <DatePickerIta
            value={values.fine}
            onChange={(value) =>
              setValues({ ...values, fine: transformDateFormatToUS(value) })
            }
            inputProps={{ name: 'fine' }}
          />
          <Button
            variant="control"
            isDisabled={!values.fine}
            onClick={() => setValues({ ...values, fine: '' })}
          >
            <TimesCircleIcon></TimesCircleIcon>
          </Button>
        </InputGroup>

        <Text className="pf-u-mr-md">Tipo</Text>
        <FormSelect
          value={values.tipo}
          onChange={(v) => {
            setValues({ ...values, tipo: v })
          }}
        >
          <FormSelectOption value="" label="Tutti"></FormSelectOption>
          <FormSelectOption
            value="completo"
            label="Completi"
          ></FormSelectOption>
          <FormSelectOption value="rapido" label="Rapidi"></FormSelectOption>
        </FormSelect>
      </div>
    </Form>
  )
}

function RowCount({ label, count, level = 'h1', className }) {
  return (
    <div
      className={classNames(
        'pf-u-display-flex pf-u-flex-direction-row pf-u-justify-content-space-between',
        className
      )}
    >
      <Title headingLevel={level}>{label}</Title>
      <div className="pf-u-text-align-right">
        <Title headingLevel={level}>{count}</Title>
      </div>
    </div>
  )
}

function TipiMezzoBar({ datiTipiMezzo }) {
  const data = useMemo(() => {
    if (!datiTipiMezzo) {
      return []
    }
    return datiTipiMezzo.map((datum, i) => ({
      name: TIPI_MEZZO[datum.tipo_mezzo],
      x: TIPI_MEZZO[datum.tipo_mezzo],
      y: datum.totale,
    }))
  }, [datiTipiMezzo])

  const [ref, bounds] = useMeasure()

  return (
    <div style={{ height: '250px' }} ref={ref}>
      {bounds && (
        <Chart
          ariaDesc="Average number of pets"
          ariaTitle="Bar chart example"
          // domain={{ y: [0, 9] }}
          domainPadding={{ x: [25, 25] }}
          // legendData={data.map(item => item.name)}
          legendData={data.map((d) => ({ name: d.name }))}
          legendOrientation="vertical"
          legendPosition="right"
          padding={{
            top: 20,
            bottom: 50,
            left: 80,
            right: 150, // Adjusted to accommodate legend
          }}
          width={bounds.width}
        >
          <ChartAxis />
          <ChartAxis dependentAxis showGrid />
          <ChartGroup>
            {data.map((datum, i) => (
              <ChartBar key={i} horizontal data={[datum]}></ChartBar>
            ))}
          </ChartGroup>
          {/* <ChartBar
          // style={{ data:  { fill: ({datum}) => {console.log(datum); return datum.fill}} }}
          horizontal data={data}
          /> */}
        </Chart>
      )}
    </div>
  )
}

function RilievoArea({ timeSerie }) {
  const data = useMemo(() => {
    if (!timeSerie) {
      return []
    }
    return timeSerie.map((datum, i) => ({
      name: 'serie',
      x: datum.data,
      y: datum.totale,
    }))
  }, [timeSerie])

  const [ref, bounds] = useMeasure()

  return (
    <div style={{ height: '250px' }} ref={ref}>
      {bounds && (
        <Chart
          ariaDesc="Average number of pets"
          ariaTitle="Area chart example"
          maxDomain={{ y: 9 }}
          padding={{
            bottom: 50,
            top: 20,
          }}
          width={bounds.width}
        >
          <ChartAxis />
          <ChartAxis dependentAxis showGrid />
          <ChartGroup>
            <ChartArea data={data} interpolation="monotoneX" />
          </ChartGroup>
        </Chart>
      )}
    </div>
  )
}

function getToday() {
  const now = dayjs().format('YYYY-MM-DD')

  return {
    inizio: now,
    fine: now,
  }
}

function getYesterday() {
  const day = dayjs().subtract(1, 'days').format('YYYY-MM-DD')

  return {
    inizio: day,
    fine: day,
  }
}

function getThisWeek() {
  const now = dayjs()
  const start = now.startOf('week')

  return {
    inizio: start.format('YYYY-MM-DD'),
    fine: now.format('YYYY-MM-DD'),
  }
}

function getThisMonth() {
  const now = dayjs()
  const start = now.startOf('month')

  return {
    inizio: start.format('YYYY-MM-DD'),
    fine: now.format('YYYY-MM-DD'),
  }
}

function getLastMonth() {
  const now = dayjs()
  const start = now.subtract(1, 'month').startOf('month')
  const end = start.endOf('month')

  return {
    inizio: start.format('YYYY-MM-DD'),
    fine: end.format('YYYY-MM-DD'),
  }
}

export default function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams()

  const DEFAULTS_DATE = useConstant(getThisMonth)

  const filtersDate = useMemoCompare({
    inizio: searchParams.get('inizio') ?? DEFAULTS_DATE.inizio,
    fine: searchParams.get('fine') ?? DEFAULTS_DATE.fine,
    tipo: searchParams.get('tipo') ?? '',
  })

  const filterTipoMezzo = useMemoCompare({
    tipo_mezzo: searchParams.get('tipo_mezzo') ?? '',
  })

  const periods = useMemo(
    () => [
      {
        value: 'today',
        label: 'Oggi',
        dates: getToday(),
      },
      {
        value: 'yesterday',
        label: 'Ieri',
        dates: getYesterday(),
      },
      {
        value: 'this_week',
        label: 'Questa settimana',
        dates: getThisWeek(),
      },
      {
        value: 'this_month',
        label: 'Questo mese',
        dates: getThisMonth(),
      },
      {
        value: 'last_month',
        label: 'Mese scorso',
        dates: getLastMonth(),
      },
    ],
    []
  )

  const handlePeriodChange = useCallback(
    (value) => {
      const selectedPeriod = find(periods, { value })
      if (selectedPeriod) {
        setSearchParams({ ...filtersDate, ...selectedPeriod.dates })
      }
    },
    [filtersDate, periods, setSearchParams]
  )

  const selectedPeriod = useMemo(() => {
    return (
      find(
        periods,
        (p) =>
          filtersDate.inizio === p.dates.inizio &&
          filtersDate.fine === p.dates.fine
      )?.value ?? ''
    )
  }, [filtersDate.fine, filtersDate.inizio, periods])

  const [{ data: countStats }, { run: refreshCounts }] =
    useDashboardCountRilievi(filtersDate)
  const [{ data: timeSerieTipoMezzo }, { run: refreshTimeSerieTipoMezzo }] =
    useDashboardTimeSerieTipoMezzo(filterTipoMezzo)

  useEffect(() => {
    refreshCounts(filtersDate)
  }, [filtersDate, refreshCounts])

  useEffect(() => {
    refreshTimeSerieTipoMezzo(filterTipoMezzo)
  }, [filterTipoMezzo, refreshTimeSerieTipoMezzo])

  const nestedCounts = useMemo(() => {
    if (!countStats) {
      return []
    }
    const byOperatoreTipo = groupBy(countStats.by_operatore_tipo, 'tipo_mezzo')
    let out = []
    countStats.by_tipo_mezzo.forEach((tipoRow) => {
      out.push({
        label: TIPI_MEZZO[tipoRow.tipo_mezzo],
        count: tipoRow.totale,
        level: 'h2',
        className: 'pf-u-mt-sm text-capitalize',
      })
      const records = sortBy(byOperatoreTipo[tipoRow.tipo_mezzo], 'nome')
      records.forEach((operatoreTipoRow) => {
        out.push({
          label: operatoreTipoRow.nome,
          count: operatoreTipoRow.totale,
          level: 'h4',
        })
      })
    })

    return out
  }, [countStats])

  return (
    <FullPage>
      <SectionHeader
        title="Dashboard"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/">Dashboard</BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <div className="pf-u-display-flex pf-u-justify-content-space-between pf-u-align-items-center"></div>
        }
        bottomLeft={
          <div className="pf-u-mb-sm">
            <PeriodoForm values={filtersDate} setValues={setSearchParams} />
          </div>
        }
        bottomRight={
          <div className="pf-u-mb-sm pf-u-display-flex pf-u-justify-content-start pf-u-align-items-center">
            <Text className="pf-u-mr-md">Periodo</Text>
            <FormSelect onChange={handlePeriodChange} value={selectedPeriod}>
              <FormSelectOption value="" label="Personalizzato" />
              {periods.map((p) => (
                <FormSelectOption
                  value={p.value}
                  label={p.label}
                  key={p.value}
                />
              ))}
            </FormSelect>
          </div>
        }
      />
      <ScrollableContent>
        <div className="pf-u-py-sm pf-u-h-100">
          <Grid hasGutter>
            <GridItem span={3} rowSpan={4}>
              {countStats && (
                <Card isFullHeight>
                  <CardBody>
                    <RowCount
                      className="pf-u-mb-sm"
                      label={'Totale'}
                      count={countStats['totale']}
                      level={'h1'}
                    ></RowCount>
                    {nestedCounts.map((nestedCount, i) => (
                      <RowCount
                        key={i}
                        label={nestedCount.label}
                        count={nestedCount.count}
                        level={nestedCount.level}
                        className={nestedCount.className}
                      ></RowCount>
                    ))}
                  </CardBody>
                </Card>
              )}
            </GridItem>
            <GridItem span={6} rowSpan={2}>
              <Card isFullHeight>
                <CardHeader>
                  <Title headingLevel="h1">Rilievi per tipo mezzo</Title>
                </CardHeader>
                <CardBody>
                  {countStats && (
                    <TipiMezzoBar
                      datiTipiMezzo={countStats.by_tipo_mezzo}
                    ></TipiMezzoBar>
                  )}
                </CardBody>
              </Card>
            </GridItem>

            <GridItem span={3} rowSpan={2}>
              <Card isFullHeight>
                <CardHeader>
                  <Title headingLevel="h1">Rilevatori</Title>
                </CardHeader>
                <CardBody>
                  {countStats &&
                    countStats.by_rilevatore.map((stat, i) => (
                      <RowCount
                        key={i}
                        label={stat.nome}
                        count={stat.totale}
                        level={'h3'}
                      ></RowCount>
                    ))}
                </CardBody>
              </Card>
            </GridItem>

            <GridItem span={9} rowSpan={2}>
              <Card isFullHeight>
                <CardHeader>
                  <Title headingLevel="h1">Andamento rilievi</Title>
                </CardHeader>
                <CardBody>
                  {countStats && (
                    <RilievoArea timeSerie={timeSerieTipoMezzo}></RilievoArea>
                  )}
                </CardBody>
              </Card>
            </GridItem>

            {/* <GridItem span={6} rowSpan={1}>
              <Card isFullHeight>
            </GridItem> */}
          </Grid>
        </div>
      </ScrollableContent>
    </FullPage>
  )
}
