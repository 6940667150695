import {
  Button,
  Form,
  ModalBoxBody,
  ModalBoxFooter,
} from '@patternfly/react-core'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputField, transformErrorForForm } from '../../components/Form'

const OperatoreSchema = Yup.object().shape({
  nome: Yup.string().required().label('Nome'),
  email: Yup.string().email().label('Email'),
})

const INITIAL_DATA = {
  nome: '',
  email: '',
}

export default function OperatoreFormBoxModal({ onClose, save, data }) {
  return (
    <Formik
      validationSchema={OperatoreSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={
        data ? { email: data.email ?? '', nome: data.nome } : INITIAL_DATA
      }
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody className="pf-c-form">
            <Field
              isRequired
              label={'Nome'}
              name="nome"
              component={InputField}
            />
            <Field
              label={'Email'}
              name="email"
              type="email"
              component={InputField}
            />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}
