import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import OperatoriTipiMezzoList from './OperatoriTipiMezzoList'
import OperatoreTipoMezzoDetail from './OperatoreTipoMezzoDetail'

export default function Rilevatori() {
  return (
    <Routes>
      <Route index element={<OperatoriTipiMezzoList />} />
      <Route path=":id" element={<OperatoreTipoMezzoDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
