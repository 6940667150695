export function countFilters(filters, names) {
  return names.reduce((c, name) => {
    return c + (String(filters[name]).trim() === '' ? 0 : 1)
  }, 0)
}

export function makeBikeStationLabel(stazione) {
  if (stazione) {
    let label = stazione.nome
    if (stazione.numero) {
      label += ` (${stazione.numero})`
    }
    return label
  }
  return ""
}

