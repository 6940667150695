import { AjaxError } from 'rxjs/ajax'

const naiveIsObj = (a) =>
  typeof a === 'object' && a !== null && !Array.isArray(a)

const arrayze = (a) => (Array.isArray(a) ? a : [a])

export const transformErrorForForm = (error) => {
  if (error.status === 400) {
    let errorData
    if (error instanceof AjaxError && naiveIsObj(error.response)) {
      errorData = error.response
    } else if (naiveIsObj(error.response?.body)) {
      errorData = error.response.body
    }

    function makeErrors(errorObj) {
      return Object.keys(errorObj).reduce(
        (transformed, key) => ({
          ...transformed,
          [key]: naiveIsObj(errorObj[key])
            ? makeErrors(errorObj[key])
            : // TODO: Better joins of errors...
              arrayze(errorObj[key]).join(' '),
        }),
        {}
      )
    }

    if (errorData) {
      return makeErrors(errorData)
    }
  }
  // When no 400 (not related 2 form)
  // add a special __noFieldsServerError key \w original error
  return {
    __noFieldsServerError: error,
  }
}

export const getNonFieldsErrorMessageAsString = (error) => {
  if(error.response){
    return JSON.stringify(error.response)
  }
  return error.message

}