import './index.css'
import '@patternfly/react-core/dist/styles/base.css'
import '@patternfly/patternfly/patternfly-addons.css'
import 'leaflet/dist/leaflet.css'

// import '@patternfly/react-core/dist/styles/'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import rjLogger from 'react-rocketjump/logger'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/it'

dayjs.extend(customParseFormat)
dayjs.locale('it')

rjLogger()

if (process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: 'https://c306e3ddb2ab422184df440fba849536@o1047151.ingest.sentry.io/6235750',
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  })
  console.log('Version', process.env.REACT_APP_SENTRY_RELEASE)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
