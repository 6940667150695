import FullPage from '../../components/FullPage'
import { Breadcrumb, Card, CardBody } from '@patternfly/react-core'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import SectionHeader from '../../components/SectionHeader'
import ProfileForm from '../../components/ProfileForm'
import {
  useEditAmministratoreProfile,
  useEditRilevatoreProfile,
} from '../../hooks/profile'

export default function EditProfile() {
  const { user } = useAuthUser()
  const { updateUser } = useAuthActions()
  const profile = user.amministratore
    ? {
        firma: user.amministratore.firma,
        riceve_mail_segnalazione: user.amministratore.riceve_mail_segnalazione,
      }
    : {
        firma: user.rilevatore.firma,
      }

  const { run: editAdminProfile } = useEditAmministratoreProfile()[1]
  const { run: editRilevatoreProfile } = useEditRilevatoreProfile()[1]

  return (
    <FullPage>
      <SectionHeader
        title={`Modifica ${user?.name}`}
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/profile">Profilo</BreadcrumbItemLink>
            <BreadcrumbItemLink to="/profile/edit">Modifica</BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <Card>
        <CardBody>
          <ProfileForm
            profile={profile}
            isAdmin={Boolean(user.amministratore)}
            save={(data) => {
              if (user.amministratore) {
                return editAdminProfile.onSuccess(updateUser).asPromise(data)
              } else {
                return editRilevatoreProfile
                  .onSuccess(updateUser)
                  .asPromise(data)
              }
            }}
          />
        </CardBody>
      </Card>
    </FullPage>
  )
}
