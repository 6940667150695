import dayjs from 'dayjs'
import { EditAltIcon } from '@patternfly/react-icons'
import { Title, Text } from '@patternfly/react-core'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Grid, GridItem } from '@patternfly/react-core'
import DefaultIcon from '../../../components/MarkerIcon'
import { TIPI_MEZZO } from '../../../consts'
import { makeBikeStationLabel } from '../../../utils'
import { Link } from 'react-router-dom'

export default function RilievoDetailIntro({
  rilievo,
  openImage,
  openModalIdentificativo,
  openModalOperatore,
  editable,
}) {
  return (
    <div>
      <div className="pf-u-mb-md">
        <Title headingLevel="h4" size="lg" className="pf-u-mb-md">
          Informazioni generali
        </Title>
        <Grid className="pf-u-mb-sm">
          <GridItem span={1}>
            <b>Rilevatore</b>
          </GridItem>
          <GridItem span={11}>{rilievo?.rilevatore_data.user.name}</GridItem>
        </Grid>
        <Grid className="pf-u-mb-sm">
          <GridItem span={1}>
            <b>Data e ora</b>
          </GridItem>
          <GridItem span={11}>
            {rilievo.dataora_inizio
              ? dayjs(rilievo.dataora_inizio).format('DD/MM/YYYY HH:mm')
              : null}
          </GridItem>
        </Grid>
        <Grid className="pf-u-mb-sm">
          <GridItem span={1}>
            <b>Posizione</b>
          </GridItem>
          <GridItem span={11}>
            {rilievo?.via_data.nome} {rilievo.civico} (lat:{' '}
            {rilievo?.posizione.latitude}, lng: {rilievo?.posizione.longitude})
          </GridItem>
        </Grid>
        {rilievo.tipo_mezzo !== 'bike_station_based' ? (
          rilievo.tipo_rilievo === 'completo' ? (
            <Grid className="pf-u-mb-sm">
              <GridItem span={1}>
                <b>Area sosta</b>
              </GridItem>
              <GridItem span={11}>
                {rilievo?.area_sosta_data?.properties?.nome}
              </GridItem>
            </Grid>
          ) : null
        ) : (
          <Grid className="pf-u-mb-sm">
            <GridItem span={1}>
              <b>Stazione</b>
            </GridItem>
            <GridItem span={11}>
              {makeBikeStationLabel(rilievo.stazione_data)}
            </GridItem>
          </Grid>
        )}
        {rilievo.segnalazione && (
          <Grid className="pf-u-mb-sm pf-u-display-flex pf-u-align-items-center">
            <div>
              <b>Segnalazione</b>
            </div>
            <div className="pf-u-ml-md">
              <Link to={`/segnalazioni/${rilievo.segnalazione}`}>
                # {rilievo.segnalazione}
              </Link>
            </div>
          </Grid>
        )}
      </div>

      <div className="pf-u-mb-md">
        <MapContainer
          scrollWheelZoom={false}
          style={{ height: 500, width: '100%' }}
          center={
            rilievo?.posizione
              ? [rilievo.posizione.latitude, rilievo.posizione.longitude]
              : undefined
          }
          zoom={13}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {rilievo?.posizione && (
            <Marker
              position={[
                rilievo.posizione.latitude,
                rilievo.posizione.longitude,
              ]}
              icon={DefaultIcon}
            >
              <Popup>{rilievo?.via_data.nome}</Popup>
            </Marker>
          )}
        </MapContainer>
      </div>
      <div>
        <Title headingLevel="h4" size="lg" className="pf-u-mb-md">
          Mezzo
        </Title>
        <Grid className="pf-u-mb-sm">
          <GridItem span={1}>
            <b>Operatore</b>
          </GridItem>
          <GridItem
            span={11}
            className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
          >
            {rilievo?.operatore_data.nome}
            {editable && (
              <EditAltIcon
                onClick={() => {
                  openModalOperatore({
                    operatore: rilievo.operatore,
                    tipo_mezzo: rilievo.tipo_mezzo,
                  })
                }}
                className="cursor-pointer pf-u-active-color-100 pf-u-ml-md"
                size="sm"
              />
            )}
          </GridItem>
        </Grid>
        <Grid className="pf-u-mb-sm">
          <GridItem
            span={1}
            className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
          >
            <b>Mezzo</b>
          </GridItem>
          <GridItem
            span={2}
            className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
          >
            <Text className="pf-u-mr-md">
              {TIPI_MEZZO[rilievo?.tipo_mezzo]}
            </Text>
          </GridItem>
          <GridItem span={1}>
            <img
              onClick={() =>
                openImage({
                  caption: 'Immagine mezzo',
                  src: rilievo?.foto_mezzo,
                })
              }
              className="thumbnail-rilievo cursor-pointer"
              src={rilievo?.foto_mezzo}
              alt="foto mezzo"
            ></img>
          </GridItem>
          {rilievo.foto_stazione && (
            <GridItem span={1}>
              <img
                onClick={() =>
                  openImage({
                    caption: 'Immagine stazione',
                    src: rilievo?.foto_stazione,
                  })
                }
                className="thumbnail-rilievo cursor-pointer"
                src={rilievo?.foto_stazione}
                alt="foto stazione"
              ></img>
            </GridItem>
          )}
        </Grid>
        <Grid className="pf-u-mb-sm">
          <GridItem
            span={1}
            className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
          >
            <b>Id/targa</b>
          </GridItem>
          <GridItem
            span={2}
            className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
          >
            <Text className="pf-u-mr-md">{rilievo?.identificativo_mezzo}</Text>
            {editable && (
              <EditAltIcon
                onClick={() => {
                  openModalIdentificativo({
                    identificativo_mezzo: rilievo?.identificativo_mezzo,
                  })
                }}
                className="cursor-pointer pf-u-active-color-100"
                size="sm"
              />
            )}
          </GridItem>
          <GridItem
            span={1}
            className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
          >
            <img
              className="thumbnail-rilievo cursor-pointer"
              onClick={() =>
                openImage({
                  caption: 'Immagine identificativo mezzo',
                  src: rilievo?.foto_identificativo_mezzo,
                })
              }
              src={rilievo?.foto_identificativo_mezzo}
              alt="foto identificativo mezzo"
            ></img>
          </GridItem>
          {rilievo?.foto_identificativo_mezzo_aggiuntivo && (
            <GridItem
              span={1}
              className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
            >
              <img
                className="thumbnail-rilievo cursor-pointer"
                onClick={() =>
                  openImage({
                    caption: 'Immagine identificativo mezzo aggiuntivo',
                    src: rilievo?.foto_identificativo_mezzo_aggiuntivo,
                  })
                }
                src={rilievo?.foto_identificativo_mezzo_aggiuntivo}
                alt="foto identificativo mezzo aggiuntivo"
              ></img>
            </GridItem>
          )}
          <GridItem
            span={3}
            className="pf-u-display-flex pf-u-flex-direction-row pf-u-align-items-center"
          >
            {rilievo.mezzo_in_flotta
              ? 'Mezzo in flotta'
              : 'Mezzo non in flotta'}
            {rilievo.data_aggiornamento_flotta
              ? ` al ${dayjs(rilievo.data_aggiornamento_flotta).format(
                  'DD/MM/YYYY'
                )}`
              : null}
          </GridItem>
        </Grid>
      </div>
    </div>
  )
}
