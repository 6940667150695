import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const AreePedonaleGeo = rj()
  .mutations({
    importAreePedonali: {
      effect: (t) => (data) => {
        const formData = new FormData()
        formData.append('zip_file', data.zip)
        return api.auth(t).post('/api/aree-pedonali/import', formData)
      },
      updater: 'updateData',
    },
  })
  .effect({
    name: 'AreaPedonaleGeo',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/aree-pedonali', params),
  })

export function useAreePedonaliGeo(params) {
  return useRunRj(AreePedonaleGeo, [params], false)
}
