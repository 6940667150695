import { rj, useRunRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

const OperatoriTipiMezziListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .effect({
    name: 'OperatoriTipiMezziList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/operatori-tipi-mezzo-dashboard', params),
  })

export function useOperatoriTipiMezziList(params) {
  return useRunRj(OperatoriTipiMezziListState, [params], false)
}

const OperatoriTipiMezzoFlatState = rj().effect({
  name: 'OperatoriTipiMezzoFlat',
  effectCaller: 'configured',
  effect: (t) => (tipoMezzo) =>
    api.auth(t).get('/api/operatori-tipi-mezzo', {
      tipo_mezzo: tipoMezzo,
    }),
})

export function useOperatoriTipiMezzoFlat(tipoMezzo) {
  return useRunRj(OperatoriTipiMezzoFlatState, [tipoMezzo])
}

const OperatoriTipiMezzoAdminListState = rj()
  .mutations({
    addOperatoreTipoMezo: {
      effect: (t) => (data) =>
        api.auth(t).post('/api/operatori-tipi-mezzo-admin', data),
      updater: (s) => s,
    },
    patchOperatoreTipoMezzo: {
      effect: (t) => (id, data) =>
        api.auth(t).patch(`/api/operatori-tipi-mezzo-admin/${id}`, data),
      updater: (state, o) => ({
        ...state,
        data: state.data.map((s) => (s.id === o.id ? o : s)),
      }),
    },
  })
  .effect({
    name: 'OperatoriTipiMezzoAdminList',
    effectCaller: 'configured',
    effect: (t) => (params) =>
      api.auth(t).get('/api/operatori-tipi-mezzo-admin', params),
  })

export function useOperatoriTipiMezzoAdminList(params) {
  return useRunRj(OperatoriTipiMezzoAdminListState, [params], false)
}

const OperatoreTipoMezzoDetailState = rj()
  .mutations({
    importFlotta: {
      effect: (t) => (id, data) => {
        const fd = new FormData()
        fd.append('file', data.file)
        fd.append('data', data.data)
        return api
          .auth(t)
          .post(`/api/operatori-tipi-mezzo-dashboard/${id}/import-flotta`, fd)
      },
      updater: 'updateData',
    },
    patchOperatoreTipoMezzo: {
      effect: (t) => (id, data) => {
        return api
          .auth(t)
          .patch(`/api/operatori-tipi-mezzo-dashboard/${id}`, data)
      },
      updater: 'updateData',
    },
  })
  .effect({
    name: 'OperatoreTipoMezzoDetail',
    effectCaller: 'configured',
    effect: (t) => (id) =>
      api.auth(t).get(`/api/operatori-tipi-mezzo-dashboard/${id}`),
  })

export function useOperatoreTipoMezzoDetail(id) {
  return useRunRj(OperatoreTipoMezzoDetailState, [id])
}

const OperatoriListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .mutations({
    addOperatore: {
      effect: (t) => (data) => api.auth(t).post('/api/operatori', data),
      updater: (s) => s,
    },
  })
  .effect({
    name: 'OperatoriTipiMezziList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/operatori', params),
  })

export function useOperatoriList(params) {
  return useRunRj(OperatoriListState, [params], false)
}

const OperatoreDetailState = rj()
  .mutations({
    patchOperatore: {
      effect: (t) => (id, data) =>
        api.auth(t).patch(`/api/operatori/${id}`, data),
      updater: 'updateData',
    },
  })
  .effect({
    name: 'OperatoreDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/api/operatori/${id}`),
  })

export function useOperatoreDetail(id) {
  return useRunRj(OperatoreDetailState, [id])
}
