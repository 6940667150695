export function computeDisabled(values, field) {
  let enabled = true
  if (field.enabled) {
    enabled = Object.keys(field.enabled).every((k) => {
      const allowList = field.enabled[k]
      if (Array.isArray(allowList)) {
        return allowList.includes(values[k])
      } else {
        return true
      }
    })
  }
  if (enabled && field.disabled) {
    enabled = !Object.keys(field.disabled).every((k) => {
      const allowList = field.disabled[k]
      if (Array.isArray(allowList)) {
        return allowList.includes(values[k])
      } else {
        return true
      }
    })
  }
  return !enabled
}

export function normalizeSchemaEnabler(schedaConfig) {
  if (!schedaConfig.ui_schema) {
    return schedaConfig.schema
  }
  if (!schedaConfig.ui_schema.enabler) {
    return schedaConfig.schema
  }
  let normSchema = [...schedaConfig.schema]
  Object.keys(schedaConfig.ui_schema.enabler).forEach((fieldName) => {
    normSchema = normSchema.map((field) => {
      if (field.name === fieldName) {
        return field
      }
      return {
        ...field,
        enabled: {
          [fieldName]: schedaConfig.ui_schema.enabler[fieldName],
          ...field.enabled,
        },
      }
    })
  })
  return normSchema
}

export function normalizeSchema(schedaConfig) {
  return normalizeSchemaEnabler(schedaConfig)
}

export function groupBySchema(schedaConfig) {
  if (!schedaConfig.ui_schema) {
    return [
      {
        name: '__nogroup:1',
        label: null,
        fields: schedaConfig.schema,
      },
    ]
  }
  const groups = []
  let noGroupIndex = 0
  const groupsAtIndex = {}
  schedaConfig.schema.forEach((field) => {
    if (field.groupname) {
      if (groupsAtIndex[field.groupname]) {
        const gIndex = groupsAtIndex[field.groupname]
        groups[gIndex].fields.push(field)
      } else {
        groups.push({
          name: field.groupname,
          label: schedaConfig.ui_schema.groups[field.groupname],
          fields: [field],
        })
        groupsAtIndex[field.groupname] = groups.length - 1
      }
    } else {
      if (
        groups.length === 0 ||
        groups[groups.length - 1].name.indexOf('__nogroup') !== 0
      ) {
        noGroupIndex++
        groups.push({
          name: `__nogroup:${noGroupIndex}`,
          label: null,
          fields: [field],
        })
      } else {
        groups[groups.length - 1].fields.push(field)
      }
    }
  })
  return groups
}
