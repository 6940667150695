import { Title } from '@patternfly/react-core'

function SectionHeader({
  title,
  topLeft,
  topRight,
  subTitle,
  bottomLeft,
  bottomRight,
  centerRight,
  centerLeft,
  bottomPadded = false
}) {
  return (
    <div className={`pf-u-background-color-100 ${bottomPadded ? 'pf-u-pb-md' : ''}`}>
      <div className="pf-u-display-flex pf-u-flex-row pf-u-justify-content-space-between pf-u-align-items-center">
        <div>{topLeft}</div>
        <div>{topRight}</div>
      </div>
      <div className="pf-u-display-flex pf-u-flex-row  pf-u-justify-content-space-between pf-u-mt-md">
        {centerLeft && <div>{centerLeft}</div>}
        <div className="pf-u-display-flex pf-u-align-items-flex-end">
          <Title headingLevel="h1" size="4xl">
            {title}
          </Title>
          <div className="pf-u-ml-xl pf-u-mb-xs">{subTitle}</div>
        </div>
        <div>{centerRight}</div>
      </div>
      <div className="pf-u-display-flex pf-u-flex-row pf-u-justify-content-space-between pf-u-mt-md">
        <div>{bottomLeft}</div>
        <div>{bottomRight}</div>
      </div>
    </div>
  )
}

export default SectionHeader
