import {
  Breadcrumb,
  Button,
  Form,
  Modal,
  ModalBoxBody,
  ModalBoxFooter,
  ModalVariant,
  Pagination,
  CalendarMonth,
} from '@patternfly/react-core'
import * as Yup from 'yup'
import {
  TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { EditAltIcon } from '@patternfly/react-icons'
import dayjs from 'dayjs'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import FullPage from '../../components/FullPage'
import ScrollableContent from '../../components/ScrollableContent'
import SearchBar from '../../components/SearchBar'
import SectionHeader from '../../components/SectionHeader'
import { PAGE_SIZE, TIPI_MEZZO } from '../../consts'
import { useMezziList } from '../../hooks/mezzi'
import { useOperatoreTipoMezzoDetail } from '../../hooks/operatori'
import { useTableSorter } from '../../hooks/sorter'
import { Field, Formik } from 'formik'
import keyBy from 'lodash/keyBy'
import {
  DatePickerField,
  FileUploadField,
  InputField,
  transformErrorForForm,
} from '../../components/Form'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useEffect, useMemo, useState } from 'react'
import { useImportazioniAnagraficaList } from '../../hooks/importazioniAnagrafica'
import { CheckCircleIcon, TimesCircleIcon } from '@patternfly/react-icons'

const ImportValidationSchema = Yup.object().shape({
  file: Yup.mixed().required().label('XLSX'),
  data: Yup.date().typeError('').required().label('Data in Flotta'),
})

const TODAY_STR = dayjs().format('YYYY-MM-DD')

function ImportFormBoxModal({ onClose, save }) {
  return (
    <Formik
      validationSchema={ImportValidationSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{ file: null, data: TODAY_STR }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody>
            <Field
              isRequired
              label={'XLSX'}
              name="file"
              accept={'.xlsx'}
              component={FileUploadField}
            />
            <Field
              className="pf-u-mt-md"
              isRequired
              label={'Data in Flotta'}
              name="data"
              component={DatePickerField}
            />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button
              variant="secondary"
              onClick={onClose}
              isDisabled={isSubmitting}
            >
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Importa
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

const ImportConfigValidationSchema = Yup.object().shape({
  colonna_identificativo_mezzo: Yup.string()
    .required()
    .label('Colonna identificativo mezzo'),
  colonna_stato_mezzo: Yup.string().label('Colonna stato mezzo'),
})

function ImportConfigFormBoxModal({ onClose, save, config }) {
  return (
    <Formik
      validationSchema={ImportConfigValidationSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={{
        colonna_identificativo_mezzo: config.colonna_identificativo_mezzo,
        colonna_stato_mezzo: config.colonna_stato_mezzo,
        colonna_gestore: config.colonna_gestore,
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <ModalBoxBody className="pf-c-form">
            <Field
              isRequired
              label={'Colonna identificativo mezzo'}
              name="colonna_identificativo_mezzo"
              component={InputField}
            />
            <Field
              label={'Colonna stato mezzo'}
              name="colonna_stato_mezzo"
              component={InputField}
            />
            <Field
              label={'Colonna gestore'}
              name="colonna_gestore"
              component={InputField}
            />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button
              variant="secondary"
              onClick={onClose}
              isDisabled={isSubmitting}
            >
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}

export default function OperatoreTipoMezzoDetail() {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const [
    { data: operatoreTipoMezzo },
    { importFlotta, patchOperatoreTipoMezzo },
  ] = useOperatoreTipoMezzoDetail(id)

  const [
    { data: importazioniAnagraica },
    { run: refreshImportazioni, removeImportazieAanagrafica },
  ] = useImportazioniAnagraficaList(
    useMemoCompare({
      operatore_tipo_mezzo: id,
    })
  )
  const importazioniByDates = useMemo(() => {
    if (!importazioniAnagraica) {
      return {}
    }
    return keyBy(importazioniAnagraica, 'data')
  }, [importazioniAnagraica])

  const [selectedImportazione, setSelectedImportazione] = useState(null)
  const selectedDate = useMemo(
    () => (selectedImportazione ? new Date(selectedImportazione.data) : null),
    [selectedImportazione]
  )

  useEffect(() => {
    if (
      selectedImportazione === null &&
      importazioniAnagraica &&
      importazioniAnagraica.length > 0
    ) {
      setSelectedImportazione(importazioniAnagraica[0])
    }
  }, [importazioniAnagraica, selectedImportazione])

  const filters = {
    page: searchParams.get('page') ?? 1,
    identificativo: searchParams.get('identificativo') ?? '',
    ordering: searchParams.get('ordering') ?? '',
  }
  const mezziApiFilters = useMemoCompare({
    ...filters,
    importazione_anagrafica: selectedImportazione?.id,
  })

  const [{ data: mezzi }, { clean: cleanMezzi }] = useMezziList(
    selectedImportazione ? mezziApiFilters : null
  )

  const sorter = useTableSorter(filters.ordering, (ordering) =>
    setSearchParams({ ...filters, ordering, page: 1 })
  )

  const [modalImport, modalImportActions] = useModalTrigger()
  const [modalConfig, modalConfigActions] = useModalTrigger()
  const [modalRm, modalRmActions] = useModalTrigger()

  return (
    <FullPage>
      <SectionHeader
        bottomPadded
        centerLeft={
          operatoreTipoMezzo ? (
            <div>
              <div className="pf-u-mb-md pf-u-font-size-sm pf-u-display-flex pf-u-align-items-start">
                <div>
                  <div className="pf-u-mb-xs pf-u-font-size-sm pf-u-display-flex pf-u-align-items-center">
                    <div className="pf-u-mr-md">
                      <span className="pf-u-mr-xs">
                        Colonna identificativo mezzo
                      </span>
                      <code>
                        {operatoreTipoMezzo.colonna_identificativo_mezzo}
                      </code>
                    </div>
                    <div>
                      <span className="pf-u-mr-xs">Colonna stato mezzo</span>
                      <code>{operatoreTipoMezzo.colonna_stato_mezzo}</code>
                    </div>
                  </div>
                  <div>
                    <span className="pf-u-mr-xs">Colonna gestore</span>
                    <code>{operatoreTipoMezzo.colonna_gestore}</code>
                  </div>
                </div>
                <div className="pf-u-ml-md pf-u-mt-xs">
                  <EditAltIcon
                    onClick={() => modalConfigActions.open()}
                    size={'sm'}
                    className="pf-u-active-color-100 cursor-pointer"
                  />
                </div>
              </div>
              <div className="pf-u-display-flex">
                <div>N. MEZZI {mezzi?.pagination?.count ?? ''}</div>
                {selectedImportazione && (
                  <div className="pf-u-ml-xl pf-u-mr-md">
                    Data aggiornamento{' '}
                    {dayjs(selectedImportazione.data).format('DD/MM/YYYY')}
                  </div>
                )}
                <div className="pf-u-display-flex pf-u-ml-md">
                  Integrazione mobishare
                  <div className="pf-u-ml-sm">
                    {operatoreTipoMezzo.id_operatore_mobishare ? (
                      <CheckCircleIcon
                        style={{ color: 'green', cursor: 'pointer' }}
                      />
                    ) : (
                      <TimesCircleIcon
                        style={{ color: 'red', cursor: 'pointer' }}
                      />
                    )}
                    <code>{operatoreTipoMezzo.id_operatore_mobishare}</code>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        }
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/anagrafica-mezzi">
              Anagrafica Mezzi
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/anagrafica-mezzi/${id}`}>
              {operatoreTipoMezzo &&
                `${operatoreTipoMezzo.operatore.nome} ${
                  TIPI_MEZZO[operatoreTipoMezzo.tipo_mezzo]
                }`}
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <>
            <Button
              variant="danger"
              isDisabled={!selectedImportazione}
              className="pf-u-mr-md"
              onClick={() => {
                modalRmActions.open()
              }}
            >
              Rimuovi
            </Button>
            <Button
              onClick={() => {
                modalImportActions.open()
              }}
            >
              Aggiorna
            </Button>
          </>
        }
        bottomLeft={
          <SearchBar
            initialValue={filters.identificativo}
            onChange={(search) =>
              setSearchParams({ ...filters, page: 1, identificativo: search })
            }
          />
        }
      />
      <div
        className="pf-u-display-flex pf-u-flex-direction-row"
        style={{ flex: 1, overflow: 'hidden' }}
      >
        <ScrollableContent>
          <TableComposable isStickyHeader>
            <Thead>
              <Tr>
                <Th sort={sorter('identificativo')}>Identificativo</Th>
                <Th>Status</Th>
                <Th>Status update</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {mezzi &&
                mezzi.list.map((mezzo) => (
                  <Tr key={mezzo.id}>
                    <Td>{mezzo.identificativo}</Td>
                    <Td>{mezzo.status}</Td>
                    <Td>
                      {mezzo.status_change_date &&
                        dayjs(mezzo.status_change_date).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </TableComposable>
          {mezzi?.pagination && (
            <Pagination
              variant="bottom"
              isSticky
              onSetPage={(_, page) => {
                setSearchParams({ ...filters, page })
              }}
              page={+(searchParams.get('page') ?? 1)}
              perPageOptions={[{ title: PAGE_SIZE, value: PAGE_SIZE }]}
              itemCount={mezzi.pagination.count}
              perPage={PAGE_SIZE}
            />
          )}
        </ScrollableContent>
        <div style={{ overflowY: 'auto' }}>
          <CalendarMonth
            weekStart={1}
            date={selectedDate}
            validators={[
              (date) =>
                Boolean(importazioniByDates[dayjs(date).format('YYYY-MM-DD')]),
            ]}
            onChange={(date) => {
              setSelectedImportazione(
                importazioniByDates[dayjs(date).format('YYYY-MM-DD')]
              )
              setSearchParams({ ...filters, page: 1 })
            }}
          />
        </div>
      </div>
      <Modal
        variant={ModalVariant.medium}
        title="Importa flotta mezzi"
        aria-label="import-flotta-mezzi"
        hasNoBodyWrapper
        isOpen={modalImport.isOpen}
        onClose={modalImportActions.close}
      >
        <ImportFormBoxModal
          save={(data) =>
            importFlotta
              .onSuccess(() => {
                refreshImportazioni
                  .onSuccess((data) => {
                    setSelectedImportazione(data[0])
                    setSearchParams({ ...filters, page: 1 })
                  })
                  .run({
                    operatore_tipo_mezzo: id,
                  })
                modalImportActions.close()
              })
              .asPromise(operatoreTipoMezzo.id, data)
          }
          onClose={modalImportActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Modifica configurazione import"
        aria-label="modifica-configurazione-import"
        hasNoBodyWrapper
        isOpen={modalConfig.isOpen}
        onClose={modalConfigActions.close}
      >
        <ImportConfigFormBoxModal
          config={operatoreTipoMezzo}
          save={(data) =>
            patchOperatoreTipoMezzo
              .onSuccess(() => {
                modalConfigActions.close()
              })
              .asPromise(operatoreTipoMezzo.id, data)
          }
          onClose={modalConfigActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Rimuovere anagrafica"
        aria-label="remove-anagrafica"
        className="modal-actions-end"
        isOpen={modalRm.isOpen}
        onClose={modalRmActions.close}
        actions={[
          <Button onClick={modalRmActions.close} variant="secondary">
            Annulla
          </Button>,
          <Button
            onClick={() => {
              removeImportazieAanagrafica
                .onSuccess(() => {
                  setSearchParams({ ...filters, page: 1 })
                  setSelectedImportazione(null)
                  cleanMezzi()
                  modalRmActions.close()
                })
                .run(selectedImportazione.id)
            }}
          >
            Conferma
          </Button>,
        ]}
      >
        {selectedImportazione &&
          `Rimuovere l'anagrafica del ${dayjs(selectedImportazione.data).format(
            'DD/MM/YYYY'
          )}?`}
      </Modal>
    </FullPage>
  )
}
