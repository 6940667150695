import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import RilevatoriList from './RilevatoriList'
import RilevatoreAdd from './RilevatoreAdd'
import RilevatoreDetail from './RilevatoreDetail'

export default function Rilevatori() {
  return (
    <Routes>
      <Route index element={<RilevatoriList />} />
      <Route path="add" element={<RilevatoreAdd />} />
      <Route path=":id" element={<RilevatoreDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
