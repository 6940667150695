export default function ListFiltersOverlay({ children, style, ...props }) {
  return (
    <div
      style={{
        flex: 1,
      }}
      {...props}
    >
      {children}
    </div>
  )
}
