import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  TextInput,
} from '@patternfly/react-core'
import { useAuthActions, useAuthState } from 'use-eazy-auth'

export default function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <div className="pf-u-display-flex pf-u-flex-direction-column pf-u-h-100 pf-u-align-items-center pf-u-justify-content-center">
      <Card style={{ maxWidth: 544, width: '100%' }} className="pf-u-p-xl">
        <CardTitle>Monitoraggio Sharing</CardTitle>
        <CardBody>
          <div className='pf-u-mb-md'>
            <b>Login</b>
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              if (email !== '' && password !== '') {
                login({ email, password })
              }
            }}
          >
            <FormGroup label="Email" fieldId="email" isRequired>
              <TextInput
                type="email"
                id="email"
                value={email}
                onChange={(v) => {
                  setEmail(v)
                  clearLoginError()
                }}
              />
            </FormGroup>
            <FormGroup label="Password" fieldId="password" isRequired>
              <TextInput
                type="password"
                id="password"
                value={password}
                onChange={(v) => {
                  setPassword(v)
                  clearLoginError()
                }}
              />
            </FormGroup>
            <div className="pf-u-my-md pf-u-text-align-right">
              <Link to="/recover">Recupera password</Link>
            </div>
            <Button isDisabled={loginLoading} type="submit">
              Login
            </Button>
            {loginError && (
              <Alert
                variant="danger"
                isInline
                title="Combinazione di email password errata."
              />
            )}
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}
