import { useNavigate } from 'react-router'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import SectionHeader from '../../../components/SectionHeader'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { CheckCircleIcon, ExclamationCircleIcon } from '@patternfly/react-icons'
import {
  Badge,
  Breadcrumb,
  Button,
  Form,
  FormGroup,
  FormSelectOption,
  Pagination,
} from '@patternfly/react-core'
import {
  TableComposable,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from '@patternfly/react-table'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { useRilieviList } from '../../../hooks/rilievo'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import {
  PAGE_SIZE,
  STATI_RILIEVO,
  TIPI_MEZZO,
  TIPI_RILIEVO,
} from '../../../consts'
import SearchBar from '../../../components/SearchBar'
import { useTableSorter } from '../../../hooks/sorter'
import ScrollableContent from '../../../components/ScrollableContent'
import FullPage from '../../../components/FullPage'
import ListFiltersOverlay from '../../../components/ListFiltersOverlay'
import { useMemo, useState } from 'react'
import { Field, Formik, useFormikContext } from 'formik'
import {
  DatePickerField,
  MultiViaAutocomplete,
  SelectField,
  SwitchField,
} from '../../../components/Form/fields'
import { countFilters } from '../../../utils'
import { useOperatoriTipiMezzoFlat } from '../../../hooks/operatori'

function FiltersFormFields({ operatoriTipiMezzo }) {
  const { values } = useFormikContext()

  const tipoMezzoOptions = useMemo(() => {
    let deOptions = [{ value: '', label: 'Tutti' }]
    if (operatoriTipiMezzo) {
      deOptions.push(
        ...uniq(
          operatoriTipiMezzo
            .filter((ot) => {
              if (!values.operatore) {
                return true
              }
              return +ot.operatore.id === +values.operatore
            })
            .map((s) => s.tipo_mezzo)
        ).map((value) => ({
          value,
          label: TIPI_MEZZO[value],
        }))
      )
    }
    return deOptions
  }, [operatoriTipiMezzo, values.operatore])

  const operatoriOptions = useMemo(() => {
    let deOptions = [{ value: '', label: 'Tutti' }]
    if (operatoriTipiMezzo) {
      deOptions.push(
        ...uniqBy(
          operatoriTipiMezzo.filter((ot) => {
            if (!values.tipo_mezzo) {
              return true
            }
            return ot.tipo_mezzo === values.tipo_mezzo
          }),
          (ot) => ot.operatore.id
        ).map((ot) => ({
          value: ot.operatore.id,
          label: ot.operatore.nome,
        }))
      )
    }
    return deOptions
  }, [operatoriTipiMezzo, values.tipo_mezzo])

  return (
    <>
      <div className="pf-u-display-flex pf-u-justify-content-space-between form-group-max-width">
        <Field
          invalidFormatText=""
          label="Dal"
          name="inizio"
          component={DatePickerField}
        />
        <Field
          invalidFormatText=""
          label="Al"
          name="fine"
          component={DatePickerField}
        />
      </div>
      <Field label="Tipo rilievo" name="tipo_rilievo" component={SelectField}>
        <FormSelectOption label={'Tutti'} value={''} />
        {Object.keys(TIPI_RILIEVO)
          .sort()
          .map((s) => (
            <FormSelectOption label={TIPI_RILIEVO[s]} value={s} key={s} />
          ))}
      </Field>
      <Field label="Tipo Mezzo" name="tipo_mezzo" component={SelectField}>
        {tipoMezzoOptions.map((o) => (
          <FormSelectOption label={o.label} value={o.value} key={o.value} />
        ))}
      </Field>
      <Field label="Operatore" name="operatore" component={SelectField}>
        {operatoriOptions.map((o) => (
          <FormSelectOption label={o.label} value={o.value} key={o.value} />
        ))}
      </Field>
      <Field label="Stato" name="stato" component={SelectField}>
        <FormSelectOption label={'Tutti'} value={''} />
        {Object.keys(STATI_RILIEVO)
          .sort()
          .map((s) => (
            <FormSelectOption label={STATI_RILIEVO[s]} value={s} key={s} />
          ))}
      </Field>
      <Field label="Via" name="vie" component={MultiViaAutocomplete} />
      <Field
        label="Upload video completo"
        name="video_ok"
        component={SelectField}
      >
        <FormSelectOption label={'Tutti'} value={''} />
        <FormSelectOption label={'Si'} value={'1'} />
        <FormSelectOption label={'No'} value={'0'} />
      </Field>
      <Field
        label="Mostra rilievi invalidi"
        name="show_invalidi"
        component={SwitchField}
      />
      <Field
        label="Mostra rilievi in report chiusi"
        name="show_in_report_chiusi"
        component={SwitchField}
      />
    </>
  )
}

function FiltersForm({ initialValues, onSubmit, onClose, operatoriTipiMezzo }) {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Form isHorizontal onSubmit={handleSubmit}>
          <FiltersFormFields operatoriTipiMezzo={operatoriTipiMezzo} />
          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between pf-u-mt-md">
              <Button variant="secondary" type="button" onClick={onClose}>
                Annulla
              </Button>
              <Button type="submit">Aggiorna</Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}

export default function RilieviList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const filters = useMemoCompare({
    page: searchParams.get('page') ?? 1,
    search: searchParams.get('search') ?? '',
    ordering: searchParams.get('ordering') ?? '',
    tipo_mezzo: searchParams.get('tipo_mezzo') ?? '',
    inizio: searchParams.get('inizio') ?? '',
    show_invalidi: searchParams.get('show_invalidi') ?? '',
    show_in_report_chiusi: searchParams.get('show_in_report_chiusi') ?? '',
    fine: searchParams.get('fine') ?? '',
    vie: searchParams.getAll('vie') ?? [],
    operatore: searchParams.get('operatore') ?? '',
    stato: searchParams.get('stato') ?? '',
    video_ok: searchParams.get('video_ok') ?? '',
    tipo_rilievo: searchParams.get('tipo_rilievo') ?? '',
  })
  const sorter = useTableSorter(filters.ordering, (ordering) =>
    setSearchParams({ ...filters, page: 1, ordering })
  )
  const [{ data: operatoriTipiMezzo }] = useOperatoriTipiMezzoFlat()
  const [{ data, pending, error }, { getToken }] = useRilieviList(filters)
  const [openFilters, setOpenFilters] = useState(false)
  const filtersCount =
    countFilters(filters, [
      'tipo_mezzo',
      'tipo_rilievo',
      'inizio',
      'fine',
      'operatore',
      'video_ok',
      'stato',
    ]) +
    (filters.vie.length > 0 ? 1 : 0) +
    (Boolean(+filters.show_invalidi) ? 1 : 0) +
    (Boolean(+filters.show_in_report_chiusi) ? 1 : 0)

  return (
    <FullPage>
      <SectionHeader
        bottomPadded
        title="Rilievi"
        subTitle={
          <span>
            {data ? `${data.pagination.count} totali` : null}
            {filtersCount > 0 && (
              <span
                onClick={() => setSearchParams({})}
                className="pf-u-ml-md pf-u-active-color-100 mimic-link"
              >
                Cancella filtri
              </span>
            )}
          </span>
        }
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/rilievi">Rilievi</BreadcrumbItemLink>
          </Breadcrumb>
        }
        bottomLeft={
          <SearchBar
            initialValue={filters.search}
            onChange={(search) =>
              setSearchParams({ ...filters, page: 1, search })
            }
          />
        }
        bottomRight={
          <Button
            isDisabled={openFilters}
            onClick={() => setOpenFilters(true)}
            variant="secondary"
          >
            {filtersCount === 0 && 'Filtra'}
            {filtersCount > 0 && (
              <>
                Filtri applicati
                <Badge className="pf-u-ml-sm">{filtersCount}</Badge>
              </>
            )}
          </Button>
        }
        centerRight={
          <Button
            onClick={() => {
              getToken
                .onSuccess((link) => {
                  window.open(link, '_blank')
                })
                .run(filters)
            }}
          >
            Esporta
          </Button>
        }
      />
      <ScrollableContent>
        {openFilters && (
          <ListFiltersOverlay className="pf-u-mt-xl">
            <FiltersForm
              operatoriTipiMezzo={operatoriTipiMezzo}
              initialValues={{
                tipo_mezzo: filters.tipo_mezzo,
                tipo_rilievo: filters.tipo_rilievo,
                inizio: filters.inizio,
                fine: filters.fine,
                vie: filters.vie,
                operatore: filters.operatore,
                stato: filters.stato,
                show_invalidi: Boolean(+filters.show_invalidi),
                show_in_report_chiusi: Boolean(+filters.show_in_report_chiusi),
                video_ok: filters.video_ok,
              }}
              onClose={() => setOpenFilters(false)}
              onSubmit={(values) => {
                setSearchParams({
                  ...filters,
                  page: 1,
                  tipo_mezzo: values.tipo_mezzo,
                  tipo_rilievo: values.tipo_rilievo,
                  inizio: values.inizio,
                  fine: values.fine,
                  vie: values.vie,
                  operatore: values.operatore,
                  stato: values.stato,
                  show_invalidi: values.show_invalidi ? 1 : 0,
                  show_in_report_chiusi: values.show_in_report_chiusi ? 1 : 0,
                  video_ok: values.video_ok,
                })
                setOpenFilters(false)
              }}
            />
          </ListFiltersOverlay>
        )}
        {!openFilters && (
          <>
            <TableComposable isStickyHeader>
              <Thead>
                <Tr>
                  <Th sort={sorter('id')}>Rilievo</Th>
                  <Th sort={sorter('tipo_rilievo')}>Tipo</Th>
                  <Th sort={sorter('video_ok')}>Video</Th>
                  <Th sort={sorter('rilevatore')}>Rilevatore</Th>
                  <Th sort={sorter('tipo_mezzo')}>Mezzo</Th>
                  <Th sort={sorter('operatore')}>Operatore</Th>
                  <Th sort={sorter('via')}>Via</Th>
                  <Th sort={sorter('dataora_inizio')}>Data e ora</Th>
                  <Th sort={sorter('stato')}>Stato</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.list.map((rilievo) => (
                    <Tr
                      className={classNames({
                        'pf-u-background-color-danger':
                          rilievo.motivazione_invalido,
                      })}
                      isHoverable
                      key={rilievo.id}
                      onRowClick={() => navigate(`/rilievi/${rilievo.id}`)}
                    >
                      <Td>{rilievo.id}</Td>
                      <Td>{TIPI_RILIEVO[rilievo.tipo_rilievo]}</Td>
                      <Td>
                        {rilievo.video_ok ? (
                          <CheckCircleIcon color="green" />
                        ) : (
                          <ExclamationCircleIcon color="red" />
                        )}
                      </Td>
                      <Td>{rilievo.rilevatore_data.user.name}</Td>
                      <Td>{TIPI_MEZZO[rilievo.tipo_mezzo]}</Td>
                      <Td>{rilievo.operatore_data.nome}</Td>
                      <Td>
                        {rilievo.via_data.nome} {rilievo.civico}
                      </Td>
                      <Td>
                        {rilievo.dataora_inizio
                          ? dayjs(rilievo.dataora_inizio).format(
                              'DD/MM/YYYY HH:mm'
                            )
                          : null}
                      </Td>
                      <Td>{STATI_RILIEVO[rilievo.stato]}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </TableComposable>
            {data?.pagination && (
              <Pagination
                variant="bottom"
                isSticky
                onSetPage={(_, page) => {
                  setSearchParams({ ...filters, page })
                }}
                page={+(searchParams.get('page') ?? 1)}
                perPageOptions={[{ title: PAGE_SIZE, value: PAGE_SIZE }]}
                itemCount={data.pagination.count}
                perPage={PAGE_SIZE}
              />
            )}
          </>
        )}
      </ScrollableContent>
    </FullPage>
  )
}
