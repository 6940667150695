import FullPage from '../../components/FullPage'
import { Breadcrumb, Card, CardBody } from '@patternfly/react-core'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import SectionHeader from '../../components/SectionHeader'
import { useChangePassword } from '../../hooks/profile'
import ChangePasswordForm from '../../components/ChangePasswordForm'

export default function ChangePassword() {
  const { run: changePassword } = useChangePassword()[1]

  return (
    <FullPage>
      <SectionHeader
        title={'Cambia Password'}
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/profile">Profilo</BreadcrumbItemLink>
            <BreadcrumbItemLink to="/profile/change-password">
              Cambia Password
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <Card>
        <CardBody>
          <ChangePasswordForm save={(data) => changePassword.asPromise(data)} />
        </CardBody>
      </Card>
    </FullPage>
  )
}
