import { Route, Routes } from 'react-router'
import { useAuthUser } from 'use-eazy-auth'
import PageNotFound from '../../components/PageNotFound'
import ChangePassword from './ChangePassword'
import EditProfile from './EditProfile'
import ViewProfile from './ViewProfile'

export default function Profile() {
  const { user } = useAuthUser()

  return (
    <Routes>
      <Route index element={<ViewProfile />} />
      {(user.rilevatore || user.amministratore) && (
        <Route path="edit" element={<EditProfile />} />
      )}
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
