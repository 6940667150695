import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  transformErrorForForm,
  TextAreaField,
  SelectField,
} from '../../../components/Form'
import {
  Button,
  Form,
  FormSelectOption,
  ModalBoxBody,
  ModalBoxFooter,
} from '@patternfly/react-core'

const OperatoreNoteSchema = Yup.object().shape({
  note: Yup.string().label('Note').required(),
  operatore: Yup.string().label('Operatore').required(),
})

const INITIAL_VALUES = {
  note: '',
  operatore: '',
}

export default function NoteOperatoreFormBoxModal({
  onClose,
  save,
  noteOperatore,
  operatoriOptions,
}) {
  return (
    <Formik
      validationSchema={OperatoreNoteSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={noteOperatore ?? INITIAL_VALUES}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody className='pf-c-form'>
            <Field
              isRequired
              label={'Operatore'}
              name="operatore"
              component={SelectField}
            >
              <FormSelectOption
                label={'-- Seleziona operatore --'}
                value={''}
              />
              {operatoriOptions.map((o) => (
                <FormSelectOption label={o.nome} value={o.id} key={o.id} />
              ))}
            </Field>
            <Field
              isRequired
              label={'Note'}
              name="note"
              component={TextAreaField}
            />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button
              variant="secondary"
              onClick={onClose}
              isDisabled={isSubmitting}
            >
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Salva
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}
