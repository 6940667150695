import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Outlet } from 'react-router'
import Auth from './Auth'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import PageNotFound from './components/PageNotFound'
import Videos from './pages/Videos'
import Profile from './pages/Profile'
import ActivateUser from './pages/ActivateUser'
import { RequireAuth, RequireGuest } from './lib'
import Layout from './components/Layout/Layout'
import MapPage from './pages/MapPage'
import Rilevatori from './pages/Rilevatori'
import Rilievi from './pages/Rilievi'
import ReportsCompleti from './pages/ReportsCompleti'
import ReportsRapidi from './pages/ReportsRapidi'
import Segnalazioni from './pages/Segnalazioni'
import Amministratori from './pages/Amministratori'
import AnagraficaMezzi from './pages/AnagraficaMezzi'
import Recover from './pages/Recover'
import Operatori from './pages/Operatori'
import AccountOperatore from './pages/AccountOperatore'
import Reset from './pages/Reset'
import { MultiUploaderProviderRilevatore } from './hooks/video'
import RunTimeErrorFallback from './components/RunTimeErrorFallback'

function amministratoreAccess(user) {
  if (!user.amministratore) {
    if (user.rilevatore) {
      return '/videos'
    } else {
      return '/segnalazioni'
    }
  }
}

function rilevatoreAccess(user) {
  if (!user.rilevatore) {
    if (user.amministratore) {
      return '/'
    } else {
      return '/segnalazioni'
    }
  }
}

function accountOperatoreAccess(user) {
  if (!user.account_operatore && !user.amministratore) {
    return '/videos'
  }
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<RunTimeErrorFallback />}>
      <Auth>
        <MultiUploaderProviderRilevatore>
          <Router>
            <Routes>
              {/* GUEST */}
              <Route
                path="/"
                element={
                  <RequireGuest>
                    <Outlet />
                  </RequireGuest>
                }
              >
                <Route path="login" element={<Login />} />
                <Route path="activate/:token" element={<ActivateUser />} />
                <Route path="recover" element={<Recover />} />
                <Route path="reset/:token" element={<Reset />} />
              </Route>
              {/* AUTH COMMON */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route path="profile/*" element={<Profile />} />
              </Route>
              {/* AMMINISTRATORI */}
              <Route
                path="/"
                element={
                  <RequireAuth redirectTest={amministratoreAccess}>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route index element={<Dashboard />} />
                <Route path="rilievi/*" element={<Rilievi />} />
                <Route path="operatori/*" element={<Operatori />} />
                <Route
                  path="reports-completi/*"
                  element={<ReportsCompleti />}
                />
                <Route path="reports-rapidi/*" element={<ReportsRapidi />} />
                <Route path="rilevatori/*" element={<Rilevatori />} />
                <Route path="amministratori/*" element={<Amministratori />} />
                <Route
                  path="anagrafica-mezzi/*"
                  element={<AnagraficaMezzi />}
                />
                <Route
                  path="account-operatore/*"
                  element={<AccountOperatore />}
                />
                <Route path="map" element={<MapPage />} />
              </Route>
              {/* ACCOUNT OPERATORE */}
              <Route
                path="/"
                element={
                  <RequireAuth redirectTest={accountOperatoreAccess}>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route path="segnalazioni/*" element={<Segnalazioni />} />
              </Route>
              {/* RILEVATORI */}
              <Route
                path="/"
                element={
                  <RequireAuth redirectTest={rilevatoreAccess}>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route path="videos" element={<Videos />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Router>
        </MultiUploaderProviderRilevatore>
      </Auth>
    </Sentry.ErrorBoundary>
  )
}

export default App
