import { rj, useRunRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

const RilievoListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .mutations({
    getToken: {
      effect: (t) => (params) =>
        api.auth(t).get('/api/rilievi/token', params),
      updater: (s) => s,
    },
  })
  .effect({
    name: 'RilievoList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/rilievi', params),
  })

const RilievoDetailState = rj()
  .mutations({
    invalidaRilievo: {
      updater: (s) => s,
      effect: (t) => (id, data) =>
        api.auth(t).post(`/api/rilievi/${id}/invalida/`, data),
    },
    modificaAmministratoreTableQuestion: {
      updater: 'updateData',
      effect: (t) => (id, data) =>
        api
          .auth(t)
          .post(
            `/api/rilievi/${id}/modifica-amministratore-tablequestion/`,
            data
          ),
    },
    spostaImmagineAmministratoreTableQuestion: {
      updater: 'updateData',
      effect: (t) => (id, data) =>
        api
          .auth(t)
          .post(
            `/api/rilievi/${id}/sposta-immagine-amministratore-tablequestion/`,
            data
          ),
    },
    rimuoviImmagineAmministratoreTableQuestion: {
      updater: 'updateData',
      effect: (t) => (id, data) =>
        api
          .auth(t)
          .post(
            `/api/rilievi/${id}/rimuovi-immagine-amministratore-tablequestion/`,
            data
          ),
    },
    modificaAmministratoreField: {
      updater: 'updateData',
      effect: (t) => (id, data) =>
        api
          .auth(t)
          .post(`/api/rilievi/${id}/modifica-amministratore-field/`, data),
    },
    modificaAmministratoreTesta: {
      updater: 'updateData',
      effect: (t) => (id, data) =>
        api
          .auth(t)
          .patch(`/api/rilievi/${id}/modifica-amministratore-testa/`, data),
    },
    forceEditableAmministratore: {
      updater: 'updateData',
      effect: (t) => (id) =>
        api.auth(t).post(`/api/rilievi/${id}/force-editable/`),
    },
  })
  .effect({
    name: 'RilievoDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/api/rilievi/${id}`),
  })

export function useRilievoDetail(id) {
  return useRunRj(RilievoDetailState, [id])
}

export function useRilieviList(params) {
  return useRunRj(RilievoListState, [params], false)
}

const RilievoLogsState = rj().effect({
  name: 'RilievoLogs',
  effectCaller: 'configured',
  effect: (t) => (id) => api.auth(t).get(`/api/rilievi/${id}/logs`),
})

export function useRilievoLogs(id) {
  return useRunRj(RilievoLogsState, [id])
}

const RilievoVideoListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .actions(() => ({
    addVideo: (video) => ({
      type: 'ADD_VIDEO',
      payload: video,
    }),
  }))
  .composeReducer((state, action) => {
    if (action.type === 'ADD_VIDEO') {
      return {
        ...state,
        data: {
          ...state.data,
          list: state.data.list.map((rilievo) => {
            if (action.payload.rilievo === rilievo.id) {
              return {
                ...rilievo,
                videos: rilievo.videos.concat(action.payload),
              }
            }
            return rilievo
          }),
        },
      }
    }
    return state
  })
  .mutations({
    videoOk: {
      effect: (t) => (id) => api.auth(t).post(`/api/rilievi-video/${id}/ok`),
      updater: (s) => s,
    },
    removeVideo: {
      effect: (t) => (id, videoId) =>
        api.auth(t).delete(`/api/rilievi-video/${id}/remove-video/${videoId}`),
      updater: 'updateItem',
    },
  })
  .computed({
    list: 'getList',
    pagination: 'getPagination',
    loading: 'isLoading',
    error: 'getError',
  })
  .effect({
    name: 'RilievoVideoList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/rilievi-video', params),
  })

export function useRilieviVideoList(params) {
  return useRunRj(RilievoVideoListState, [params], false)
}
