import { Breadcrumb } from '@patternfly/react-core'
import { useNavigate } from 'react-router'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import RilevatoreForm from '../../../components/RilevatoreForm'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import { useAddRilevatore } from '../../../hooks/rilevatore'

export default function RilevatoreAdd() {
  const { run: addRilevatore } = useAddRilevatore()[1]
  const navigate = useNavigate()
  return (
    <FullPage>
      <SectionHeader
        title="Nuovo rilevatore"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/rilevatori">
              Gestione rilevatori
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to="/rilevatori/add">Nuovo</BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <ScrollableContent>
        <RilevatoreForm
          save={(data) =>
            addRilevatore
              .onSuccess((created) => navigate(`/rilevatori/${created.id}`))
              .asPromise(data)
          }
        />
      </ScrollableContent>
    </FullPage>
  )
}
