import { rj, useRunRj, useRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

const ReportListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .mutations({
    getToken: {
      effect:
        (t) =>
        (params = {}) =>
          api.auth(t).get('/api/reports-rapidi/token', params),
      updater: (s) => s,
    },
  })
  .effect({
    name: 'ReportList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/reports-rapidi', params),
  })

const AddReportState = rj().effect({
  name: 'AddReport',
  effectCaller: 'configured',
  effect: (t) => (data) => {
    return api.auth(t).post('/api/reports-rapidi', data)
  },
})

const ReportDetailState = rj()
  .mutations({
    updateReport: {
      updater: 'updateData',
      effect: (t) => (id, data) => {
        return api.auth(t).put(`/api/reports-rapidi/${id}/`, data)
      },
    },
    chiudiReport: rj.mutation.single({
      updater: 'updateData',
      effect: (t) => (id) => {
        return api.auth(t).post(`/api/reports-rapidi/${id}/chiudi/`)
      },
    }),
    removeReport: rj.mutation.single({
      updater: (s) => s,
      effect: (t) => (id) => {
        return api.auth(t).delete(`/api/reports-rapidi/${id}`)
      },
    }),
    addNota: {
      updater: (state, opNote) => ({
        ...state,
        data: {
          ...state.data,
          note_operatori: state.data.note_operatori.concat(opNote),
        },
      }),
      effect: (t) => (data) =>
        api.auth(t).post('/api/notes-report-rapido', data),
    },
    updateNota: {
      updater: (state, opNote) => ({
        ...state,
        data: {
          ...state.data,
          note_operatori: state.data.note_operatori.map((op) =>
            op.id === opNote.id ? opNote : op
          ),
        },
      }),
      effect: (t) => (id, data) =>
        api.auth(t).put(`/api/notes-report-rapido/${id}`, data),
    },
    removeNota: {
      updater: (state, id) => ({
        ...state,
        data: {
          ...state.data,
          note_operatori: state.data.note_operatori.filter(
            (op) => op.id !== id
          ),
        },
      }),
      effect: (t) => (id) =>
        api
          .auth(t)
          .mapResponse(() => id)
          .delete(`/api/notes-report-rapido/${id}`),
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    closing: (s) => s.mutations.chiudiReport.pending,
  })
  .effect({
    name: 'ReportDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/api/reports-rapidi/${id}`),
  })

const ReportRilieviState = rj().effect({
  name: 'ReportRilievi',
  effectCaller: 'configured',
  effect: (t) => (id) => {
    return api.auth(t).get(`/api/reports-rapidi/${id}/rilievi`)
  },
})

export function useReportRapidoDetail(id) {
  return useRunRj(ReportDetailState, [id])
}

export function useAddReportRapido() {
  return useRj(AddReportState)
}

export function useReportsRapidiList(params) {
  return useRunRj(ReportListState, [params], false)
}

export function useReportRapidoRilievi(id) {
  return useRunRj(ReportRilieviState, [id])
}
