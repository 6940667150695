import { useNavigate } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import SectionHeader from '../../../components/SectionHeader'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import {
  Badge,
  Breadcrumb,
  Button,
  Form,
  FormGroup,
  FormSelectOption,
  Pagination,
} from '@patternfly/react-core'
import {
  TableComposable,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from '@patternfly/react-table'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import { PAGE_SIZE, TIPI_MEZZO } from '../../../consts'
import SearchBar from '../../../components/SearchBar'
import { useTableSorter } from '../../../hooks/sorter'
import ScrollableContent from '../../../components/ScrollableContent'
import FullPage from '../../../components/FullPage'
import ListFiltersOverlay from '../../../components/ListFiltersOverlay'
import { useMemo, useState } from 'react'
import { Field, Formik } from 'formik'
import { DatePickerField, SelectField } from '../../../components/Form/fields'
import { countFilters } from '../../../utils'
import { useReportsRapidiList } from '../../../hooks/reportsRapidi'
import dayjs from 'dayjs'

export default function ReportsList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const filters = useMemoCompare({
    page: searchParams.get('page') ?? 1,
    search: searchParams.get('search') ?? '',
    ordering: searchParams.get('ordering') ?? '',
    tipo_mezzo: searchParams.get('tipo_mezzo') ?? '',
    inizio: searchParams.get('inizio') ?? '',
    fine: searchParams.get('fine') ?? '',
    stato: searchParams.get('stato') ?? '',
  })
  const sorter = useTableSorter(filters.ordering, (ordering) =>
    setSearchParams({ ...filters, page: 1, ordering })
  )
  const dataFilters = useMemo(
    () => ({
      ...filters,
    }),
    [filters]
  )
  const [{ data, pending, error }, { getToken }] =
    useReportsRapidiList(dataFilters)
  const [openFilters, setOpenFilters] = useState(false)
  const filtersCount = countFilters(filters, [
    'tipo_mezzo',
    'inizio',
    'fine',
    'stato',
  ])

  return (
    <FullPage>
      <SectionHeader
        bottomPadded
        title="Reports Rapidi"
        subTitle={
          <span>
            {data ? `${data.pagination.count} totali` : null}
            {filtersCount > 0 && (
              <span
                onClick={() => setSearchParams({})}
                className="pf-u-ml-md pf-u-active-color-100 mimic-link"
              >
                Cancella filtri
              </span>
            )}
          </span>
        }
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/reports-rapidi">
              Reports Rapidi
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <>
            <Button
              onClick={() => {
                getToken
                  .onSuccess((link) => {
                    window.open(link, '_blank')
                  })
                  .run(filters)
              }}
              className="pf-u-mr-md"
            >
              Statistiche
            </Button>
            <Button component={Link} to="/reports-rapidi/add">
              Crea
            </Button>
          </>
        }
        bottomLeft={
          <SearchBar
            initialValue={filters.search}
            onChange={(search) =>
              setSearchParams({ ...filters, page: 1, search })
            }
          />
        }
        bottomRight={
          <Button
            isDisabled={openFilters}
            onClick={() => setOpenFilters(true)}
            variant="secondary"
          >
            {filtersCount === 0 && 'Filtra'}
            {filtersCount > 0 && (
              <>
                Filtri applicati
                <Badge className="pf-u-ml-sm">{filtersCount}</Badge>
              </>
            )}
          </Button>
        }
      />
      <ScrollableContent>
        {openFilters && (
          <ListFiltersOverlay className="pf-u-mt-xl">
            <Formik
              onSubmit={(values) => {
                setSearchParams({
                  ...filters,
                  page: 1,
                  tipo_mezzo: values.tipo_mezzo,
                  inizio: values.inizio,
                  fine: values.fine,
                  stato: values.stato,
                })
                setOpenFilters(false)
              }}
              initialValues={{
                tipo_mezzo: filters.tipo_mezzo,
                stato: filters.stato,
                inizio: filters.inizio,
                fine: filters.fine,
              }}
            >
              {({ handleSubmit }) => (
                <Form isHorizontal onSubmit={handleSubmit}>
                  <div className="pf-u-display-flex pf-u-justify-content-space-between form-group-max-width">
                    <Field
                      invalidFormatText=""
                      label="Dal"
                      name="inizio"
                      component={DatePickerField}
                    />
                    <Field
                      invalidFormatText=""
                      label="Al"
                      name="fine"
                      component={DatePickerField}
                    />
                  </div>
                  <Field
                    label="Tipo Mezzo"
                    name="tipo_mezzo"
                    component={SelectField}
                  >
                    <FormSelectOption label="--" value={''} />
                    {Object.keys(TIPI_MEZZO).map((m) => (
                      <FormSelectOption
                        key={m}
                        label={TIPI_MEZZO[m]}
                        value={m}
                      />
                    ))}
                  </Field>
                  <Field label="Stato" name="stato" component={SelectField}>
                    <FormSelectOption label="--" value={''} />
                    <FormSelectOption
                      key={'aperto'}
                      label={'APERTO'}
                      value={'aperto'}
                    />
                    <FormSelectOption
                      key={'chiuso'}
                      label={'CHIUSO'}
                      value={'chiuso'}
                    />
                  </Field>
                  <FormGroup>
                    <div className="pf-u-display-flex pf-u-justify-content-space-between pf-u-mt-md">
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={() => setOpenFilters(false)}
                      >
                        Annulla
                      </Button>
                      <Button type="submit">Aggiorna</Button>
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ListFiltersOverlay>
        )}
        {!openFilters && (
          <>
            <TableComposable isStickyHeader>
              <Thead>
                <Tr>
                  <Th sort={sorter('id')}>Report</Th>
                  <Th>Periodo</Th>
                  <Th sort={sorter('tipo_mezzo')}>Mezzo</Th>
                  <Th>Operatori</Th>
                  <Th sort={sorter('amministratore')}>Amministratore</Th>
                  {/* <Th sort={sorter('via')}>Via</Th>
                  <Th sort={sorter('dataora_inizio')}>Data e ora</Th> */}
                  <Th>Stato</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.list.map((report) => (
                    <Tr
                      isHoverable
                      key={report.id}
                      onRowClick={() =>
                        navigate(`/reports-rapidi/${report.id}`)
                      }
                    >
                      <Td>{report.id}</Td>
                      <Td>
                        {dayjs(report.periodo_da).format('DD/MM/YYYY')} -{' '}
                        {dayjs(report.periodo_a).format('DD/MM/YYYY')}
                      </Td>
                      <Td>{TIPI_MEZZO[report.tipo_mezzo]}</Td>
                      <Td>
                        {report.operatori_data.map((operatore, i) => {
                          const isLast = i === report.operatori_data.length - 1
                          return (
                            <span
                              className={isLast ? '' : 'pf-u-mr-xs'}
                              key={operatore.id}
                            >
                              {operatore.nome}
                              {!isLast && ','}
                            </span>
                          )
                        })}
                      </Td>
                      {/* <Td>{report.operatore_data.nome}</Td> */}
                      <Td>{report.amministratore_data.user.name}</Td>
                      <Td>{report.stato}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </TableComposable>
            {data?.pagination && (
              <Pagination
                variant="bottom"
                isSticky
                onSetPage={(_, page) => {
                  setSearchParams({ ...filters, page })
                }}
                page={+(searchParams.get('page') ?? 1)}
                perPageOptions={[{ title: PAGE_SIZE, value: PAGE_SIZE }]}
                itemCount={data.pagination.count}
                perPage={PAGE_SIZE}
              />
            )}
          </>
        )}
      </ScrollableContent>
    </FullPage>
  )
}
