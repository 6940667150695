import {
  Breadcrumb,
  Button,
  Modal,
  ModalVariant,
  Pagination,
} from '@patternfly/react-core'
import {
  TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import SearchBar from '../../../components/SearchBar'
import SectionHeader from '../../../components/SectionHeader'
import { PAGE_SIZE } from '../../../consts'
import { useOperatoriList } from '../../../hooks/operatori'
import { useTableSorter } from '../../../hooks/sorter'
import OperatoreFormBoxModal from '../OperatoreFormBoxModal'

export default function OperatoriList() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const filters = useMemoCompare({
    page: searchParams.get('page') ?? 1,
    search: searchParams.get('search') ?? '',
    ordering: searchParams.get('ordering') ?? '',
  })
  const sorter = useTableSorter(filters.ordering, (ordering) =>
    setSearchParams({ ...filters, page: 1, ordering })
  )

  const [{ data, pending, error }, { addOperatore }] = useOperatoriList(filters)

  const [modalAdd, modalAddActions] = useModalTrigger()

  return (
    <FullPage>
      <SectionHeader
        bottomPadded
        title="Operatori"
        subTitle={data ? `${data.pagination.count} totali` : null}
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/operatori">Operatori</BreadcrumbItemLink>
          </Breadcrumb>
        }
        bottomLeft={
          <SearchBar
            initialValue={filters.search}
            onChange={(search) =>
              setSearchParams({ ...filters, page: 1, search })
            }
          />
        }
        centerRight={
          <>
            <Button
              onClick={() => {
                modalAddActions.open()
              }}
            >
              Crea
            </Button>
          </>
        }
      />
      <ScrollableContent>
        <TableComposable isStickyHeader>
          <Thead>
            <Tr>
              <Th sort={sorter('nome')}>Nome</Th>
              <Th sort={sorter('email')}>Email</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              data.list.map((operatore) => (
                <Tr
                  isHoverable
                  key={operatore.id}
                  onRowClick={() => navigate(`/operatori/${operatore.id}`)}
                >
                  <Td>{operatore.nome}</Td>
                  <Td>{operatore.email}</Td>
                </Tr>
              ))}
          </Tbody>
        </TableComposable>
        {data?.pagination && (
          <Pagination
            variant="bottom"
            isSticky
            onSetPage={(_, page) => {
              setSearchParams({ ...filters, page })
            }}
            page={+(searchParams.get('page') ?? 1)}
            perPageOptions={[{ title: PAGE_SIZE, value: PAGE_SIZE }]}
            itemCount={data.pagination.count}
            perPage={PAGE_SIZE}
          />
        )}
      </ScrollableContent>
      <Modal
        variant={ModalVariant.medium}
        title="Crea Operatore"
        aria-label="Crea Operatore"
        hasNoBodyWrapper
        isOpen={modalAdd.isOpen}
        onClose={modalAddActions.close}
      >
        <OperatoreFormBoxModal
          save={(data) => {
            return addOperatore
              .onSuccess((newOperatore) => {
                modalAddActions.close()
                navigate(`/operatori/${newOperatore.id}`)
              })
              .asPromise(data)
          }}
          onClose={modalAddActions.close}
        />
      </Modal>
    </FullPage>
  )
}
