import { rj, useRunRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

const SegnalazioniListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .mutations({
    getToken: {
      effect: t => params => api.auth(t).get('/api/segnalazioni/token', params),
      updater: s => s,
    }
  })
  .computed({
    list: 'getList',
    pagination: 'getPagination',
    loading: 'isLoading',
    error: 'getError',
  })
  .effect({
    name: 'SegnalazioniList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/segnalazioni', params),
  })

export function useSegnalazioniList(params) {
  return useRunRj(SegnalazioniListState, [params], false)
}

const SegnalazioniDetailState = rj()
  .mutations({
    performAction: {
      effect: (t) => (id, action, payload) => {
        const formData = new FormData()
        formData.append('messaggio', payload.messaggio ?? '')
        if (payload.allegato_1 instanceof File) {
          formData.append('allegato_1', payload.allegato_1)
        }
        if (payload.allegato_2 instanceof File) {
          formData.append('allegato_2', payload.allegato_2)
        }
        return api.auth(t).put(`/api/segnalazioni/${id}/${action}`, formData)
      },
      updater: 'updateData',
    },
  })
  .effect({
    name: 'SegnalazioneDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/api/segnalazioni/${id}`),
  })

export function useSegnalazioneDetail(id) {
  return useRunRj(SegnalazioniDetailState, [id])
}
