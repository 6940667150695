import {
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
  Text,
  Title,
} from '@patternfly/react-core'
import { ImageIcon, DownloadIcon } from '@patternfly/react-icons'
import difference from 'lodash/difference'
import classNames from 'classnames'
import dayjs from 'dayjs'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import FullPage from '../../components/FullPage'
import ScrollableContent from '../../components/ScrollableContent'
import SectionHeader from '../../components/SectionHeader'
import { STATI_SEGNALAZIONE, TIPI_MEZZO } from '../../consts'
import { useSegnalazioneDetail } from '../../hooks/segnalazioni'
import SegnalazioneActionBoxModal from './SegalazioneActionBoxModal'
import { useMemo } from 'react'

function OtherProblems({ segnalazione }) {
  const others = useMemo(
    () =>
      difference(
        [
          ...segnalazione.rilievo.messages_anomalie_lievi,
          ...segnalazione.rilievo.messages_anomalie_sosta,
          ...segnalazione.rilievo.messages_anomalie_tecniche,
          ...segnalazione.rilievo.messages_non_conformita,
        ].map((s) => s.text),
        segnalazione.righe_segnalazione.map((r) => r.testo)
      ),
    [segnalazione]
  )
  return (
    <div>
      <Title headingLevel="h2" size="xl" className="pf-u-my-md">
        Altri Problemi
      </Title>
      {others.map((text, index) => (
        <Grid
          key={index}
          className={classNames('pf-u-p-sm', {
            'pf-u-background-color-200': index % 2 === 0,
          })}
        >
          <GridItem span={12}>
            <Text>{text}</Text>
          </GridItem>
        </Grid>
      ))}
    </div>
  )
}

export default function SegnalazioneDetail() {
  const { user } = useAuthUser()
  const { id } = useParams()
  const [{ data: segnalazione }, { performAction }] = useSegnalazioneDetail(id)
  const [modalImg, modalImgActions] = useModalTrigger()
  const [modalPerform, modalPerformActions] = useModalTrigger()

  function openImage(value) {
    modalImgActions.open(value)
  }

  return (
    <FullPage>
      <SectionHeader
        title={`Segnalazione ${id}`}
        topRight={
          segnalazione ? (
            <>
              {segnalazione.next_actions.map((action) => (
                <Button
                  variant={action.btn_variant}
                  onClick={() => {
                    modalPerformActions.open(action)
                  }}
                  className="pf-u-ml-md"
                  key={action.name}
                >
                  {action.label}
                </Button>
              ))}
            </>
          ) : null
        }
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/segnalazioni">
              Segnalazioni
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/segnalazioni/${id}`}>
              Dettaglio segnalazione {id}
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <ScrollableContent>
        {segnalazione && !segnalazione.has_account_operatore && (
          <Alert
            className="pf-u-mb-md"
            variant="warning"
            title={
              <span>
                Non esistono account operatore che possono gestire questa
                segalazione.
              </span>
            }
            aria-live="polite"
            isInline
          />
        )}
        {segnalazione && (
          <div>
            <Title headingLevel="h2" size="xl" className="pf-u-mb-md">
              Informazioni generali
            </Title>
            <Grid className="pf-u-mb-sm">
              <GridItem span={2}>
                <b>Stato</b>
              </GridItem>
              <GridItem span={10}>
                <Badge className="pf-u-p-xs pf-u-px-sm">
                  {STATI_SEGNALAZIONE[segnalazione.stato]}
                </Badge>
              </GridItem>
            </Grid>
            {user.amministratore && (
              <Grid className="pf-u-mb-sm">
                <GridItem span={2}>
                  <b>Rilievo</b>
                </GridItem>
                <GridItem span={10}>
                  <Link to={`/rilievi/${segnalazione.rilievo.id}`}>
                    # {segnalazione.rilievo.id}
                  </Link>
                </GridItem>
              </Grid>
            )}
            <Grid className="pf-u-mb-sm">
              <GridItem span={2}>
                <b>Data e ora rilievo</b>
              </GridItem>
              <GridItem span={10}>
                {dayjs(segnalazione.rilievo.dataora_inizio).format(
                  'DD/MM/YYYY HH:mm'
                )}
              </GridItem>
            </Grid>
            <Grid className="pf-u-mb-sm">
              <GridItem span={2}>
                <b>Posizione</b>
              </GridItem>
              <GridItem span={10}>
                {segnalazione.rilievo.via_data?.nome ?? ''}
              </GridItem>
            </Grid>
            <Grid className="pf-u-mb-sm">
              <GridItem span={2}>
                <b>Operatore</b>
              </GridItem>
              <GridItem span={10}>
                {segnalazione.rilievo.operatore_data?.nome ?? ''}
              </GridItem>
            </Grid>
            <Grid className="pf-u-mb-sm">
              <GridItem span={2}>
                <b>Mezzo</b>
              </GridItem>
              <GridItem span={2}>
                {TIPI_MEZZO[segnalazione.rilievo.tipo_mezzo]}
              </GridItem>
              <GridItem span={2}>
                <img
                  onClick={() =>
                    openImage({
                      caption: 'Immagine mezzo',
                      src: segnalazione.rilievo.foto_mezzo,
                    })
                  }
                  className="thumbnail-rilievo cursor-pointer"
                  src={segnalazione.rilievo.foto_mezzo}
                  alt="foto mezzo"
                ></img>
              </GridItem>
              {segnalazione.rilievo.foto_stazione && (
                <GridItem span={2}>
                  <img
                    onClick={() =>
                      openImage({
                        caption: 'Immagine stazione',
                        src: segnalazione.rilievo.foto_stazione,
                      })
                    }
                    className="thumbnail-rilievo cursor-pointer"
                    src={segnalazione.rilievo.foto_stazione}
                    alt="foto stazione"
                  ></img>
                </GridItem>
              )}
            </Grid>
            <Grid className="pf-u-mb-sm">
              <GridItem span={2}>
                <b>Id/targa</b>
              </GridItem>
              <GridItem span={2}>
                {segnalazione.rilievo.identificativo_mezzo}
              </GridItem>
              <GridItem span={2}>
                <img
                  onClick={() =>
                    openImage({
                      caption: 'Immagine identificativo mezzo',
                      src: segnalazione.rilievo.foto_identificativo_mezzo,
                    })
                  }
                  className="thumbnail-rilievo cursor-pointer"
                  src={segnalazione.rilievo.foto_identificativo_mezzo}
                  alt="foto identificativo mezzo"
                ></img>
              </GridItem>
              {segnalazione.rilievo.foto_identificativo_mezzo_aggiuntivo && (
                <GridItem span={2}>
                  <img
                    onClick={() =>
                      openImage({
                        caption: 'Immagine identificativo mezzo aggiuntivo',
                        src: segnalazione.rilievo
                          .foto_identificativo_mezzo_aggiuntivo,
                      })
                    }
                    className="thumbnail-rilievo cursor-pointer"
                    src={
                      segnalazione.rilievo.foto_identificativo_mezzo_aggiuntivo
                    }
                    alt="foto identificativo mezzo aggiuntivo"
                  ></img>
                </GridItem>
              )}
            </Grid>
            <Title headingLevel="h2" size="xl" className="pf-u-mb-md">
              Problemi
            </Title>
            {segnalazione.righe_segnalazione.map((r, index) => (
              <Grid
                key={r.id}
                className={classNames('pf-u-p-sm', {
                  'pf-u-background-color-200': index % 2 === 0,
                })}
              >
                <GridItem span={9}>
                  <Text>{r.testo}</Text>
                </GridItem>
                <GridItem span={3}>
                  {r?.risposta?.immagine && (
                    <ImageIcon
                      onClick={() => {
                        openImage({
                          src: r.risposta.immagine,
                          caption: `Immagine ${r.testo}`,
                        })
                      }}
                      className="pf-u-active-color-100 cursor-pointer"
                    />
                  )}
                </GridItem>
              </Grid>
            ))}
            {user.amministratore && (
              <OtherProblems segnalazione={segnalazione} />
            )}
            <Title headingLevel="h2" size="xl" className="pf-u-my-md">
              Storico
            </Title>
            {segnalazione.cambi_stato.map((r, index) => (
              <Grid
                key={r.id}
                className={classNames('pf-u-p-sm', {
                  'pf-u-background-color-200': index % 2 === 0,
                })}
              >
                <GridItem span={3}>
                  <Text>
                    <b>{STATI_SEGNALAZIONE[r.nuovo_stato]}</b>
                  </Text>
                </GridItem>
                <GridItem span={3}>
                  {r.utente ? (
                    <Text>
                      {r.utente.amministratore
                        ? 'AMAT'
                        : segnalazione.rilievo.operatore_data.nome}
                    </Text>
                  ) : (
                    <Text />
                  )}
                </GridItem>
                <GridItem span={3}>
                  <Text>{dayjs(r.dataora).format('DD/MM/YYYY HH:mm')}</Text>
                </GridItem>
                <GridItem span={3}>
                  <Text>
                    {r.allegato_1 && (
                      <a href={r.allegato_1} target="_blank" rel="noreferrer">
                        {'Allegato 1 '}
                        <DownloadIcon />
                      </a>
                    )}
                    {r.allegato_2 && (
                      <a
                        href={r.allegato_2}
                        className="pf-u-ml-md"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {'Allegato 2 '}
                        <DownloadIcon />
                      </a>
                    )}
                  </Text>
                </GridItem>

                {r.messaggio && (
                  <GridItem span={12}>
                    <Text>{r.messaggio}</Text>
                  </GridItem>
                )}
              </Grid>
            ))}
          </div>
        )}
        <Modal
          title={modalImg.value?.caption ?? ''}
          aria-label="immagine"
          className="modal-actions-end"
          isOpen={modalImg.isOpen}
          variant={ModalVariant.medium}
          actions={[
            <Button
              key="close"
              onClick={modalImgActions.close}
              variant="secondary"
            >
              Chiudi
            </Button>,
          ]}
          onClose={modalImgActions.close}
        >
          {modalImg.value && <img src={modalImg.value.src} alt="Immagine" />}
        </Modal>
        <Modal
          variant={ModalVariant.medium}
          title={modalPerform.value?.label ?? ''}
          aria-label="action seganalzione"
          hasNoBodyWrapper
          isOpen={modalPerform.isOpen}
          onClose={modalPerformActions.close}
        >
          <SegnalazioneActionBoxModal
            action={modalPerform.value ?? null}
            save={(data) => {
              return performAction
                .onSuccess(() => {
                  modalPerformActions.close()
                })
                .asPromise(segnalazione.id, modalPerform.value.name, data)
            }}
            onClose={modalPerformActions.close}
          />
        </Modal>
      </ScrollableContent>
    </FullPage>
  )
}
