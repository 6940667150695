import React from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { transformErrorForForm } from './Form'
import { Button, Form, FormGroup } from '@patternfly/react-core'
import { InputField } from './Form/fields'

const INITIAL_VALUES = {
  revisione: '',
}

const ReportRevisionSchema = Yup.object().shape({
  revisione: Yup.string().required().label('Revisione'),
})

export default function ReportRevisionForm({ save, className, cancel, tipo }) {
  return (
    <Formik
      validationSchema={ReportRevisionSchema}
      initialValues={INITIAL_VALUES}
      onSubmit={(values, actions) =>
        save(values).catch((err) => {
          actions.setErrors(transformErrorForForm(err))
        })
      }
    >
      {({ handleSubmit, isSubmitting, resetForm }) => (
        <Form onSubmit={handleSubmit} className={className} isHorizontal>
          <Field label="Revisione" name="revisione" component={InputField} />
          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between">
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => {
                  cancel ? cancel() : resetForm({ values: INITIAL_VALUES })
                }}
              >
                Annulla
              </Button>
              <Button isDisabled={isSubmitting} type="submit">
                Nuova Revisione
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
