import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import AmministratoriList from './AmministratoriList'
import AmministratoreAdd from './AmministratoreAdd'
import AmministratoreDetail from './AmministratoreDetail'

export default function Amministratori() {
  return (
    <Routes>
      <Route index element={<AmministratoriList />} />
      <Route path="add" element={<AmministratoreAdd />} />
      <Route path=":id" element={<AmministratoreDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
