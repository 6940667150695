import {
  Button,
  Form,
  ModalBoxBody,
  ModalBoxFooter,
} from '@patternfly/react-core'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import {
  FileUploadField,
  TextAreaField,
  transformErrorForForm,
} from '../../components/Form'

const SegnalazioneActionSchema = Yup.object().shape({
  messaggio: Yup.string().label('Messaggio'),
  allegato_1: Yup.mixed().label('Allegato'),
  allegato_2: Yup.mixed().label('Allegato'),
})

const INITIAL_DATA = {
  messaggio: '',
  allegato_1: '',
  allegato_2: '',
}

export default function SegnalazioneActionBoxModal({ onClose, save, action }) {
  return (
    <Formik
      validationSchema={SegnalazioneActionSchema}
      onSubmit={(values, actions) =>
        save(values).catch((error) => {
          actions.setErrors(transformErrorForForm(error))
        })
      }
      initialValues={INITIAL_DATA}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} isHorizontal>
          <ModalBoxBody className="pf-c-form">
            <p>{action.message}</p>
            <Field
              name="messaggio"
              label="Messaggio"
              component={TextAreaField}
            />
            <Field
              name="allegato_1"
              label="Allegato"
              component={FileUploadField}
            />
            <Field
              name="allegato_2"
              label="Allegato"
              component={FileUploadField}
            />
          </ModalBoxBody>
          <ModalBoxFooter className="pf-u-display-flex pf-u-justify-content-space-between">
            <Button variant="link" onClick={onClose} isDisabled={isSubmitting}>
              Annulla
            </Button>
            <Button type="submit" variant="primary" isDisabled={isSubmitting}>
              Conferma
            </Button>
          </ModalBoxFooter>
        </Form>
      )}
    </Formik>
  )
}
