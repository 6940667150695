
import { deps, rj, useRunRj } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import api from '../api'
import { PAGE_SIZE } from '../consts'

const MezziListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .effect({
    name: 'MezziList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/mezzi', params),
  })

const NO_PARAMS = {}
export function useMezziList(params = NO_PARAMS) {
  return useRunRj(MezziListState, [deps.maybe(params)], false)
}
