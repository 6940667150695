import { Breadcrumb, Button, Modal, ModalVariant } from '@patternfly/react-core'
import { useNavigate, useParams } from 'react-router'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useAccountOperatoreDetail } from '../../../hooks/accountOperatore'
import AccountOperatoreForm from '../../../components/AccountOperatoreForm/AccountOperatoreForm'

export default function AccountDetail() {
  const { id } = useParams()
  const [{ data: account }, { updateAccount, removeAccount }] =
    useAccountOperatoreDetail(id)
  const [modalRm, modalRmActions] = useModalTrigger()
  const navigate = useNavigate()

  return (
    <FullPage>
      <SectionHeader
        title={account?.user?.name ?? ''}
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/account-operatore">
              Account Operatore
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/account-operatore/${id}`}>
              Dettaglio account
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <Button onClick={modalRmActions.open} variant="danger">
            Elimina
          </Button>
        }
      />
      <ScrollableContent>
        {account && (
          <AccountOperatoreForm
            account={account}
            save={(data) => updateAccount.asPromise(account.id, data)}
          />
        )}
      </ScrollableContent>
      {account && (
        <Modal
          title={'Rimuovi account operatore'}
          className="modal-actions-end"
          isOpen={modalRm.isOpen}
          variant={ModalVariant.medium}
          actions={[
            <Button onClick={modalRmActions.close} variant="secondary">
              Annulla
            </Button>,
            <Button
              onClick={() => {
                removeAccount
                  .onSuccess(() => {
                    navigate('/account-operatore')
                  })
                  .run(account.id)
              }}
            >
              Conferma
            </Button>,
          ]}
          onClose={modalRmActions.close}
        >
          Rimuovere account operatore {account.user.name} ?
        </Modal>
      )}
    </FullPage>
  )
}
