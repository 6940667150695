import FullPage from '../../components/FullPage'
import { Link } from 'react-router-dom'
import { Breadcrumb, Button, Card, CardBody } from '@patternfly/react-core'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'
import BreadcrumbItemLink from '../../components/BreadcrumbItemLink'
import SectionHeader from '../../components/SectionHeader'

export default function ViewProfile() {
  const { user } = useAuthUser()
  const { logout } = useAuthActions()

  return (
    <FullPage>
      <SectionHeader
        title="Profilo"
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/profile">Profilo</BreadcrumbItemLink>
          </Breadcrumb>
        }
      />
      <Card>
        <CardBody>
          <div>{user.name}</div>
          <div>{user.email}</div>
          <div className="pf-u-display-flex pf-u-mt-md">
            {(user.rilevatore || user.amministratore) && (
              <Button
                component={Link}
                to="/profile/edit"
                className="pf-u-mr-xl"
              >
                Modifica
              </Button>
            )}
            <Button
              component={Link}
              to="/profile/change-password"
              className="pf-u-mr-xl"
            >
              Cambia Password
            </Button>
            <Button onClick={() => logout()}>Logout</Button>
          </div>
        </CardBody>
      </Card>
    </FullPage>
  )
}
