import { useCallback } from 'react'
import { rj, useRj, useRunRj, deps } from 'react-rocketjump'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import { useAuthActions } from 'use-eazy-auth'
import api from '../api'
import { PAGE_SIZE } from '../consts'

const AmministratoriListState = rj()
  .plugins(
    rjList({
      pagination: nextPreviousPaginationAdapter,
      pageSize: PAGE_SIZE,
    })
  )
  .effect({
    name: 'AmministratoriList',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/amministratori', params),
  })

const AddAmministratoreState = rj().effect({
  name: 'AddAmministratore',
  effectCaller: 'configured',
  effect: (t) => (data) => {
    const formData = new FormData()
    formData.append('user.email', data.user.email)
    formData.append('user.name', data.user.name)
    formData.append('riceve_mail_segnalazione', data.riceve_mail_segnalazione)
    if (data.firma instanceof File) {
      formData.append('firma', data.firma)
    }
    return api.auth(t).post('/api/amministratori', formData)
  },
})

const AmministratoreDetailState = rj()
  .mutations({
    updateAmministratore: {
      updater: 'updateData',
      effect: (t) => (id, data) => {
        const formData = new FormData()
        console.log(data)
        formData.append('user.id', data.user.id)
        formData.append('user.email', data.user.email)
        formData.append('user.name', data.user.name)
        formData.append('user.is_active', data.user.is_active)
        formData.append(
          'riceve_mail_segnalazione',
          data.riceve_mail_segnalazione
        )
        if (data.firma instanceof File) {
          formData.append('firma', data.firma)
        } else if (data.firma === null) {
          // Remove file!
          formData.append('firma', '')
        }
        return api.auth(t).put(`/api/amministratori/${id}/`, formData)
      },
    },
    removeAmministratore: {
      updater: (s) => s,
      effect: (t) => (id) => api.auth(t).delete(`/api/amministratori/${id}/`),
    },
  })
  .effect({
    name: 'AmministratoreDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/api/amministratori/${id}`),
  })

export function useAddAmministratore() {
  return useRj(AddAmministratoreState)
}

export function useAmministratoreDetail(id) {
  return useRunRj(AmministratoreDetailState, [id])
}

const NO_PARAMS = {}
export function useAmministratoriList(params = NO_PARAMS) {
  return useRunRj(AmministratoriListState, [deps.maybe(params)], false)
}

export function useFetchAmministratori() {
  const { callAuthApiObservable } = useAuthActions()
  return useCallback(
    (...args) => {
      return callAuthApiObservable(
        (t) =>
          (params = {}) =>
            api.auth(t).get('/api/amministratori', params),
        ...args
      )
    },
    [callAuthApiObservable]
  )
}
