import { useCallback, useState } from 'react'
import {
  Badge,
  Breadcrumb,
  Button,
  Modal,
  ModalVariant,
  ModalBoxBody,
} from '@patternfly/react-core'
import {
  useReportCompletoDetail,
  useReportCompletoRilievi,
} from '../../../hooks/reportCompleti'
import { Link, useParams } from 'react-router-dom'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import BigChip from '../../../components/BigChip'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import ReportDocForm from '../../../components/ReportDocForm'
import { TIPI_MEZZO } from '../../../consts'
import dayjs from 'dayjs'
import AlertLastReportRevision from '../AlertLastReportRevision'

export default function ReporDocument() {
  const { id } = useParams()
  const [{ data: report, closing }, { updateReport, chiudiReport }] =
    useReportCompletoDetail(id)
  const [{ data: rilievi }] = useReportCompletoRilievi(id)
  const [modalEdit, modalEditActions] = useModalTrigger()
  const [modalClose, modalCloseActions] = useModalTrigger()

  const [embedState, setEmbedState] = useState(0)
  const flipEmbedState = useCallback(
    () => setEmbedState((e) => (e === 0 ? 1 : 0)),
    []
  )
  const embedContentType = report
    ? new URL(report.pdf_path).pathname.endsWith('/html/')
      ? 'text/html'
      : 'application/pdf'
    : null

  return (
    <FullPage>
      <SectionHeader
        title={`Documento Report Completo ${report?.id ?? ''}`}
        centerRight={
          <>
            {report && (
              <>
                <Button
                  component={Link}
                  to={`/reports-completi/${id}`}
                  className="pf-u-mr-xs"
                >
                  Visualizza rilievi
                </Button>
                {report.pdf_preview_download && !report.pdf_file && (
                  <Button
                    component="a"
                    className="pf-u-mr-xs"
                    target="_blank"
                    href={report.pdf_preview_download}
                  >
                    Download PDF
                  </Button>
                )}
                {!report.dataora_chiusura && (
                  <Button
                    variant="danger"
                    isDisabled={report ? !report.chiudibile : true}
                    onClick={() => {
                      modalCloseActions.open()
                    }}
                  >
                    Chiudi Report
                  </Button>
                )}
                {report.pdf_file && (
                  <Button
                    component="a"
                    className="pf-u-ml-xs"
                    href={report.pdf_file}
                    download
                  >
                    Download PDF
                  </Button>
                )}
              </>
            )}
          </>
        }
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/reports-completi">
              Reports Completi
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/reports-completi/${id}`}>
              Report Completo {id}
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/reports-completi/${id}/doc`}>
              Documento Report
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        bottomLeft={
          report && (
            <div>
              <AlertLastReportRevision report={report} />
              <div className="pf-u-mb-sm">
                <BigChip isReadOnly className="pf-u-mr-xs">
                  N. rilievi: <Badge>{rilievi?.length}</Badge>
                </BigChip>
                <BigChip isReadOnly className="pf-u-mr-xs">
                  <b>Periodo:</b> dal{' '}
                  {dayjs(report.periodo_da).format('DD/MM/YYYY')} al{' '}
                  {dayjs(report.periodo_a).format('DD/MM/YYYY')}
                </BigChip>
                <BigChip isReadOnly className="pf-u-mr-xs">
                  <b>Tipo</b>: {TIPI_MEZZO[report.tipo_mezzo]}
                </BigChip>
                <BigChip isReadOnly className="pf-u-mr-xs">
                  <b>Operatore:</b> {report.operatore_data.nome}
                </BigChip>
                {report.vie_data.length > 0 &&
                  report.vie_data.map((via) => (
                    <BigChip key={via.id} isReadOnly className="pf-u-mr-xs">
                      <b>Via:</b>
                      {via.nome}
                    </BigChip>
                  ))}
                {report.vie_data.length === 0 && (
                  <BigChip isReadOnly className="pf-u-mr-xs">
                    <b>Vie:</b> Nessuna via selezionata
                  </BigChip>
                )}
              </div>
            </div>
          )
        }
      />
      <ScrollableContent>
        {report && (
          <div className="pf-u-h-100 pf-u-display-flex pf-u-flex-direction-column">
            <embed
              key={embedState}
              className="pf-u-flex-grow-1 preview-pdf"
              src={`${report.pdf_path}#toolbar=0`}
              // src={`${report.pdf_path}`}
              style={{ width: '100%' }}
              type={embedContentType}
            />
            <div className="pf-u-mt-sm">
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Codifica:</b> {report.codifica ?? 'N.D.'}
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Revisione:</b> {report.revisione ?? 'N.D.'}
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <b>Data:</b>{' '}
                {report.data_doc
                  ? dayjs(report.data_doc).format('DD/MM/YYYY')
                  : 'N.D.'}
              </BigChip>
              <BigChip isReadOnly className="pf-u-mr-xs">
                <a
                  style={
                    report.dataora_chiusura
                      ? { pointerEvents: 'none', opacity: 0.5 }
                      : undefined
                  }
                  onClick={modalEditActions.open}
                >
                  Modifica
                </a>
              </BigChip>
            </div>
          </div>
        )}
      </ScrollableContent>
      <Modal
        variant={ModalVariant.medium}
        title="Modifica Proprietà documento"
        isOpen={modalEdit.isOpen}
        onClose={modalEditActions.close}
        hasNoBodyWrapper
        aria-label="modifca proprietà documento"
      >
        <ModalBoxBody style={{ overflow: 'initial' }}>
          <ReportDocForm
            tipo="completo"
            report={report}
            cancel={modalEditActions.close}
            save={(data) =>
              updateReport.asPromise(report.id, data).then(() => {
                modalEditActions.close()
                flipEmbedState()
              })
            }
          />
        </ModalBoxBody>
      </Modal>
      <Modal
        title={'Chiudi report'}
        className="modal-actions-end"
        isOpen={modalClose.isOpen}
        variant={ModalVariant.medium}
        actions={[
          <Button
            key="undo"
            isDisabled={closing}
            onClick={modalCloseActions.close}
            variant="secondary"
          >
            Annulla
          </Button>,
          <Button
            key="ok"
            isDisabled={closing}
            onClick={() => {
              chiudiReport
                .onSuccess(() => {
                  flipEmbedState()
                  modalCloseActions.close()
                })
                .run(id)
            }}
          >
            Conferma
          </Button>,
        ]}
        onClose={modalCloseActions.close}
      >
        Chiudere il report corrente?
      </Modal>
    </FullPage>
  )
}
