import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import SectionHeader from '../../../components/SectionHeader'
import { Link, useParams } from 'react-router-dom'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import BigChip from '../../../components/BigChip'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { STATI_RILIEVO, TIPI_MEZZO } from '../../../consts'
import uniq from 'lodash/uniq'
import ReportForm from '../../../components/ReportRapidoForm'
import {
  Badge,
  Breadcrumb,
  Button,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Modal,
  ModalBoxBody,
  ModalVariant,
} from '@patternfly/react-core'
import {
  TableComposable,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from '@patternfly/react-table'
import classNames from 'classnames'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import { useMemo } from 'react'
import {
  useReportRapidoDetail,
  useReportRapidoRilievi,
} from '../../../hooks/reportsRapidi'

export default function ReportDetail() {
  const { id } = useParams()
  const [{ data: report }, { updateReport, removeReport }] =
    useReportRapidoDetail(id)
  const [{ data: rilievi }, { run: refreshRilievi }] =
    useReportRapidoRilievi(id)

  const navigate = useNavigate()
  const [modalEdit, modalEditActions] = useModalTrigger()
  const [modalImportazioni, modalImportazioniActions] = useModalTrigger()
  const [modalDelete, modalDeleteActions] = useModalTrigger()

  const dateImportazione = useMemo(() => {
    if (!rilievi) {
      return []
    }
    return uniq(rilievi.map((r) => r.data_aggiornamento_flotta).filter(Boolean))
      .sort()
      .reverse()
  }, [rilievi])

  return (
    <FullPage>
      <SectionHeader
        bottomPadded
        title={'Report Rapido ' + id}
        // subTitle={rep ? `${data.pagination.count} totali` : null}
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/reports-rapidi">
              Reports Rapidi
            </BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/reports-rapidi/${id}`}>
              Report Rapidi {id}
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          <>
            {report && (
              <>
                {!report.dataora_chiusura && (
                  <Button
                    variant="danger"
                    className="pf-u-mr-xs"
                    onClick={() => modalDeleteActions.open()}
                  >
                    Elimina Report
                  </Button>
                )}
                <Button component={Link} to={`/reports-rapidi/${id}/doc`}>
                  Visualizza Report
                </Button>
                {report.xlsx_file && (
                  <Button
                    component="a"
                    href={report.xlsx_file}
                    download
                    className="pf-u-ml-xs"
                  >
                    Download xlsx
                  </Button>
                )}
              </>
            )}
          </>
        }
        bottomLeft={
          report && (
            <div>
              <div>
                <BigChip isReadOnly className="pf-u-mr-xs">
                  N. rilievi: <Badge>{rilievi?.length}</Badge>
                </BigChip>
                <BigChip isReadOnly className="pf-u-mr-xs">
                  <b>Periodo:</b> dal{' '}
                  {dayjs(report.periodo_da).format('DD/MM/YYYY')} al{' '}
                  {dayjs(report.periodo_a).format('DD/MM/YYYY')}
                </BigChip>
                <BigChip isReadOnly className="pf-u-mr-xs">
                  <b>Tipo</b>: {TIPI_MEZZO[report.tipo_mezzo]}
                </BigChip>
                <BigChip isReadOnly className="pf-u-mr-xs">
                  <b>Operatori:</b>{' '}
                  {report.operatori_data.map((operatore, i) => {
                    const isLast = i === report.operatori_data.length - 1
                    return (
                      <span
                        className={isLast ? '' : 'pf-u-mr-xs'}
                        key={operatore.id}
                      >
                        {operatore.nome}
                        {!isLast && ','}
                      </span>
                    )
                  })}
                </BigChip>
                {report.vie_data.length > 0 &&
                  report.vie_data.map((via) => (
                    <BigChip key={via.id} isReadOnly className="pf-u-mr-xs">
                      <b>Via:</b>
                      {via.nome}
                    </BigChip>
                  ))}
                {report.vie_data.length === 0 && (
                  <BigChip isReadOnly className="pf-u-mr-xs">
                    <b>Vie:</b> Nessuna via selezionata
                  </BigChip>
                )}
                <BigChip isReadOnly className="pf-u-mr-xs">
                  <a
                    style={
                      report.dataora_chiusura
                        ? { pointerEvents: 'none', opacity: 0.5 }
                        : undefined
                    }
                    onClick={modalEditActions.open}
                  >
                    Modifica
                  </a>
                </BigChip>
              </div>
              <div>
                {/* <BigChip isReadOnly className="pf-u-mr-xs">
                  N. rilievi: <Badge>{rilievi?.length}</Badge>
                </BigChip> */}
              </div>
            </div>
          )
        }
        bottomRight={<></>}
      />
      <ScrollableContent>
        <TableComposable isStickyHeader>
          <Thead>
            <Tr>
              <Th>ID Dispositivo</Th>
              <Th>Operatore</Th>
              <Th>Via</Th>
              <Th>Data e ora</Th>
              <Th>Rilevatore</Th>
              <Th>Stato</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rilievi &&
              rilievi.map((rilievo) => (
                <Tr
                  isHoverable
                  key={rilievo.id}
                  onRowClick={() => navigate(`/rilievi/${rilievo.id}`)}
                >
                  <Td>{rilievo.identificativo_mezzo}</Td>
                  <Td>{rilievo.operatore_data.nome}</Td>
                  <Td>{rilievo.via_data.nome}</Td>
                  <Td>
                    {rilievo.dataora_inizio
                      ? dayjs(rilievo.dataora_inizio).format('DD/MM/YYYY HH:mm')
                      : null}{' '}
                  </Td>
                  <Td>{rilievo.rilevatore_data.user.name}</Td>
                  <Td
                    style={{ textAlign: 'center' }}
                    className={classNames({
                      'rilievo-rapido-non-in-flotta': !rilievo.mezzo_in_flotta,
                      'rilievo-rapido-in-flotta': rilievo.mezzo_in_flotta,
                    })}
                  >
                    {rilievo.mezzo_in_flotta
                      ? 'In Flotta'
                      : 'Non presente tra i mezzi in flotta'}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </TableComposable>
      </ScrollableContent>
      {rilievi && (
        <div className="pf-u-display-flex">
          <BigChip isReadOnly className="pf-u-mr-md">
            <b>Ultma anagrafica:</b>{' '}
            {dateImportazione[0]
              ? dayjs(dateImportazione[0]).format('MM/DD/YYYY')
              : '-'}
          </BigChip>
          <div
            className="pf-u-mr-xs cursor-pointer pf-c-chip pf-u-mr-xs cursor-pointer no-ellipsis-chip pf-u-p-sm"
            onClick={() => {
              modalImportazioniActions.open()
            }}
          >
            <span className="pf-u-active-color-100 pf-u-mr-xs">
              Vedi altri caricamenti
            </span>
            <Badge>{dateImportazione.length}</Badge>
          </div>
        </div>
      )}
      <Modal
        variant={ModalVariant.medium}
        title="Modifica Selezione rilievi"
        hasNoBodyWrapper
        isOpen={modalEdit.isOpen}
        onClose={modalEditActions.close}
        aria-label="modal modifica rilievi"
      >
        <ModalBoxBody style={{ overflow: 'initial' }}>
          <ReportForm
            report={report}
            cancel={modalEditActions.close}
            save={(data) =>
              updateReport.asPromise(report.id, data).then(() => {
                modalEditActions.close()
                refreshRilievi(report.id)
              })
            }
          />
        </ModalBoxBody>
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Caricamenti"
        isOpen={modalImportazioni.isOpen}
        onClose={modalImportazioniActions.close}
      >
        <DataList aria-label="Simple data list example">
          {dateImportazione.map((data) => (
            <DataListItem aria-labelledby="simple-item1" key={data}>
              <DataListItemRow>
                <DataListItemCells
                  dataListCells={[
                    <DataListCell>
                      {dayjs(data).format('MM/DD/YYYY')}
                    </DataListCell>,
                  ]}
                />
              </DataListItemRow>
            </DataListItem>
          ))}
        </DataList>
      </Modal>
      <Modal
        title={'Elimina Report'}
        className="modal-actions-end"
        isOpen={modalDelete.isOpen}
        variant={ModalVariant.medium}
        actions={[
          <Button onClick={modalDeleteActions.close} variant="secondary">
            Annulla
          </Button>,
          <Button
            onClick={() => {
              removeReport
                .onSuccess(() => {
                  navigate('/reports-rapidi')
                })
                .run(report.id)
            }}
          >
            Conferma
          </Button>,
        ]}
        onClose={modalDeleteActions.close}
      >
        Rimuovere il report corrente?
      </Modal>
    </FullPage>
  )
}
