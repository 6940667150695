import omit from 'lodash/omit'
import * as Sentry from '@sentry/react'
import { useAuthUser } from 'use-eazy-auth'
import {
  FAILURE,
  PENDING,
  rj,
  RUN,
  SUCCESS,
  useRj,
  CANCEL,
} from 'react-rocketjump'
import { concat, of, Subject, merge, EMPTY, throwError } from 'rxjs'
import { filter, map, mergeMap, catchError, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import useConstant from 'magik-react-hooks/useConstant'

let uploadCounter = 0

// Create an observable rj like that also emits upload progress
function uploadEffect(action, getEffectCaller, uploadId) {
  const { payload, callbacks } = action
  const params = payload.params
  const [idRilievo, file] = params

  // Common meta
  const meta = {
    id: idRilievo,
    uploadId,
    fileName: file.name,
  }

  const effectCaller = getEffectCaller(action)

  function uploadCall(token) {
    return (idRilievo, file) => {
      // Map progress to rj like action stream
      const subject = new Subject()
      const progressObservable = subject.asObservable().pipe(
        filter((e) => e.type === 'progress'),
        map((e) => ({
          type: 'UPLOAD_PROGRESS',
          meta,
          payload: {
            data: {
              loaded: e.loaded,
              total: e.total,
            },
          },
        })),
        // NOTE: Why? we skip errors from this observable
        // cause later we merge them with main ajax observable
        // throwing error from here will no actually trigger a good
        // refresh when we hit 401 on server, also other errors
        // are catched from main ajax observable
        catchError(() => EMPTY)
      )

      // Create the ajax observable
      const formData = new FormData()
      formData.append('file', file)
      const ajaxObservable = ajax({
        method: 'POST',
        url: `/api/rilievi-video/${idRilievo}/upload/`,
        body: formData,
        progressSubscriber: subject,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      // Merge ajax observable with progress
      return merge(ajaxObservable, progressObservable)
    }
  }

  // Aplly the effect caller to inject ma token
  const resultObservable = effectCaller(uploadCall, ...params)
  // Map the ajax + upload observable to rj actions stream interface
  return concat(
    of({
      type: PENDING,
      meta,
    }),
    resultObservable.pipe(
      map((action) => {
        // Pass down progress
        if (action.type === 'UPLOAD_PROGRESS') {
          return action
        }
        // At this point action is an AjaxResult
        return {
          type: SUCCESS,
          meta,
          payload: {
            params,
            data: action.response,
          },
          // Enabled rj callbacks
          successCallback: callbacks ? callbacks.onSuccess : undefined,
        }
      }),
      catchError((error) => {
        // Avoid headache (mimic standar rj implementation)
        if (
          error instanceof TypeError ||
          error instanceof RangeError ||
          error instanceof SyntaxError ||
          error instanceof ReferenceError
        ) {
          return throwError(error)
        }
        if (process.env.REACT_APP_SENTRY_RELEASE) {
          console.log('Report video upload exception to Sentry')
          Sentry.captureException(error)
        }
        return of({
          type: FAILURE,
          payload: error,
          meta,
          failureCallback: callbacks ? callbacks.onFailure : undefined,
        })
      })
    )
  )
}

function multiUploadReducer(state = {}, action) {
  if (action.type === SUCCESS || action.type === CANCEL) {
    const { id, uploadId } = action.meta
    let nextState = omit(state, `${id}.${uploadId}`)
    // GC also main id thanks to this count "global" pending is more easy
    if (Object.keys(nextState[id] ?? {}).length === 0) {
      nextState = omit(state, id)
    }
    return nextState
  }
  if (action.type === PENDING) {
    const { id, uploadId, fileName } = action.meta
    return {
      ...state,
      [id]: {
        ...state[id],
        [uploadId]: {
          fileName,
          error: null,
          loaded: 0,
          total: 0,
        },
      },
    }
  }
  if (action.type === FAILURE) {
    const { id, uploadId } = action.meta
    return {
      ...state,
      [id]: {
        ...state[id],
        [uploadId]: {
          ...state[id][uploadId],
          error: action.payload,
        },
      },
    }
  }
  if (action.type === 'UPLOAD_PROGRESS') {
    const { id, uploadId } = action.meta
    return {
      ...state,
      [id]: {
        ...state[id],
        [uploadId]: {
          ...state[id][uploadId],
          loaded: action.payload.data.loaded,
          total: action.payload.data.total,
        },
      },
    }
  }
  return state
}

const MultiVideoUploaderState = rj()
  .reducer(() => multiUploadReducer)
  .effect({
    name: 'MultiVideoUploader',
    effect: () => Promise.reject(),
    effectCaller: 'configured',
    // Create a custom take effect to handle also progress event
    takeEffect: (actionObservable, stateObservable, { getEffectCaller }) => {
      return actionObservable.pipe(
        filter((a) => a.type === RUN || a.type === CANCEL),
        mergeMap((a) => {
          if (a.type === CANCEL) {
            return of(a)
          }
          const uploadId = ++uploadCounter
          return concat(of(a), uploadEffect(a, getEffectCaller, uploadId)).pipe(
            takeUntil(
              actionObservable.pipe(
                filter((a) => a.type === CANCEL && a.meta.uploadId === uploadId)
              )
            )
          )
        })
      )
    },
  })

export function useRilieviVideoUploader() {
  const [state, { run, cancel }] = useRj(MultiVideoUploaderState)

  const subject = useConstant(() => new Subject())
  const observable = useConstant(() => subject.asObservable())

  const upload = useCallback(
    (...params) => {
      run
        .onSuccess((data) => {
          subject.next(data)
        })
        .run(...params)
    },
    [run, subject]
  )

  const stop = useCallback(
    (rilievoId, uploadId) => {
      return cancel.withMeta({ id: rilievoId, uploadId }).run()
    },
    [cancel]
  )

  const canCloseRef = useRef(true)
  useEffect(() => {
    canCloseRef.current = Object.keys(state).length === 0
  }, [state])

  useEffect(() => {
    function handleClosePage(e) {
      const canClose = canCloseRef.current
      if (!canClose) {
        e.preventDefault()
        e.returnValue = 'Upload non completato'
        return 'Upload non completato'
      }
    }
    // NOTE: I have no idea why addEventListern wont work but ok back to 90s
    window.onbeforeunload = handleClosePage
    return () => {
      window.onbeforeunload = undefined
    }
  }, [])

  return useMemo(
    () => [state, { upload, stop }, observable],
    [observable, state, upload, stop]
  )
}

const MultiUploaderContext = createContext([null, null, null])

export function MultiUploaderProvider({ children }) {
  return (
    <MultiUploaderContext.Provider value={useRilieviVideoUploader()}>
      {children}
    </MultiUploaderContext.Provider>
  )
}

export function useRilieviVideoUploaderContext() {
  return useContext(MultiUploaderContext)
}

export function MultiUploaderProviderRilevatore({ children }) {
  const { user } = useAuthUser()
  if (user && user.rilevatore) {
    return <MultiUploaderProvider>{children}</MultiUploaderProvider>
  }
  return children
}
