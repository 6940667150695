import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const AreeSostaGeo = rj()
  .mutations({
    importAreeSosta: {
      effect: (t) => (data) => {
        const formData = new FormData()
        formData.append('zip_file', data.zip)
        return api.auth(t).post('/api/aree-sosta/import', formData)
      },
      updater: 'updateData',
    },
  })
  .effect({
    name: 'AreeSostaGeo',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/aree-sosta', params),
  })

export function useAreeSostaGeo(params) {
  return useRunRj(AreeSostaGeo, [params], false)
}
