import React from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { transformErrorForForm } from '../Form'
import {
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
  FormSelectOption,
} from '@patternfly/react-core'
import { DatePickerField, InputField, SelectField, TextAreaField } from '../Form/fields'
import { useAmministratoriList } from '../../hooks/amministratore'

const INITIAL_VALUES = {
  codifica: '',
  revisione: '',
  data_doc: '',
  redatto_da: '',
  verificato_da: '',
  approvato_da: '',
  note: '',
}

const ReportDocSchema = Yup.object().shape({
  codifica: Yup.mixed().label('Codifica'),
  revisione: Yup.mixed().label('Revisione'),
  data: Yup.date().typeError('').label('Data'),
  redatto_da: Yup.string().nullable().label('Verificato'),
  verificato_da: Yup.string().nullable().label('Verificato'),
  approvato_da: Yup.string().nullable().label('Approvato'),
})

function ReportDocFormFields({ tipo }) {
  const [{ data }] = useAmministratoriList()
  return (
    <>
      <Field label="Codifica" name="codifica" component={InputField} />
      <Field label="Revisione" name="revisione" component={InputField} />
      <Field label="Data" name="data_doc" component={DatePickerField} />
      <Field label="Redatto" name="redatto_da" component={SelectField}>
        <FormSelectOption label={'Scegli amministratore'} value={''} />
        {data &&
          data.list.map((admin) => (
            <FormSelectOption
              key={admin.id}
              label={admin.user.name}
              value={admin.id}
            />
          ))}
      </Field>
      <Field label="Verificato" name="verificato_da" component={SelectField}>
        <FormSelectOption label={'Scegli amministratore'} value={''} />
        {data &&
          data.list.map((admin) => (
            <FormSelectOption
              key={admin.id}
              label={admin.user.name}
              value={admin.id}
            />
          ))}
      </Field>
      <Field label="Approvato" name="approvato_da" component={SelectField}>
        <FormSelectOption label={'Scegli amministratore'} value={''} />
        {data &&
          data.list.map((admin) => (
            <FormSelectOption
              key={admin.id}
              label={admin.user.name}
              value={admin.id}
            />
          ))}
      </Field>
      {tipo === 'completo' && (
        <Field label="Note" name="note" component={TextAreaField} />
      )}
    </>
  )
}

export default function ReportDocForm({
  report,
  save,
  className,
  cancel,
  tipo,
}) {
  return (
    <Formik
      validationSchema={ReportDocSchema}
      initialValues={report ?? INITIAL_VALUES}
      onSubmit={(values, actions) =>
        save(values)
          .then(() => {
            actions.setStatus({ success: true })
          })
          .catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
      }
    >
      {({ handleSubmit, isSubmitting, resetForm, status }) => (
        <Form onSubmit={handleSubmit} className={className} isHorizontal>
          {status?.success && (
            <FormAlert>
              <Alert
                variant="success"
                title={
                  report?.id
                    ? 'Report modificato con successo'
                    : 'Report creato con successo'
                }
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          <div className="pf-u-pt-m" />
          <ReportDocFormFields tipo={tipo} />
          <FormGroup>
            <div className="pf-u-display-flex pf-u-justify-content-space-between">
              <Button
                isDisabled={isSubmitting}
                type="button"
                onClick={() => {
                  cancel
                    ? cancel()
                    : resetForm({ values: report ?? INITIAL_VALUES })
                }}
              >
                Annulla
              </Button>
              <Button isDisabled={isSubmitting} type="submit">
                Modifica
              </Button>
            </div>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}
