import {
  Breadcrumb,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalVariant,
} from '@patternfly/react-core'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import {
  TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import {
  CheckCircleIcon,
  TimesCircleIcon,
  ModuleIcon,
} from '@patternfly/react-icons'
import { useParams } from 'react-router'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useMemo } from 'react'
import difference from 'lodash/difference'
import BreadcrumbItemLink from '../../../components/BreadcrumbItemLink'
import FullPage from '../../../components/FullPage'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionHeader from '../../../components/SectionHeader'
import {
  useOperatoreDetail,
  useOperatoriTipiMezzoAdminList,
} from '../../../hooks/operatori'
import { TIPI_MEZZO } from '../../../consts'
import OperatoreFormBoxModal from '../OperatoreFormBoxModal'
import { Link } from 'react-router-dom'
import AttachTipoMezzoBoxModal from '../AttachTipoMezzoBoxModal'

export default function OperatoreDetail() {
  const { id } = useParams()
  const [{ data: operatore }, { patchOperatore }] = useOperatoreDetail(id)

  const [
    { data: relatedTipiMezzo },
    { addOperatoreTipoMezo, patchOperatoreTipoMezzo, run: refreshTipiMezzo },
  ] = useOperatoriTipiMezzoAdminList(
    useMemoCompare({
      operatore: id,
    })
  )

  const [modalEdit, modalEditActions] = useModalTrigger()
  const [modalAttach, modalAttachActions] = useModalTrigger()
  const [modalToggle, modalToggleActions] = useModalTrigger()

  const missingTipiMezzo = useMemo(() => {
    if (!relatedTipiMezzo) {
      return []
    }
    return difference(
      Object.keys(TIPI_MEZZO),
      relatedTipiMezzo.map((r) => r.tipo_mezzo)
    ).map((t) => ({ label: TIPI_MEZZO[t], value: t }))
  }, [relatedTipiMezzo])

  return (
    <FullPage>
      <SectionHeader
        title={`Operatore ${operatore?.nome ?? ''}`}
        topLeft={
          <Breadcrumb>
            <BreadcrumbItemLink to="/operatori">Operatori</BreadcrumbItemLink>
            <BreadcrumbItemLink to={`/operatori/${id}`}>
              Operatore {operatore?.nome ?? ''}
            </BreadcrumbItemLink>
          </Breadcrumb>
        }
        centerRight={
          operatore ? (
            <>
              <Button
                className="pf-u-mr-md"
                onClick={() => {
                  modalEditActions.open()
                }}
              >
                Modifica
              </Button>
              <Button
                isDisabled={missingTipiMezzo.length === 0}
                onClick={() => {
                  modalAttachActions.open()
                }}
              >
                Associa Tipo Mezzo
              </Button>
            </>
          ) : null
        }
      />
      <div
        style={{ flex: 1, overflow: 'hidden' }}
        className="pf-u-display-flex pf-u-flex-direction-column"
      >
        <div className="pf-u-px-md  pf-u-pb-md">
          <Grid className="pf-u-mb-sm">
            <GridItem span={1}>
              <b>Email</b>
            </GridItem>
            <GridItem span={11}>{operatore?.email ?? ''}</GridItem>
          </Grid>
        </div>
        <ScrollableContent>
          <TableComposable isStickyHeader>
            <Thead>
              <Tr>
                <Th>Tipo Mezzo</Th>
                <Th className="pf-u-text-align-center">Id mobishare</Th>
                <Th className="pf-u-text-align-center">Abilitato</Th>
                <Th className="pf-u-text-align-center">Flotta</Th>
              </Tr>
            </Thead>
            <Tbody>
              {relatedTipiMezzo &&
                relatedTipiMezzo.map((operatoreTipoMezzo) => (
                  <Tr key={operatoreTipoMezzo.id}>
                    <Td>{TIPI_MEZZO[operatoreTipoMezzo.tipo_mezzo]}</Td>
                    <Td className="pf-u-text-align-center">
                      {operatoreTipoMezzo.id_operatore_mobishare ? (
                        <CheckCircleIcon
                          style={{ color: 'green', cursor: 'pointer' }}
                        />
                      ) : (
                        <TimesCircleIcon
                          style={{ color: 'red', cursor: 'pointer' }}
                        />
                      )}{' '}
                      {operatoreTipoMezzo.id_operatore_mobishare}
                    </Td>
                    <Td className="pf-u-text-align-center">
                      {operatoreTipoMezzo.abilitato ? (
                        <CheckCircleIcon
                          onClick={() =>
                            modalToggleActions.open(operatoreTipoMezzo)
                          }
                          style={{ color: 'green', cursor: 'pointer' }}
                        />
                      ) : (
                        <TimesCircleIcon
                          onClick={() =>
                            modalToggleActions.open(operatoreTipoMezzo)
                          }
                          style={{ color: 'red', cursor: 'pointer' }}
                        />
                      )}
                    </Td>
                    <Td className="pf-u-text-align-center">
                      <Link
                        to={`/anagrafica-mezzi/${operatoreTipoMezzo.id}`}
                        title="Vai alla Flotta"
                      >
                        <ModuleIcon />
                      </Link>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </TableComposable>
        </ScrollableContent>
      </div>
      <Modal
        variant={ModalVariant.medium}
        title="Modifca Operatore"
        aria-label="Modifca Operatore"
        hasNoBodyWrapper
        isOpen={modalEdit.isOpen}
        onClose={modalEditActions.close}
      >
        <OperatoreFormBoxModal
          data={operatore}
          save={(data) => {
            return patchOperatore
              .onSuccess(() => {
                modalEditActions.close()
              })
              .asPromise(operatore.id, data)
          }}
          onClose={modalEditActions.close}
        />
      </Modal>
      <Modal
        variant={ModalVariant.medium}
        title="Associa Operatore"
        aria-label="Associa Operatore"
        hasNoBodyWrapper
        isOpen={modalAttach.isOpen}
        onClose={modalAttachActions.close}
      >
        <AttachTipoMezzoBoxModal
          tipoMezzoOptions={missingTipiMezzo}
          save={(data) => {
            return addOperatoreTipoMezo
              .onSuccess(() => {
                modalAttachActions.close()
                refreshTipiMezzo({
                  operatore: operatore.id,
                })
              })
              .asPromise({
                operatore: operatore.id,
                tipo_mezzo: data.tipo_mezzo,
              })
          }}
          onClose={modalAttachActions.close}
        />
      </Modal>
      <Modal
        title={
          modalToggle.value
            ? modalToggle.value.abilitato
              ? 'Disabilita associazione'
              : 'Abilita associazione'
            : null
        }
        className="modal-actions-end"
        isOpen={modalToggle.isOpen}
        variant={ModalVariant.medium}
        actions={[
          <Button onClick={modalToggleActions.close} variant="secondary">
            Annulla
          </Button>,
          <Button
            onClick={() => {
              patchOperatoreTipoMezzo
                .onSuccess(() => {
                  modalToggleActions.close()
                })
                .run(modalToggle.value.id, {
                  abilitato: !modalToggle.value.abilitato,
                })
            }}
          >
            Conferma
          </Button>,
        ]}
        onClose={modalToggleActions.close}
      >
        {modalToggle.value && (
          <>
            {modalToggle.value.abilitato ? 'Disabilitare' : 'Abilitare'}{' '}
            associazione con {TIPI_MEZZO[modalToggle.value.tipo_mezzo]}?
          </>
        )}
      </Modal>
    </FullPage>
  )
}
