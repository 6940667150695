import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import ReportsList from './ReportsList'
import ReportAdd from './ReportAdd'
import ReportDocument from './ReportDocument'
import ReportDetail from './ReportDetail'

export default function ReportsRapidi() {
  return (
    <Routes>
      <Route index element={<ReportsList />} />
      <Route path=":id/doc" element={<ReportDocument />} />
      <Route path=":id" element={<ReportDetail />} />
      <Route path="add" element={<ReportAdd />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
